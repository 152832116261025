import React from 'react';
import styled from 'styled-components';
import { CelebPermissionStatus } from './MyPermissionCelebList';
import {colors} from "../../../styles/color";

const RequestDateContainer = styled.div`
	display: flex;
	align-items: flex-start;
	gap: 8px;
	color: ${colors.neutral["500"]};
	text-align: right;
	font-family: Pretendard;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%; /* 14.4px */
`;

interface MyPermissionRequestDateProps {
	selectedTab: CelebPermissionStatus;
	registerDate: string;
	updateDate: string;
}

const MyPermissionRequestDate = ({
	selectedTab,
	registerDate,
	updateDate,
}: MyPermissionRequestDateProps) => {
	const getDateLabel = () => {
		switch (selectedTab) {
			case CelebPermissionStatus.PENDING:
				return '요청한 날짜';
			case CelebPermissionStatus.GRANTED:
				return '수락한 날짜';
			case CelebPermissionStatus.REJECTED:
				return '거절한 날짜';
			default:
				return '요청한 날짜';
		}
	};

	const getDisplayDate = () => {
		if (selectedTab === CelebPermissionStatus.PENDING) {
			return registerDate;
		}
		return updateDate;
	};

	return (
		<RequestDateContainer>
			<div>{getDateLabel()}</div>
			<div className="date">{getDisplayDate()}</div>
		</RequestDateContainer>
	);
};

export default MyPermissionRequestDate;
