import React from "react";
import { CelebData } from "../../interfaces/celeb.interfaces";
import Section from "../../layout/Section";
import styled from "styled-components";
import DataForSeoCard, { InstagramCard } from "./DataCard";
import NewsCard from "./NewsCard";

const Wrapper = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: center;
  overflow-x: auto;
  padding: 1rem;

  /* 스크롤바 스타일링 (선택사항) */
  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  @media screen and (max-width: 1250px) {
    flex-direction: column;
    overflow-x: visible; /* 모바일에서는 세로 스크롤만 필요 */
  }
`;

const DataCardsContainer = styled.div`
  display: flex;
  gap: 2rem;
  
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const CelebDataCard = styled.div`
  margin: 0 auto;
  border: 0.1rem solid var(--gray-900);
  border-radius: 0.4rem;
  background-color: var(--gray-050);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 32rem;
  height: 42rem;
  padding: 3.6rem;
  
  /* 반응형 크기 조정 */
  @media screen and (max-width: 1400px) {
    width: 28rem;
    height: 40rem;
    padding: 2.8rem;
  }
  
  @media screen and (max-width: 1200px) {
    width: 26rem;
    height: 38rem;
    padding: 2.4rem;
  }
  
  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 32rem;
    height: auto;
    min-height: 36rem;
    padding: 2rem;
  }
  
  > .title {
    display: flex;
    width: 100%;
    gap: 0.8rem;
    align-items: center;
    ${({ theme }) => theme.font.title2};
    ${({ theme }) => theme.font.bold};
    padding-bottom: 2rem;
  }
`;

interface CelebSummarySectionProps {
  celebData: CelebData;
}

const CelebSummarySection = ({ celebData }: CelebSummarySectionProps) => {
  const searchTrend = celebData.searchTrend;
  const searchTrendStatus = Object.keys(searchTrend).length !== 0;
  const instagram = celebData.instagram;
  const instagramStatus = Object.keys(instagram).length !== 0;
  const newsList = celebData.celebInfo.celebNews;
  const newsListStatus = celebData.celebInfo.celebNews.length !== 0;

  return (
    <Section mt={8}>
      <Wrapper>
        {newsListStatus && (
          <NewsCard
            celebType={celebData.celebInfo.type}
            newsList={newsList}
            dataStatus={searchTrendStatus && instagramStatus}
          />
        )}
        {searchTrendStatus && instagramStatus && (
          <DataCardsContainer>
            <DataForSeoCard
              searchTrend={searchTrend}
              celebData={celebData}
            />
            {
              instagram && <InstagramCard instagram={instagram} celebData={celebData} />
            }
          </DataCardsContainer>
        )}
      </Wrapper>
    </Section>
  );
};

export default CelebSummarySection;
