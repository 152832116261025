import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../styles/color';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import NavigationSection from '../components/privacyTerm/\bsections/NavigationSection';
import GeneralSection from '../components/privacyTerm/\bsections/GeneralSection';
import ServiceSection from '../components/privacyTerm/\bsections/ServiceSection';
import DataManagementSection from '../components/privacyTerm/\bsections/DataManagementSection';
import LegalMattersSection from '../components/privacyTerm/\bsections/LegalMattersSections';
import MarketingSection from '../components/privacyTerm/\bsections/MarketingSection';
import PrivacyTermHeader from '../components/privacyTerm/PrivacyTermHeader';
import PrivacyTermContent from '../components/privacyTerm/PrivacyTermContents';
import MoveTop from '../components/privacyTerm/MoveTop';

const Wrapper = styled.div`
	width: 1600px;
	margin: 0 auto;
`;

const Container = styled.div`
  display: flex;
  width: 920px;
  padding: 40px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  margin: 0 auto;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 920px;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  border-radius: 12px;
  background: ${colors.neutral['0']};
  margin: 0 auto;
`;

const PrivacyTermsPage = () => {
  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState('General Provisions');

  useEffect(() => {
    const section = searchParams.get('section');
    if (section) {
      setActiveTab(section);
      const element = document.getElementById(section.toLowerCase().replace(/ /g, '-'));
      if (element) {
        const yOffset = -100;
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  }, [searchParams]);

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
    const element = document.getElementById(tab.toLowerCase().replace(/ /g, '-'));
    if (element) {
      const yOffset = -100;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  const handleDownloadPDF = async () => {
    try {
      const contentElement = document.querySelector('.content-wrapper') as HTMLElement;
      if (!contentElement) return;

      const canvas = await html2canvas(contentElement, {
        scale: 2,
        useCORS: true,
        logging: false,
      });

      const imgWidth = 210;
      const pageHeight = 297;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      const pdf = new jsPDF('p', 'mm', 'a4');
      let page = 1;

      while (heightLeft >= 0) {
        if (page > 1) {
          pdf.addPage();
        }

        pdf.addImage(
          canvas.toDataURL('image/png'),
          'PNG',
          0,
          position,
          imgWidth,
          imgHeight,
          '',
          'FAST'
        );

        heightLeft -= pageHeight;
        position -= pageHeight;
        page++;
      }

      pdf.save('privacy-terms.pdf');
    } catch (error) {
      console.error('PDF 다운로드 중 오류 발생:', error);
    }
  };

  return (
    <Wrapper>
		<PrivacyTermHeader />
      <NavigationSection
        activeTab={activeTab}
        onTabClick={handleTabClick}
        onDownload={handleDownloadPDF}
      />
	  <PrivacyTermContent showTopButton={false} onScrollToTop={function (): void {
			  throw new Error('Function not implemented.');
		  } } children={undefined} />
      <Container>
        <ContentWrapper className="content-wrapper">
          <GeneralSection />
          <ServiceSection />
          <DataManagementSection />
          <LegalMattersSection />
          <MarketingSection />
        </ContentWrapper>
        <MoveTop show={true} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} />
      </Container>
    </Wrapper>
  );
};

export default PrivacyTermsPage;