import React from "react";
import styled, { css } from "styled-components";
import { RegisterOptions, UseFormRegister } from "react-hook-form";
import { sizeStyle } from "../common/SizeStyle";
import { colors } from "../../styles/color";
const colorStyle = {
  disabled: css`
    border: 0.1rem solid ${colors.neutral[300]};
    border-radius: 0.4rem;
    background-color: ${colors.neutral[0]};
    color: ${colors.neutral[900]};
  `,
  gray: css`
    border: 0.1rem solid ${colors.neutral[300]};
    border-radius: 0.4rem;
    background-color: ${colors.neutral[0]};
    color: ${colors.neutral[900]};
  `,
  error: css`
    border: 0.1rem solid ${colors.alert.error};
    border-radius: 0.4rem;
    background-color: ${colors.neutral[0]};
    color: ${colors.neutral[900]};
  `,
  default: css`
    border: 0.1rem solid ${colors.neutral[300]};
    border-radius: 0.4rem;
    background-color: ${colors.neutral[0]};
    color: ${colors.neutral[900]};
  `,
};

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

interface InputProps {
  $inputSize: "sm" | "md" | "lg" | "default";
  $color: "gray" | "default";
  $margin?: string;
  $marginTop?: string;
  $marginRight?: string;
  $marginBottom?: string;
  $marginLeft?: string;
  $errorStatus?: boolean;
}

const Input = styled.input<InputProps>`
  font-family: "Inter";
  ${({ $inputSize }) => sizeStyle[$inputSize]};
  ${({ $margin }) => ($margin ? `margin: ${$margin}` : "")};
  ${({ $color }) => colorStyle[$color]};
  ${({ $errorStatus }) => ($errorStatus ? colorStyle.error : "")};
  ${({ $marginTop }) => ($marginTop ? `margin-top: ${$marginTop}` : "")};
  ${({ $marginRight }) =>
    $marginRight ? `margin-right: ${$marginRight}` : ""};
  ${({ $marginBottom }) =>
    $marginBottom ? `margin-bottom: ${$marginBottom}` : ""};
  ${({ $marginLeft }) => ($marginLeft ? `margin-left: ${$marginLeft}` : "")};
  &:disabled {
    ${colorStyle.disabled}
  }
`;

export const LabelGroup = styled.div`
  display: flex;
  padding: 0 0.2rem;
  justify-content: space-between;
`;

export const Label = styled.label<{ $required?: boolean }>`
  color: ${colors.neutral[600]};
  ${({ theme }) => theme.font.body1};
  ${({ theme }) => theme.font.bold};


  &::after {
    content: ${({ $required }) => ($required ? "'*'" : "''")};
    color: ${colors.alert.error};
    margin-left: 0.4rem;
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.regular};
  }
`;

export const ErrorMessage = styled.p`
  color: ${colors.alert.error};
  text-align: right;
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.regular};
  text-overflow: ellipsis;
`;

interface RegisterInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'prefix'> {
  inputSize?: "sm" | "md" | "lg" | "default";
  color?: "gray" | "default";
  fieldName: string;
  register: UseFormRegister<any>;
  registerOptions?: RegisterOptions<any>;
  label?: string;
  errorMessage?: string;
  margin?: string;
  marginTop?: string;
  marginRight?: string;
  marginBottom?: string;
  marginLeft?: string;
  required?: boolean;
  prefix?: React.ReactNode;
}

const RegisterInput = ({
  inputSize = "default",
  color = "default",
  fieldName,
  register,
  registerOptions = {},
  label,
  errorMessage,
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  required = false,
  prefix,
  ...options
}: RegisterInputProps) => {
  return (
    <InputGroup>
      {label && (
        <LabelGroup>
          <Label $required={required}>{label}</Label>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </LabelGroup>
      )}
      <div style={{ position: 'relative' }}>
        {prefix}
        <Input
          $inputSize={inputSize}
          $color={color}
          $margin={margin}
          $marginTop={marginTop}
          $marginRight={marginRight}
          $marginBottom={marginBottom}
          $marginLeft={marginLeft}
          $errorStatus={!!errorMessage}
          {...register(fieldName)}
          {...options}
        />
      </div>
    </InputGroup>
  );
};

export default RegisterInput;
