import React from "react";
import styled from "styled-components";
import Checkbox from "../common/Checkbox";
import Order from "../select/Order";
import { CelebListFilterQuery } from "../interfaces/filter.interface";

const FilterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1.6rem;
  align-items: center;
  margin-left: auto;
  margin-bottom: 20px;
  
  @media (max-width: 1250px) {
    margin-right: 0;
    margin-left: 0;
    justify-content: center; 
  }

  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

const CheckBoxContainer = styled.div`
  display: flex;
  gap: 20px;
`;

interface FilterProps {
  query: CelebListFilterQuery;
  setQuery: Function;
}

const Filter = ({ query, setQuery }: FilterProps) => {

  return (
    <FilterContainer>
      <CheckBoxContainer>
        <Checkbox
          label="Male"
          value="MALE"
          checked={query.gender.includes("MALE")}
          onChange={(value, checked) => setQuery("gender", value, checked)}
        />
        <Checkbox
          label="Female"
          value="FEMALE"
          checked={query.gender.includes("FEMALE")}
          onChange={(value, checked) => setQuery("gender", value, checked)}
        />
      </CheckBoxContainer>
      <Order query={query} setQuery={setQuery} />
    </FilterContainer>
  );
};

export default Filter;
