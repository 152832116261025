import React from 'react';
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { colors } from "../../../styles/color";

const AdvertiserMenuContainer = styled.div`
  margin-top: 20px;

  @media (max-width: 768px) {
    display: flex;
    width: 90%;
    margin: 0 auto;
    padding: 10px 0;
    border-bottom: 0.1rem solid ${colors.neutral["800"]};
  }
`;

const SidebarItem = styled.div`
  ${({theme}) => theme.font.regular};
  ${({theme}) => theme.font.title2};
  color: ${colors.neutral["800"]};
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  &:hover {
    color: ${colors.main.bora};
  }

  @media (max-width: 768px) {
    width: 50%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

const SubItem = styled(NavLink)<{ $currentPage: boolean }>`
  display: flex;
  ${({ theme }) => theme.font.body1};
  align-items: center;
  padding: 20px 32px;
  
  &:hover {
    color: var(--primary);
    cursor: pointer;
  }
  
  ${({ $currentPage, theme }) => $currentPage && theme.font.bold};

  @media (max-width: 768px) {
    padding: 10px 16px;
    width: 100%;
    justify-content: center;
  }
`;

const AdvertiserMenu = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <AdvertiserMenuContainer>
      <SidebarItem>
        <SubItem
          to="projects/list?type=ongoing"
          $currentPage={currentPath.startsWith("/dashboard/projects")}
        >
          Project management
        </SubItem>
      </SidebarItem>
      <SidebarItem>
        <SubItem
          to="celebs/favorite"
          $currentPage={currentPath.startsWith("/dashboard/celebs/favorite")}
        >
          Favorite Celebrities
        </SubItem>
      </SidebarItem>
    </AdvertiserMenuContainer>
  );
};

export default AdvertiserMenu;
