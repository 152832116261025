import React, {useState, useEffect} from "react";
import styled from "styled-components";
import { CelebListData } from "../../interfaces/celeb.interfaces";
import { useQuery } from "@tanstack/react-query";
import { getFavoriteCelebList } from "../../../api/celebList";
import { BaseResponse } from "../../interfaces/auth.interfaces";
import CelebCard from "../../celeb/celebCard/CelebCard";
import {colors} from "../../../styles/color";
import {CelebListFilterQuery, FavoriteCelebListFilterQuery} from "../../interfaces/filter.interface";
import {useNavigate} from "react-router-dom";
import AdvertiserFavoriteFilter from "../../celeb/dashboard/AdvertiserFavoriteFilter";

const DashboardContainer = styled.div`
`;

const FavoriteHeader = styled.div`
  color: ${colors.neutral["800"]};
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 28.8px */
  margin-bottom: 20px;
`;

const CelebCardListContainer = styled.div`
  display: flex;
  width: 1176px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  gap: 16px;
  flex-wrap: wrap;
  margin-top: 20px;
  
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const EmptyContainer = styled.div`
  display: flex;
  width: 1176px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  
  img {
    margin-bottom: 16px;
  }
  
  p {
    color: ${colors.neutral["600"]};
    ${({ theme }) => theme.font.body1};
  }
  
  .button {
    width: 420px;
    height: 60px;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    margin-top: 40px;
    background-color: ${colors.neutral["900"]};
    
    &:hover {
      cursor: pointer;
    }

    div {
      display: flex;
      align-items: center;
      gap: 8px;
      color: white;
    }

    img {
      margin-bottom: 0;
    }
  }
`;

type FavoriteCelebList = BaseResponse<CelebListData[]>;

const Favorite = () => {
  const navigate = useNavigate();
  const [query, setQuery] = useState<FavoriteCelebListFilterQuery>({
    page: 1,
    take: 10,
    minEstimatedPrice: 0,
    maxEstimatedPrice: 0,
    country: '',
    minInstagramFollowers: 0,
    maxInstagramFollowers: 0,
    minSearchVolume: 0,
    maxSearchVolume: 0,
    isLike: true,
    gender: [],
    type: [],
    order__by: 'createdAt',
    envType: process.env.REACT_APP_NODE_ENV as 'prod' | 'stage' | 'dev'
  });

  const { isPending, isError, data, error, refetch } =
    useQuery<FavoriteCelebList>({
      queryKey: ["FavoriteCelebList", query],
      queryFn: () => getFavoriteCelebList(query),
    });

  useEffect(() => {
    refetch();
  }, [query, refetch]);

  const queryHandler = (
    name: keyof CelebListFilterQuery,
    value: string | number,
    checked?: boolean
  ) => {
    setQuery((prevQuery) => {
      if (checked !== undefined && name === "gender") {
        return {
          ...prevQuery,
          gender: checked
            ? [...prevQuery.gender, value as string]
            : prevQuery.gender.filter(item => item !== value),
        };
      }

      return {
        ...prevQuery,
        [name]: value,
      };
    });
  };
  if (isPending) return <></>;

  return (
    <DashboardContainer>
      <FavoriteHeader>
        Favorite Celebrities
      </FavoriteHeader>
      <AdvertiserFavoriteFilter query={query} setQuery={queryHandler} />
      {
        data?.data.length === 0 ? (
          <EmptyContainer>
            <img src="/svg/dashboard/agency/folder.svg" alt="folder" />
            <p>You don't have any Favorite Celebrity yet.</p>
            <div className="button" onClick={() => navigate("/celebs")}>
              <div>
                <img src="/svg/dashboard/advertiser/person_search.svg" alt="person_search" />
                <span>Explore Celebrities</span>
              </div>
            </div>
          </EmptyContainer>
        ) : (
          <CelebCardListContainer>
            {data?.data.map((celeb, index) => {
              return <CelebCard
                key={celeb.id + index}
                celebData={celeb}
                egrCelebsTopTwenty={[]}
                followersCelebsTopTwenty={[]}
              />;
            })}
          </CelebCardListContainer>
        )
      }
    </DashboardContainer>
  );
};

export default Favorite;
