import {useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { formatNumber } from "../../utils/utils";
import styled from "styled-components";
import { CelebListFilterQuery } from "../interfaces/filter.interface";
import {colors} from "../../styles/color";

const minDistance = 1000;

const SliderContainer = styled.div`
  gap: 8px;
  color: ${colors.neutral["700"]};
`;

const SliderTitle = styled.div<{ titleColor?: string}>`
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.regular};
  width: 100%;
  color: ${props => props.titleColor || colors.neutral["700"]};
`;

const RangeViewer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${colors.neutral["700"]};
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.regular};
  margin: 0.8rem auto 0;
`;

interface RangeSlideProps {
  title: string;
  width: number;
  min?: number;
  max?: number;
  minValue: number;
  maxValue: number;
  name: string;
  titleColor?: string;
  hideRangeViewer?: boolean;
  double?: boolean;
  setQuery: (name: keyof CelebListFilterQuery, value: string | number, checked?: boolean) => void;
}

const RangeSlider = ({
                       title,
                       width,
                       min = 0,
                       max = 50000,
                       minValue,
                       maxValue,
                       name,
                       titleColor,
                       hideRangeViewer = true,
                       double = true,
                       setQuery,
                     }: RangeSlideProps) => {
  const [singleValue, setSingleValue] = useState(maxValue);

  useEffect(() => {
    if (!double) {
      setSingleValue(maxValue);
    }
  }, [maxValue, double]);

  const handleChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (double) {
      if (!Array.isArray(newValue)) {
        return;
      }

      if (activeThumb === 0) {
        setQuery(`min${name}` as keyof CelebListFilterQuery,
          Math.min(newValue[0], maxValue - minDistance)
        );
      } else {
        setQuery(`max${name}` as keyof CelebListFilterQuery,
          Math.max(newValue[1], minValue + minDistance)
        );
      }
    } else {
      const value = Array.isArray(newValue) ? newValue[0] : newValue;
      setSingleValue(value);
      setQuery(`max${name}` as keyof CelebListFilterQuery, value);
    }
  };

  return (
    <SliderContainer>
      <SliderTitle titleColor={titleColor}>{title}</SliderTitle>
      <Box sx={{ width: "100%", margin: "0 auto", maxWidth: width }}>
        <Slider
          sx={{
            color: "var(--purple-300)",
            "& .MuiSlider-thumb": {
              "&:hover, &:active": {
                color: "var(--purple-500)",
              },
            },
            "& .MuiSlider-rail": {
              color: "var(--Grey-200, #F1F1F1)",
              opacity: 1,
              fill: "var(--Grey-200, #F1F1F1)"
            },
            "& .MuiSlider-track": {
              border: "1px solid var(--purple-050)",
              color: "var(--purple-050)",
            },
          }}
          getAriaLabel={(value) => `${value}`}
          min={min}
          max={max}
          value={double ? [minValue, maxValue] : singleValue}
          onChange={handleChange}
          disableSwap={double}
        />
      </Box>
      {hideRangeViewer && (
        <RangeViewer>
          {double ? (
            <>
              <div>from {formatNumber(minValue)}~</div>
              <div>
                {formatNumber(maxValue)}
                {
                  (
                    maxValue === 50000 ||
                    maxValue === 1000000 ||
                    maxValue === 12
                  )
                  ? "+"
                  : ""
                }
              </div>
            </>
          ) : (
            <div>
              {formatNumber(singleValue)}
              {
                (
                  singleValue === 50000 ||
                  singleValue === 1000000 ||
                  singleValue === 12
                )
                ? "+"
                : ""
              }
            </div>
          )}
        </RangeViewer>
      )}
    </SliderContainer>
  );
};

export default RangeSlider;
