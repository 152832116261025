import React from "react";
import styled from "styled-components";
import { colors } from "../../styles/color";

const ApplyButton = styled.button`
  width: 17.2rem;
  height: 4.4rem;
  flex-shrink: 0;
  background-color: ${colors.purple["300"]};
  color: ${colors.neutral["50"]};
  margin: 0 auto;
  display: block;
  transition: background 0.3s ease;

  &:hover {
    background: #9a47fb;
  }
`;

interface ApplyProps {
  onApply: () => void;
}

const Apply = ({ onApply }: ApplyProps) => {
  return (
    <ApplyButton onClick={onApply}>
      Apply
    </ApplyButton>
  );
};

export default Apply;
