import {keepPreviousData, useInfiniteQuery, useQuery} from "@tanstack/react-query";
import {
  CelebHighlightResponse,
  CelebInContractResponse,
  CelebListData,
  CelebOnCelevuSuccessResponse,
  CelebResponse,
} from "../components/interfaces/celeb.interfaces";
import {getCeleb, getCelebHighlight, getCelebInContract, getCelebList, getCelebOnCelevu,} from "../api/celebList";
import {CelebListFilterQuery} from "../components/interfaces/filter.interface";
import {useAtom} from "jotai/index";
import {celebFilterAtom} from "../atoms/filter";
import {privateClient} from "../api/axiosInstance";
import { BaseResponse } from "../components/interfaces/auth.interfaces";

export const useCelebData = (id: string | undefined) => {
  const [query, setQuery] = useAtom<CelebListFilterQuery>(celebFilterAtom);

  const { data, isPending, refetch } = useQuery<CelebResponse>({
    queryKey: [id, "getCelebData"],
    queryFn: () => getCeleb(id, query.countries),
  });

  return {
    data,
    isPending,
    refetch,
  };
};

export const useCelebList = (query: CelebListFilterQuery, envType: 'prod' | 'stage' | 'dev') => {
  const {
    data,
    isPending,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery<BaseResponse<{
    data: CelebListData[];
    followersCelebsTopTwenty: { id: string, name: string, followersCnt: number }[];
    egrCelebsTopTwenty: { id: string, name: string, egr: number }[];
    targetCountryTopTwenty: { id: string, name: string, targetCountryValue: number }[];
  }>>({
    queryKey: ["getCelebList", query, envType],
    queryFn: async ({ pageParam = 1 }) => {
      return await getCelebList({
        ...query,
        page: Number(pageParam),
        take: 10,
        envType,
      });
    },
    placeholderData: keepPreviousData,
    getNextPageParam: (lastPage, allPages) => {
      // Check if the data array exists and has items
      if (!lastPage.data || lastPage.data.data.length < 5) return undefined;
      return allPages.length + 1;
    },
    initialPageParam: 1,
  });

  return {
    data,
    isPending,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  };
};

// useCeleb.ts
export const useContactCelebList = (ENV: 'prod'| 'stage'| 'dev') => {
  return useQuery({
    queryKey: ['contactCelebList', ENV],
    queryFn: async () => {
      try {
        const response = await privateClient.get("/api/v1/celeb/contact", {
          params: { envType: ENV }
        });
        return response.data;
      } catch (error) {
        console.error('Contact celeb data fetch error:', error);
        throw error;
      }
    },
  });
};

export const useCelebHighlight = (id: string) => {
  const { data, isPending, refetch } = useQuery<CelebHighlightResponse>({
    queryKey: ["getCelebHighlight", id],
    queryFn: () => getCelebHighlight(id),
  });

  return {
    data,
    isPending,
    refetch,
  };
};

export const useCelebInContract = () => {
  const { data, isPending, refetch } = useQuery<CelebInContractResponse>({
    queryKey: ["getCelebInContract"],
    queryFn: () => getCelebInContract(),
  });

  return {
    data,
    isPending,
    refetch,
  };
};

export const useCelebOnCelevu = () => {
  const { data, isPending, refetch } = useQuery<CelebOnCelevuSuccessResponse>({
    queryKey: ["getCelebOnCelevu"],
    queryFn: () => getCelebOnCelevu(),
  });

  return {
    data,
    isPending,
    refetch,
  };
};
