import React from 'react';
import styled from 'styled-components';
import { colors } from '../styles/color';
import {publicClient} from "../api/axiosInstance";
import {useNavigate, useLocation} from "react-router-dom";
import {useAtom} from "jotai/index";
import {userDataAtom} from "../atoms/auth";
import Logo from "../components/layout/Logo";
import { FormContainer } from '../components/common/FormContainer';
import {AxiosError} from "axios";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5rem;
	align-items: center;
	justify-content: center;
	margin-top: 6rem;
`;

const Wrapper = styled.div`
	display: flex;
	width: 372px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 40px;
`;

const Header = styled.div`
	gap: 1rem; // 16px
	display: flex;
	flex-direction: column;

	img {
		margin: 0 auto;
		width: 77px;
		height: 77px;
		aspect-ratio: 1/1;
	}

	p {
		color: ${colors.neutral[900]};
		text-align: center;
		font-family: Inter;
		font-size: 24px;
		font-style: normal;
		font-weight: 700;
		line-height: 120%; /* 28.8px */
	}
`;

const Content = styled.div`
	color: ${colors.neutral[900]};
	text-align: center;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%; /* 19.2px */
`;

const Bottom = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 12px;
	align-self: stretch;

	button {
		color: ${colors.blue["600"]};
		text-align: center;

		/* Body1 / Regular */
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 120%; /* 19.2px */
		text-decoration-line: underline;
		text-decoration-style: solid;
		text-decoration-skip-ink: none;
		text-decoration-thickness: auto;
		text-underline-offset: auto;
		text-underline-position: from-font;
	}

	div {
		display: flex;
		color: ${colors.neutral[600]};
		font-family: Inter;
		text-align: center;
		margin: auto;
		justify-content: center;
		align-items: center;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;

		.email {
			color: ${colors.email.blue};
			margin-left: 5px;
			font-family: Inter;
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 150%;
		}
	}
`;

const RequiredLimitExceeded = styled.div`
	color: ${colors.neutral["600"]};
	text-align: center;
	width: 100%;

	/* Body1 / Regular */
	font-family: Inter;
	font-size: 16px !important;
	font-style: normal;
	font-weight: 400;
	line-height: 120%; /* 19.2px */
`;

const VerifyViaEmailPage = () => {
	const [userData, _] = useAtom(userDataAtom);
	const location = useLocation();
	const [isLimitExceeded, setIsLimitExceeded] = React.useState(
		location.state?.isLimitExceeded || false
	);

	const sendVerificationEmail = async () => {
		const userEmail = userData?.email;
		try {
			await publicClient.post("/api/v1/email/send_verification", { email: userEmail });
		} catch (error: any) {
			console.error("Unexpected error:", error);
			if (error?.response?.data?.message === "The request limit has been exceeded") {
				setIsLimitExceeded(true);
			}
		}
	}

	return (
		<Container>
			<Logo url="/" />
			<FormContainer>
				<Wrapper>
					<Header>
						<img src="/svg/auth/verify_via_email.svg" alt="verify_via_email.svg"/>
						<p>Verify via Email</p>
					</Header>
					<Content>
						A verification email has been sent to your<br/>
						registered email.<br/>
						Follow the instructions in the email to complete<br/>
						the verification process.<br/><br/>
						If you haven't received the email or the link has<br/>
						expired, you can request a new one.
					</Content>
					<Bottom>
						{isLimitExceeded ? (
							<RequiredLimitExceeded>
								The request limit has been exceeded
							</RequiredLimitExceeded>
						) : (
							<button onClick={sendVerificationEmail}>Resend Verification Email</button>
						)}
						<div>Didn't get the email? Contact us
							<a className="email" href="mailto:dev@aligo.ai">dev@aligo.ai</a>
						</div>
					</Bottom>
				</Wrapper>
			</FormContainer>
		</Container>
	);
};

export default VerifyViaEmailPage;
