import React, {useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { CelebListFilterQuery } from "../interfaces/filter.interface";
import {colors} from "../../styles/color";

const SelectWrapper = styled.div`
  position: relative;
  width: 256px;
`;

const DropdownContainer = styled.div`
   border: 1px solid ${colors.neutral["300"]};
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
`;

interface SelectedDropdownItemProps {
  isOpen: boolean;
}

const SelectedDropdownItem = styled.div<SelectedDropdownItemProps>`
  display: flex;
  width: 256px;
  height: 40px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 20px;
  background-color: white;
  border: 1px solid ${colors.bora["200"]};
  cursor: pointer;
  color: ${colors.neutral["700"]};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  &::after {
    content: '';
    width: 6px;
    height: 6px;
    border-right: 1.5px solid ${colors.bora["200"]};
    border-bottom: 1.5px solid ${colors.bora["200"]};
    transform: ${({ isOpen }) => isOpen ? 'rotate(-135deg)' : 'rotate(45deg)'};
    transition: transform 0.3s ease;
  }
`;

const SelectOptionItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  width: 100%;
  gap: 8px;
  
  .text {
    flex: 1;
    color: ${colors.neutral["900"]};
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    display: flex;
    align-items: center;
  }
  
  .img {
    width: 20px;
    height: 20px;
  }
`;

const DropdownItem = styled.div`
  border-bottom: 1px solid ${colors.neutral["300"]};
  display: flex;
  width: 100%;
  height: 48px;
  padding: 12px 32px;
  align-items: center;
  background: white;
  color: ${colors.neutral["700"]};
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  cursor: pointer;

  &:hover {
    background-color: ${colors.bora["50"]};
  }

  &.selected {
    background-color: ${colors.bora["50"]};
  }
`;

const BestValueTooltip = styled.div`
  display: flex;
  width: 144px;
  padding: 8px;
  position: absolute;
  right: -62px;
  top: -160px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid ${colors.bora["200"]};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  justify-content: center;
  align-items: center;
  visibility: hidden;

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 8px;
    border-style: solid;
    border-color: white transparent transparent transparent;
    z-index: 3001; // z-index 값 증가
  }

  &::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 9px;
    border-style: solid;
    border-color: ${colors.bora["200"]} transparent transparent transparent;
    z-index: 3000; // z-index 값 증가
  }

  p {
    color: ${colors.neutral["900"]};
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }
`;

const BestValueDropdownItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  position: relative;
  z-index: 1000; // z-index 값 감소

  img {
    position: relative;
    z-index: 1000; // 이미지의 z-index 값 설정
  }

  &:hover {
    .tooltip {
      visibility: visible;
      opacity: 1;
    }

    img[alt="best_value_question_mark"] {
      z-index: 900; // hover 시 물음표 이미지의 z-index 값을 낮춤
    }
  }
`;

const BestValueItem = styled.div`
  position: relative;
  z-index: 200;
  display: flex;
  gap: 4px;
  align-items: center;
  height: 100%;

  &:hover {
    .tooltip {
      opacity: 1;
      visibility: visible;
    }
  }
`;

const SelectedBestValueTooltip = styled(BestValueTooltip)`
  position: absolute;
  top: -160px;
  right: -62px;
  opacity: 0;
  background-color: white;

  ${BestValueItem}:hover & {
    opacity: 1;
    visibility: visible;
  }
`;

const orderList = [
  { value: "follower", displayName: "Instagram followers" },
  { value: "search_volume", displayName: "Search volume" },
  { value: "best_value", displayName: "Best Value" },
];

interface CountryProps {
  query: CelebListFilterQuery;
  setQuery: Function;
}

const Order = ({ query, setQuery }: CountryProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectedOrder = orderList.find(order => order.value === query.order__by);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <SelectWrapper ref={wrapperRef}>
      <SelectedDropdownItem isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <SelectOptionItem>
          <div className="text">
            {selectedOrder?.displayName ? (
              selectedOrder.displayName === 'Best Value' ? (
                <BestValueItem>
                  <img src="/svg/celeblist/best_value.svg" alt="best_value" style={{ width: "20px", height: "20px"}}/>
                  {selectedOrder.displayName}
                  <img
                    src="/svg/celeblist/best_value_question_mark.svg"
                    alt="best_value"
                    style={{ width: "20px", height: "20px"}}
                  />
                  <SelectedBestValueTooltip>
                    <p>Best Value is determined by comprehensively considering factors such as Followers, Engagement Rate, and proportion of Followers from Target Countries.</p>
                  </SelectedBestValueTooltip>
                </BestValueItem>
              ) : (
                selectedOrder.displayName
              )
            ) : (
              "Select order"
            )}
          </div>
        </SelectOptionItem>
      </SelectedDropdownItem>
      {isOpen && (
        <DropdownContainer>
          {orderList.map((order) => (
            <DropdownItem
              key={`select_option_${order.value}`}
              className={order.value === query.order__by ? 'selected' : ''}
              onClick={() => {
                setQuery("order__by", order.value);
                setIsOpen(false);
              }}
            >
              {order.displayName === "Best Value" ? (
                <BestValueDropdownItem>
                  <img src="/svg/celeblist/best_value.svg" alt="best_value" style={{ width: "20px", height: "20px"}}/>
                  {order.displayName}
                  <img
                    src="/svg/celeblist/best_value_question_mark.svg"
                    alt="best_value_question_mark"
                    style={{ width: "20px", height: "20px"}}
                  />
                  <BestValueTooltip className="tooltip">
                    {/*<p>Gorem ipsum dolor sit amet, consectetur adipiscing elit.</p>*/}
                    <p>Best Value is determined by comprehensively considering factors such as Followers, Engagement Rate, and proportion of Followers from Target Countries.</p>
                  </BestValueTooltip>
                </BestValueDropdownItem>
              ) : (
                order.displayName
              )}
            </DropdownItem>
          ))}
        </DropdownContainer>
      )}
    </SelectWrapper>
  );
};

export default Order;
