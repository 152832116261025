import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { colors } from "../styles/color";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Logo from "../components/layout/Logo";
import {FormContainer} from "../components/common/FormContainer";
import {publicClient} from "../api/axiosInstance";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5rem;
	align-items: center;
	justify-content: center;
	margin-top: 6rem;
`;

const VerifyCompleteFailContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;
	align-self: stretch;
	
	.title {
		align-self: stretch;
		color: ${colors.neutral["900"]};
		text-align: center;
		font-family: Inter;
		font-size: 24px;
		font-style: normal;
		font-weight: 700;
		line-height: 120%; /* 28.8px */
	}
	
	.content {
		align-self: stretch;
		color: ${colors.neutral["900"]};
		text-align: center;
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 120%; /* 19.2px */
	}
`;

const Wrapper = styled.div`
	display: flex;
	width: 372px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 40px;

	img {
		width: 80px;
		height: 80px;
	}

	p {
		color: ${colors.neutral["900"]};
		text-align: center;
		font-family: Inter;
		font-size: 24px;
		font-style: normal;
		font-weight: 700;
		line-height: 120%; /* 28.8px */
	}

	button {
		width: 372px;
		height: 60px;
		flex-shrink: 0;
		border-radius: 2px;
		background-color: ${colors.bora["301"]};
		color: white;
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		border: none;
		cursor: pointer;
	}

	.error-message {
		color: ${colors.alert.error};
		text-align: center;
		font-size: 16px;
		margin-top: -20px;
	}
`;

const VerifyCompletePage = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { type } = useParams(); // URL에서 :type 파라미터 가져오기
	const [isVerificationSuccess, setIsVerificationSuccess] = useState(true);

	useEffect(() => {
		const verifyEmail = async () => {
			const queryParams = new URLSearchParams(location.search);
			const email = queryParams.get('email');
			const token = queryParams.get('token');

			if (!email || !token) {
				navigate("/login");
				return;
			}

			try {
				const result = await publicClient.get('api/v1/email/verification', {
					params: {
						email: email,
						token: token,
					},
				});
			} catch (error: any) {
				console.log('error', error.response);
				if (error?.response?.data?.message === "Verification Link Expired") {
					setIsVerificationSuccess(false);
				}
			}
		};

		// Check if we're on the correct page
		if (location.pathname.includes('/verify-complete')) {
			verifyEmail();
		}
	}, [location.search, location.pathname]);

	const handleLogin = () => {
		navigate("/login");
	};

	// 성공 화면
	return (
		<Container>
			<Logo url="/" />
			<FormContainer>
				<Wrapper>
					{
						isVerificationSuccess
							? (<>
									<img src="/svg/auth/email_done.svg" alt="email_done.svg" />
									<p>Email Verification Complete!</p>
									<button onClick={handleLogin}>Log in</button>
								</>)
							: (
								<>
									<VerifyCompleteFailContent>
										<img src="/svg/auth/email_fail.svg" alt="email_done.svg" />
										<p className="title">Verification Link Expired</p>
										<p className="content">The email verification link has expired.
											<br />
											Please log in and resend the link.</p>
									</VerifyCompleteFailContent>
									<button onClick={handleLogin}>Log in</button>
								</>
							)
					}
				</Wrapper>
			</FormContainer>
		</Container>
	);
};

export default VerifyCompletePage;
