import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import {CelebListFilterQuery, FavoriteCelebListFilterQuery} from "../../interfaces/filter.interface";
import {colors} from "../../../styles/color";

const OrderSelect = styled.select`
  width: 25.6rem;
  height: 4rem;
  padding: 1rem 2rem;
  border: 0.1rem solid var(--purple-300);
  border-radius: 2rem;
  color: var(--gray-900);
  ${({ theme }) => theme.font.inter};
  ${({ theme }) => theme.font.body1};
  ${({ theme }) => theme.font.regular};
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
   
  &:focus {
    outline: none;
  }
  cursor: pointer;
`;

const SelectWrapper = styled.div`
  position: relative;
  width: 256px;
`;

const DropdownContainer = styled.div`
	border: 1px solid ${colors.neutral["300"]};
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 12px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  z-index: 1000;
`;

interface SelectedDropdownItemProps {
	isOpen: boolean;
}

const SelectedDropdownItem = styled.div<SelectedDropdownItemProps>`
  border: 1px solid var(--Primary-Violet-200, #C69BFD);
  border-radius: 20px;
  width: 100%;
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
	color: ${colors.neutral["700"]};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  background: white;

  &::after {
    content: '';
    width: 8px;
    height: 8px;
    border-right: 2px solid var(--Grey-700);
    border-bottom: 2px solid var(--Grey-700);
    transform: ${({ isOpen }) => isOpen ? 'rotate(-135deg)' : 'rotate(45deg)'};
    transition: transform 0.3s ease;
  }
`;

const SelectOptionItem = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
	justify-content: space-between;
	margin: 0 auto;
  width: 216px;
  gap: 8px;
  
  .text {
    color: ${colors.neutral["900"]};
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }
  
  .img {
    width: 20px;
    height: 20px;
  }
`;

const DropdownItem = styled.div`
  border-bottom: 1px solid var(--Grey-300, #E5E5E5);
  display: flex;
  width: 100%;
  height: 48px;
  padding: 12px 32px;
  align-items: center;
  background: white;
  color: var(--Grey-700, #666);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  cursor: pointer;

  &:hover {
    background: var(--Primary-Violet-050, #F3E7FE);
  }

  &.selected {
    background: var(--Primary-Violet-050, #F3E7FE);
  }
`;

const orderList = [
	{ value: "createdAt", displayName: "Recently Added" },
	{ value: "follower", displayName: "Instagram followers" },
	{ value: "search_volume", displayName: "Search volume" },
];

interface CountryProps {
	query: FavoriteCelebListFilterQuery;
	setQuery: Function;
}

const AdvertiserFavoriteOrder = ({ query, setQuery }: CountryProps) => {
	const [isOpen, setIsOpen] = React.useState(false);
	const selectedOrder = orderList.find(order => order.value === query.order__by);
	const dropdownRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
				setIsOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<SelectWrapper ref={dropdownRef}>
			<SelectedDropdownItem isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
				<SelectOptionItem>
					<div className="text">
						{selectedOrder?.displayName || "Select order"}
					</div>
					<img className="img" src="/svg/dashboard/advertiser/drop_down.svg" alt="drop_down" style={{ width: "20px", height: "20px"}}/>
				</SelectOptionItem>
			</SelectedDropdownItem>

			{isOpen && (
				<DropdownContainer>
					{orderList.map((order) => (
						<DropdownItem
							key={`select_option_${order.value}`}
							className={order.value === query.order__by ? 'selected' : ''}
							onClick={() => {
								setQuery("order__by", order.value);
								setIsOpen(false);
							}}
						>
							{order.displayName}
						</DropdownItem>
					))}
				</DropdownContainer>
			)}
		</SelectWrapper>
	);
};

export default AdvertiserFavoriteOrder;
