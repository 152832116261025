import { UseFormSetError } from "react-hook-form";
import {
  SignupErrorResponse,
  SignupFormData, UserInformation, userType,
} from "../components/interfaces/auth.interfaces";
import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import {postSignup} from "../api/auth";
import {useNavigate} from "react-router-dom";
import {useAtom} from "jotai/index";
import {userDataAtom} from "../atoms/auth";

const isFieldInSignupFormData = (
  field: string
): field is keyof SignupFormData => {
  return [
    "email",
    "password",
    "passwordConfirm",
    "phone",
    "companyName",
    "position",
  ].includes(field);
};

export const useSignupMutation = (
  setError: UseFormSetError<SignupFormData>
) => {
  const navigate = useNavigate();
  const [_, setUserData] = useAtom(userDataAtom);

  return useMutation<
    AxiosResponse,
    AxiosError<SignupErrorResponse>,
    SignupFormData
  >({
    mutationFn: postSignup,
    onSuccess: async (data, variables) => {
      const user = data.data.user;
      try {
        const userData: UserInformation = {
          id: user.id,
          email: user.email,
          createdAt: user.createdAt,
          updatedAt: user.updatedAt,
          deletedAt: user.deletedAt,
          companyName: user.companyName,
          companyType: user.companyType,
          isVerification: user.isVerification,
          position: user.position,
          phone: user.phone,
          permissionId: user.permissionId,
        };
        setUserData(userData);
        navigate('/verification-required');
      } catch (error) {
        setError("email", {
          type: "server",
          message: "An unexpected error occurred after signup. Please try logging in manually.",
        });
      }
    },
    onError: (error) => {
      const response = error.response?.data;

      // Handle the format shown in the screenshot: {ok: false, statusCode: 400, message: "..."}
      if (response?.message && typeof response.message === 'string') {
        setError("email", {
          type: "server",
          message: response.message,
        });
        return;
      }

      // Handle the original format with message array
      if (response?.message && Array.isArray(response.message)) {
        response.message.forEach((err) => {
          if (isFieldInSignupFormData(err.field)) {
            setError(err.field, {
              type: "server",
              message: err.messages[0],
            });
          }
        });
      } else {
        setError("email", {
          type: "server",
          message: "An unexpected error occurred. Please try again.",
        });
      }
    },
  });
};
