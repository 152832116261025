export const colors = {
  purple: {
    50: '#F3E7FE',
    100: '#DDC4FD',
    200: '#C79BFD',
    300: '#AF6EFD', // Primary
    400: '#9B47FC',
    500: '#860CF9',
    600: '#7B01F2',
    700: '#6A00EA',
    800: '#5900E5',
    900: '#3800E0',
    1000:'#9747FF'
  },
  blue: {
    50: '#E3F2FD',
    100: '#BBDEFB',
    200: '#90CAF9',
    300: '#64B5F6',
    400: '#42A5F5',
    500: '#2196F3', // Main Blue
    600: '#1E88E5',
    700: '#1976D2',
    800: '#1565C0',
    900: '#0D47A1',
  },
  orange: {
    50: '#FFF3E0',
    100: '#FFE0B2',
    200: '#FFCC80',
    300: '#FFB74D',
    400: '#FFA726',
    500: '#FF9800', // Main Orange
    600: '#FB8C00',
    700: '#F57C00',
    800: '#EF6C00',
    900: '#E65100',
  },
  neutral: {
    0: '#FFFFFF',
    50: '#FBFBFB',
    100: '#F6F6F6',
    200: '#F1F1F1',
    300: '#E5E5E5',
    400: '#C2C2C2',
    500: '#A4A4A4',
    600: '#7A7A7A',
    700: '#666666',
    800: '#474747',
    900: '#252525', // Logo
    black: '#000000',
  },
  aligo: {
    50: '#FDE4EB',
    100: '#FABACE',
    200: '#F78DAE',
    300: '#F35F8D',
    400: '#EF3A74',
    500: '#EC0E5D',
    600: '#DB0A5B', // Aligo main
    700: '#C50656',
    800: '#B10052',
    900: '#8C004C',
  },
  bora: {
    50: '#F3E7FE',
    100: '#DDC4FD',
    200: '#C79BFD',
    300: '#AF6EFD',
    301: '#AE6EFD',
    400: '#9B47FC',
    500: '#860CF9',
    600: '#7B01F2',
    700: '#6A00EA',
    800: '#5900E5',
    900: '#3800E0',
    1000: '#9747FF'
  },
  permissionCeleb: {
    'closed' : '#FD8700',
    'success': '#E0F7FA',
    'rejected': '#FFEBEE',
    'pending': '#FFF3E0',
    'successes': '#00ACC1',
  },
  alert: {
    success: '#4CAF50',
    warning: '#FF9800',
    error: '#F44336',
  },
  main: {
    bora: '#AF6EFD', // Primary color
    bora2: '#AE6EFD', // 추가된 색상
    black: '#252525',
    white: '#FFF',
    gray: '#F1F1F1',
    blue: '#1976D2',
    ggray: '#444444',
    main: '#fff0',
  },
  email: {
    blue: '#2196F3',
    sendbutton: '#1E88E5',
  },
};
