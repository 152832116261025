import React, { useState } from "react";
import Section from "../../layout/Section";
import { CelebInfo } from "../../interfaces/celeb.interfaces";
import {ECelebType} from "../celebCard/CelebCard";
import PermissionGrantModals from "../../modals/PermissionGrantModals";
import ClosedPermissionRequestModals from "../../modals/ClosedPermissionRequestModals";

interface CelebNameSectionProps {
  celebInfo: CelebInfo;
}

const CelebNameSection = ({ celebInfo }: CelebNameSectionProps) => {
  const celebId = celebInfo.id;
  const celebType = celebInfo.type;

  const [modalMessage, setModalMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [isClosedRequestPermissionBtn, setIsClosedRequestPermissionBtn] = useState(false);

  const closeModal = () => {
    setShowModal(false);
    setModalMessage("");
  };

  const closeGrantModal = () => {
    setIsSuccessModal(false)
    window.location.reload();
  }

  const changeClosedRequestPermissionBtn = () => {
    setIsClosedRequestPermissionBtn(true);
  }

  return (
    <Section mt={6.6}>
      {
        (showModal &&
          celebType === ECelebType.CLOSED && !isSuccessModal) && (
          <ClosedPermissionRequestModals
            onClose={closeModal}
            celebId={celebId}
            setIsSuccessModal={setIsSuccessModal}
            changeClosedRequestPermissionBtn={changeClosedRequestPermissionBtn}
          />
        )
      }
      {
        (showModal &&
          celebType === ECelebType.CLOSED && isSuccessModal) && (
          <PermissionGrantModals onClose={closeGrantModal} />
        )
      }
    </Section>
  );
};

export default CelebNameSection;
