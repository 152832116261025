import React from 'react';
import styled from "styled-components";
import PendingIcon from '@mui/icons-material/Pending';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import { colors } from '../../styles/color';
import { useAtom } from 'jotai';
import { celebPermissionAtom } from '../../atoms/celeb';

const Overlay = styled.div<{ isVisible: boolean }>`
	display: ${({ isVisible }) => (isVisible ? "flex" : "none")};
	position: fixed;
	z-index: 10;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.4);
	backdrop-filter: blur(2px);
	justify-content: center;
	align-items: center;
`;

const ModalContainer = styled.div<{ isPending: boolean }>`
	width: ${({ isPending }) => isPending ? '496px' : '400px'};
	background: ${colors.neutral['0']};
	border-radius: ${({ isPending }) => isPending ? '20px' : '8px'};
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
	padding: ${({ isPending }) => isPending ? '40px' : '24px'};
	text-align: center;
	position: relative;
`;

const TitleContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	margin-bottom: 24px;
`;

const PendingIconContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 35px;
	height: 35px;
	border-radius: 50%;
	background: ${colors.permissionCeleb['pending']};
	border: 1px solid ${colors.alert.warning};

	svg {
		color: ${colors.alert.warning};
		font-size: 24px;
	}
`;

const StatusIconContainer = styled.div<{ status: 'success' | 'rejected' }>`
	margin: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 48px;
	height: 48px;
	border-radius: 50%;
	background: ${({ status }) => status === 'rejected' ? colors.permissionCeleb['rejected'] : colors.neutral['0']};
	border: 2px solid ${({ status }) => status === 'rejected' ? colors.alert.error : colors.neutral['0']};

	svg {
		font-size: 32px;
		color: ${({ status }) => status === 'rejected' ? colors.alert.error : colors.permissionCeleb['success']};
	}

	img {
		width: 24px;           
		height: 24px;
	}
`;

const Title = styled.h2<{ isPending: boolean }>`
	font-family: 'Inter', sans-serif;
	font-size: 20px;
	font-weight: 700;
	line-height: 19.2px;
	letter-spacing: 0px;
	text-align: center;
	color: ${colors.main.black};
	margin: ${({ isPending }) => isPending ? '0' : '8px 0 28px'};
`;

const Content = styled.p`
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	color: ${colors.main.black};
	margin: 0 0 8px;
	text-align: center;
`;

const SubContent = styled.p`
	font-family: 'Inter', sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	color: ${colors.neutral['600']};
	margin: 0 0 24px;
`;

const PendingButton = styled.button`
	width: 160px;
	height: 52px;
	border: none;
	border-radius: 4px;
	background-color: ${colors.main.black};
	color: ${colors.main.white};
	font-size: 16px;
	font-weight: 500;
	cursor: pointer;
	margin-bottom: 20px;

	&:hover {
		background-color: ${colors.main.ggray};
	}
`;

const StatusButton = styled.button`
	width: 160px;
	height: 52px;
	border: 1px solid ${colors.main.black};
	border-radius: 4px;
	background-color: ${colors.main.black};
	color: ${colors.main.white};
	font-size: 16px;
	font-weight: 500;
	cursor: pointer;

	&:hover {
		background-color: ${colors.main.ggray};
	}
`;

const EmailInfo = styled.p`
	font-family: 'Inter', sans-serif;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
	color: ${colors.neutral['600']};
	margin: 0;

	a {
		color: ${colors.email.blue};
		text-decoration: none;
		
		&:hover {
			text-decoration: underline;
		}
	}
`;

interface IPermissionPendingSuccessModalsProps {
	isVisible: boolean;
	titleMessage: string;
	status: 'pending' | 'success' | 'rejected';
	onClose: (event: React.MouseEvent) => void;
	celebId: string;
}

const PermissionPendingSuccessModals: React.FC<IPermissionPendingSuccessModalsProps> = ({
	isVisible,
	titleMessage,
	status,
	onClose,
	celebId
}) => {
	const isPending = status === 'pending';
	const [, setCelebPermissions] = useAtom(celebPermissionAtom);

	const handleClose = (event: React.MouseEvent) => {
		setCelebPermissions(prev => ({
			...prev,
			[celebId]: status === 'rejected' ? 'REJECTED' : 
					  status === 'pending' ? 'PENDING' : prev[celebId]
		}));
		onClose(event);
	};

	return (
		<Overlay isVisible={isVisible} onClick={handleClose}>
			<ModalContainer isPending={isPending} onClick={(e) => e.stopPropagation()}>
				{isPending ? (
					<>
						<TitleContainer>
							<PendingIconContainer>
								<PendingIcon />
							</PendingIconContainer>
							<Title isPending={true}>{titleMessage}</Title>
						</TitleContainer>
						<Content>
							Limited celebrity have restricted access to detailed pages.
						</Content>
						<SubContent>
							Estimated Response Time : within 3 business days
						</SubContent>
						<PendingButton onClick={handleClose}>
							OK
						</PendingButton>
						<EmailInfo>
							For a faster response, please reach out via email below.
							<br />
							<a href="mailto:support@aligo.ai">support@aligo.ai</a>
						</EmailInfo>
					</>
				) : (
					<>
						{status === 'rejected' ? (
							<img src="/image/do_not_disturb_on.png" alt="rejected" style={{ marginBottom: '8px' }} />
						) : (
							<StatusIconContainer status={status as 'success' | 'rejected'}>
								<img src="/svg/done.svg" alt="success" />
							</StatusIconContainer>
						)}
						<Title isPending={false}>{titleMessage}</Title>
						<StatusButton onClick={handleClose}>OK</StatusButton>
					</>
				)}
			</ModalContainer>
		</Overlay>
	);
};

export default PermissionPendingSuccessModals;