import React, {useCallback, useEffect, useMemo, useState} from "react";
import CelebListSidebar from "./CelebListSidebar";
import CelebCardList from "./CelebCardList";
import Filter from "./Filter";
import styled from "styled-components";
import {CelebListFilterQuery} from "../interfaces/filter.interface";
import Layout from "../layout/Layout";
import {useAtom} from "jotai";
import {celebFilterAtom, displayCountryAtom} from "../../atoms/filter";
import {useCelebList, useContactCelebList} from "../../hooks/useCeleb";
import {countryList} from "../../utils/utils";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ContactNow from './contactNow/ContactNow';
import {colors} from "../../styles/color";


const Wrapper = styled.div`
  display: flex;
  max-width: 1600px;
  margin: auto;

  @media (max-width: 1250px) {
    flex-direction: column;
    gap: 2rem;
    padding: 0;
  }
`;

const MainContentWrapper = styled.div`
  max-width: 1280px;
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: auto;
  
  @media (max-width: 1250px) {
      .contact-now {
        display: flex;
        justify-content: center;
        align-items: center;
    }
  }

  @media (max-width: 650px) {
    width: 100%;
  }
`;

const MainSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  
  @media (max-width: 1250px) {
    width: 100%;
    max-width: 100%;
    padding: 0 16px;
    gap: 15px;
  }
  
  @media (max-width: 760px) {
    width: 100%;
    padding: 0 12px;
    gap: 10px;
  }
`;

const MainSectionHeader = styled.div`
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 40px;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 10;
  transition: all 0.3s ease;

  @media (max-width: 650px) {
    position: relative;
    display: flex;
    flex-direction: column;
  }  
`;

const CelebListMapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  @media (max-width: 1250px) {
    padding: 0 16px;
  }
`;

const CelebListMap = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  color: #7a7a7a;
  white-space: nowrap;

  img {
    width: 16px;
    height: 16px;
  }

  span {
    &:first-of-type {
      color: #b3b3b3; /* Lighter gray for "Worldwide" */
    }

    &:nth-of-type(2) {
      color: #b3b3b3; /* Lighter gray for the arrow */
    }

    &:last-of-type {
      color: #7A7A7A; /* Darker gray for the selected country */
    }
  }
`;

const LoadingContainer = styled.div`
  position: fixed;
  top: 60%; /* 수직 중앙 */
  left: 50%; /* 수평 중앙 */
  transform: translate(-10%, -50%); /* 중앙 정렬 보정 */
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const LoadingImage = styled.img`
  width: 200px;
  height: 100px;
`;

const CelebListMapContent = styled.div`
  color: ${colors.neutral["500"]};
  font-family: Inter, sans-serif;
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 10px;
  font-weight: 400;
  line-height: 120%;
`;


const CelebList = () => {
  const [query, setQuery] = useAtom<CelebListFilterQuery>(celebFilterAtom);
  const ENV = process.env.REACT_APP_NODE_ENV as 'prod' | 'stage' | 'dev';

  const [likedCelebs, setLikedCelebs] = useState<Set<string>>(() => new Set());
  const [displayCountry] = useAtom(displayCountryAtom);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
    isPending,
  } = useCelebList(query, ENV);

  const { data: contactData } = useContactCelebList(ENV);

  // 초기 데이터 로드 시 좋아요 상태 설정
  useEffect(() => {
    if (contactData?.data) {
      setLikedCelebs(new Set(
        contactData.data
          .filter((celeb:any) => celeb.isLike)
          .map((celeb:any) => celeb.id)
      ));
    }
  }, [contactData?.data]);

  const handleLoadMore = useCallback(
    async () => {
    if (hasNextPage && !isFetchingNextPage) {
      await fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  // 모든 셀럽 데이터를 하나의 Map으로 관리
  const allCelebsMap = useMemo(() => {
    const map = new Map();

    // CelebList 데이터 추가
    if (data?.pages) {
      data.pages.forEach(page => {
        if (page.data && page.data.data && Array.isArray(page.data.data)) {
          page.data.data.forEach((celeb: any) => {
            map.set(celeb.id, celeb);
          });
        }
      });
    }

    // ContactNow 데이터 추가
    if (contactData?.data && Array.isArray(contactData.data)) {
      contactData.data.forEach((celeb: any) => {
        map.set(celeb.id, celeb);
      });
    }

    return map;
  }, [data?.pages, contactData?.data]);

  // ContactNow에 전달할 데이터에도 좋아요 상태 반영
  const contactCelebsWithLikeStatus = useMemo(() => {
    if (!contactData?.data) return [];
    return contactData.data.map((celeb: any) => ({
      ...celeb,
      isLike: likedCelebs.has(celeb.id),
      type: celeb.type,
      limitedCelebPermission: celeb.limitedCelebPermission,
    }));
  }, [contactData?.data, likedCelebs]);

  const onFavoriteUpdate = useCallback((id: string, status: boolean) => {
    // 해당 셀럽이 존재하는지 확인
    if (allCelebsMap.has(id)) {
      setLikedCelebs(prev => {
        const newSet = new Set(prev);
        if (status) {
          newSet.add(id);
        } else {
          newSet.delete(id);
        }
        return newSet;
      });
    }
  }, [allCelebsMap]);

  const celebs = useMemo(() => {
    if (!data?.pages) return [];

    return data.pages.flatMap(page => {
      return page.data && page.data.data ? page.data.data : [];
    });
  }, [data?.pages]);

  // 상위 20% 셀럽 ID Set을 직접 추출
  const egrCelebsTopTwenty = useMemo(() => {
    if (!data?.pages || !data.pages[0]?.data?.egrCelebsTopTwenty) return [];
    return data.pages[0].data.egrCelebsTopTwenty.map((celeb: {
      id: string;
      name: string;
      egr: number;
    }) => {
      return {
        id: celeb.id,
        name: celeb.name,
        egr: celeb.egr,
      }
    });
  }, [data?.pages, allCelebsMap]);

  // 상위 20% 셀럽 ID Set을 직접 추출
  const followersCelebsTopTwenty = useMemo(() => {
    if (!data?.pages || !data.pages[0]?.data?.followersCelebsTopTwenty) return [];
    return data.pages[0].data.followersCelebsTopTwenty.map((celeb: {
      id: string;
      name: string;
      followersCnt: number;
    }) => {
      return {
        id: celeb.id,
        name: celeb.name,
        followersCnt: celeb.followersCnt,
      }
    });
  }, [data?.pages, allCelebsMap]);

  // Target Countries 상위 20% 셀럽 추출
  const targetCountryTopTwenty = useMemo(() => {
    if (!data?.pages || !data.pages[0]?.data?.targetCountryTopTwenty) return [];
    return data.pages[0].data.targetCountryTopTwenty.map((celeb: {
      id: string;
      name: string;
      targetCountryValue: number;
    }) => {
      return {
        id: celeb.id,
        name: celeb.name,
        targetCountryValue: celeb.targetCountryValue,
      }
    });
  }, [data?.pages, allCelebsMap]);

  const getCountryLabel = (values: string[]): string => {
    const labels = values.map((value) => {
      const country = countryList.find((country) => country.value === value);
      return country ? country.label : value;
    });

    return labels.join(' / ');
  };

  const updateArray = (target: string[], value: string, checked: boolean) => {
    return checked ? [...target, value] : target.filter((item) => item !== value);
  };

  const queryHandler = (
    name: keyof CelebListFilterQuery,
    value: string| string[] | number,
    checked?: boolean
  ) => {
    setQuery((prevQuery) => {
      const targetValue = prevQuery[name];
      if (checked !== undefined && typeof value === "string" && Array.isArray(targetValue)) {
        return {
          ...prevQuery,
          [name]: updateArray(targetValue as string[], value, checked),
        };
      }

      return {
        ...prevQuery,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    refetch();
  }, [query.gender, query.order__by, refetch]);

  return (
    <Layout>
      {isPending && (
        <LoadingContainer>
          <LoadingImage src="/svg/celeblist/loading.gif" alt="Loading..." />
        </LoadingContainer>
      )}
      <Wrapper>
        <CelebListSidebar
          query={query}
          setQuery={queryHandler}
          refetch={refetch}
        />
        <MainContentWrapper>
          {
            <div className="contact-now">
              {(contactData?.data && contactData.data.length > 0) && (
                <ContactNow
                  celebs={contactCelebsWithLikeStatus}
                  onFavoriteUpdate={onFavoriteUpdate}
                />
              )}
            </div>
          }
          <MainSection>
            <MainSectionHeader>
              <Filter query={query} setQuery={queryHandler} />
              <CelebListMapWrapper>
                <CelebListMap>
                  <img src="/svg/celeblist/map.svg" alt="celebListMap" />
                  <span>Worldwide</span>
                  {displayCountry.length > 0 && displayCountry[0] !== "WW" && (
                    <>
                      <span>&gt;</span>
                      <span>{getCountryLabel(displayCountry)}</span>
                    </>
                  )}
                </CelebListMap>
                <CelebListMapContent>
                  The Followers and Search data are projected figures for the respective country.
                </CelebListMapContent>
              </CelebListMapWrapper>
            </MainSectionHeader>
            <CelebCardList
              celebList={celebs}
              hasMore={hasNextPage}
              loading={isFetchingNextPage}
              onLoadMore={handleLoadMore}
              onFavoriteUpdate={onFavoriteUpdate}
              egrCelebsTopTwenty={egrCelebsTopTwenty}
              followersCelebsTopTwenty={followersCelebsTopTwenty}
              targetCountryTopTwenty={targetCountryTopTwenty}
            />
          </MainSection>
        </MainContentWrapper>
      </Wrapper>
    </Layout>
  );
};

export default CelebList;
