import React from "react";
import styled from "styled-components";
import { CelebData } from "../interfaces/celeb.interfaces";
import { ECelebType } from "./celebCard/CelebCard";
import {colors} from "../../styles/color";

const Wrapper = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
  max-width: 768px;
  height: 100%;

  @media (max-width: 1350px) {
    flex-direction: column-reverse;
    gap: 0.8rem;
  }

  @media (max-width: 768px) {
    gap: 0.6rem;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    height: auto;
    gap: 0.5rem;
  }
`;

const ClosedCelebWrapper = styled.div`
  position: relative;
  width: 760px;
  height: 760px;
  filter: blur(8px);
  flex-shrink: 0;
  align-self: stretch;
  display: flex;
  gap: 0.8rem;
  overflow: hidden;
  border-radius: 4px;
  
  @media (max-width: 1400px) {
    width: 100%;
    height: 100%;
  }
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ImageListContainer = styled.div`
  width: 120px;
  background-color: ${colors.neutral["0"]};
  border-radius: 4px;
  display: flex;
  overflow-y: auto;
  
  @media (max-width: 1350px) {
    max-height: 120px;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: 8px;
  }
  
  /* 스크롤바 스타일링 */
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
  }
`;

const ImageList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 0.5rem;
  height: 100%;

  @media (max-width: 1350px) {
    flex-direction: row;
    width: max-content;
    height: auto;
    margin-bottom: 0;
    padding: 0.5rem;
    gap: 8px;
  }

  @media (max-width: 480px) {
    flex-direction: row;
    width: max-content;
    height: auto;
    margin-bottom: 0;
    padding: 0.5rem;
    gap: 8px;
  }
`;

interface ThumbnailItemProps {
  $isSelected: boolean;
}

const ThumbnailItem = styled.li<ThumbnailItemProps>`
  min-height: 120px;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1150px) {
    width: 100px;
    height: 100px;
    flex-shrink: 0;
  }

  @media (max-width: 480px) {
    width: 100px;
    height: 100px;
    flex-shrink: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

interface FocusImageContainerProps {
  $isClosed?: boolean;
}

const FocusImageContainer = styled.div<FocusImageContainerProps>`
  display: flex;
  flex: 1;
  width: 100%;
  aspect-ratio: 1 / 1;
  background-color: ${colors.neutral["0"]};
  border-radius: 4px;
  overflow: hidden;
  filter: ${props => props.$isClosed ? 'blur(8px)' : 'none'};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 480px) {
    max-width: 100%;
    max-height: 600px;
  }
`;

interface ImageViewProps {
  celebData: CelebData;
  selectedIndex: number;
  setSelectedIndex: (index: number) => void;
}

const ImageView = ({
                     celebData,
                     selectedIndex,
                     setSelectedIndex,
                   }: ImageViewProps) => {
  const photos = celebData.celebStockData;
  const celebType = celebData.celebInfo.type;

  if (photos.length === 0) {
    return null;
  }

  return (
    <>
      {celebType === ECelebType.CLOSED ? (
        <ClosedCelebWrapper>
          <img src={photos[selectedIndex].imgUrl} alt="selected_image" />
        </ClosedCelebWrapper>
      ) : (
        <Wrapper>
          <ImageListContainer>
            <ImageList>
              {photos.map((photo, index) => (
                <ThumbnailItem
                  key={`celeb_image_${photo.photoId}`}
                  onClick={() => setSelectedIndex(index)}
                  $isSelected={selectedIndex === index}
                >
                  <img
                    src={photo.imgUrl}
                    alt={`${celebData.celebInfo.name}_image_${index + 1}`}
                  />
                </ThumbnailItem>
              ))}
            </ImageList>
          </ImageListContainer>
          <FocusImageContainer $isClosed={false}>
            <img src={photos[selectedIndex].imgUrl} alt="selected_image" />
          </FocusImageContainer>
        </Wrapper>
      )}
    </>
  );
};

export default ImageView;
