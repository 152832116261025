import { privateClient } from "./axiosInstance";

export const sendLimitedCelebPermission = async (
	id: string
) => {
	const response = await privateClient.post(`/api/v1/email/send_limited_permission`, {
		id,
	});
	return response.data;
};

export const sendLimitedPermissionPendingToAgency = async (celebId: string) => {
	const response = await privateClient.post(`/api/v1/email/send-limited-permission-pending-agency`, {
		celebId,
	});
	return response.data;
};

export const sendLimitedPermissionPendingToAligo = async (celebId: string) => {
	const response = await privateClient.post(`/api/v1/email/send-limited-permission-pending-aligo`, {
		celebId,
	});
	return response.data;
};

export const sendClosedCelebPermission = async (
	id: string
) => {
	const response = await privateClient.post(`/api/v1/email/send_closed_permission`, {
		id
	});
	return response.data;
};

export const putClosedCelebPermission = async (
	id: string
) => {
	const response = await privateClient.put(`/api/v1/celeb/${id}/closed_permission`);
	return response.data;
};

export const putLimitedCelebPermission = async (
	id: string
) => {
	const response = await privateClient.put(`/api/v1/celeb/${id}/limited_permission`);
	return response.data;
};

