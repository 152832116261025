import { atom } from "jotai";
import {
  CelebInfo,
  CelebStockCart,
  CelebStockData,
} from "../components/interfaces/celeb.interfaces";
import { atomWithStorage } from "jotai/utils";
import { ContractType, CurrencyType } from "../components/interfaces/contract.interface";
import { ProjectData } from "../components/interfaces/project.interface";

export const selectedImageListAtom = atomWithStorage<CelebStockCart[]>(
  "selectedImageList",
  []
);

interface CelebContractAtomProps {
  projectData: ProjectData | undefined;
  celebInfo: CelebInfo | undefined;
  currencyType: CurrencyType;
  contractType: ContractType;
  amount: number;
  startDate: string;
  endDate: string;
  advertisingMedia: string[];
  country: string[];
  stockCart: CelebStockData[];
  additionalCondition: string;
  comment: string;
}

export const contractDefalutValue: CelebContractAtomProps = {
  projectData: undefined,
  celebInfo: undefined,
  currencyType: "",
  contractType: "",
  amount: 0,
  startDate: "",
  endDate: "",
  advertisingMedia: [],
  country: [],
  stockCart: [],
  additionalCondition: "",
  comment: "",
};

export const contractFormAtom =
  atom<CelebContractAtomProps>(contractDefalutValue);
