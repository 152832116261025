import axios from "axios";
import { requestRefresh } from "./auth";
import { getTokens, updateTokens } from "../atoms/auth";

export const getEnv = () => {
  return process.env.REACT_APP_API_URL;
}

export const publicClient = axios.create({
  baseURL: getEnv(),
  headers: {
    "Content-Type": "application/json",
  },
});

export const privateClient = axios.create({
  baseURL: getEnv(),
  headers: {
    "Content-Type": "application/json",
  },
});

privateClient.interceptors.request.use(
  async (config) => {
    const { accessToken, refreshToken } = getTokens();

    if (!accessToken && refreshToken) {
      const newTokens = await requestRefresh(refreshToken);
      updateTokens(newTokens.accessToken, newTokens.refreshToken);
      config.headers.Authorization = `Bearer ${newTokens.accessToken}`;
      return config;
    }

    if (accessToken && config.headers) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 응답 인터셉터 추가
privateClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // 401 Unauthorized 에러 처리
    if (error.response && error.response.status === 401) {
      // 로컬 스토리지 토큰 삭제 (로그아웃 상태로 만들기)
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');

      // 현재 URL을 저장하여 로그인 후 리다이렉트할 수 있도록 함 (선택사항)
      const currentPath = window.location.pathname + window.location.search;
      if (currentPath !== '/login') {
        localStorage.setItem('redirectAfterLogin', currentPath);
      }

      // 로그인 페이지로 리다이렉트
      window.location.href = '/login';

      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);
