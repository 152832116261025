import { useAtomValue } from "jotai";
import React from "react";
import styled from "styled-components";
import { userDataAtom } from "../../atoms/auth";
import {colors} from "../../styles/color";
import AdvertiserMenu from './celebrityAgency/AdvertiserMenu';
import AgencyMenu from "./celebrityAgency/AgencyMenu";

const SidebarContainer = styled.div`
  width: 240px;
  background-color: ${colors.main.white};
  color: ${colors.neutral["800"]};
  height: 100vh;
  flex-shrink: 0;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

const SidebarTitle = styled.div`
  margin-top: 20px;
  padding: 24px 32px;
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  
  .icon {
    width: 24px;
    height: 24px;
  }
  
  .title {
    ${({ theme }) => theme.font.regular};
    ${({ theme }) => theme.font.title1};
    color: ${colors.neutral["800"]};
    font-size: 20px;
  }
  &.border-top {
    border-top: 0.1rem solid ${colors.neutral["800"]};
    padding: 32px;
  }
  
  @media (max-width: 768px) {
    padding: 16px 16px;
    
    > .title {
      display: none;
    }
    > .icon {
			display: none;
		}
  }
`;

const SidebarList = styled.ul`
  display: flex;
  flex-direction: column;
  min-height: 42rem;

  &::before {
    content: "";
    border-top: 0.1rem solid ${colors.neutral["800"]};
    width: 70%;
    margin: 0 auto;
  }
  
  @media (max-width: 768px) {
    min-height: 10rem;
    
    &::before {
      display: none;
    }
  }
`;

const Sidebar = () => {
  const userType = useAtomValue(userDataAtom)?.companyType;

  return (
    <SidebarContainer>
      <SidebarTitle>
        <div className="icon">
          <img src="/svg/dashboard/agency/manage.svg" alt="Manage" />
        </div>
        <div className="title">Manage</div>
      </SidebarTitle>
      <SidebarList>
        {userType === "advertiser" && <AdvertiserMenu />}
        {userType === "celebrity_agency" && <AgencyMenu />}
      </SidebarList>
    </SidebarContainer>
  );
};

export default Sidebar;
