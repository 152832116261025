import React from 'react';
import styled from "styled-components";
import CloseIcon from '@mui/icons-material/Close';
import {colors} from "../../styles/color";
import {countryList} from "../../utils/utils";
import {useAtom} from "jotai/index";
import {celebCountryFilterAtom} from "../../atoms/filter";

const Wrapper = styled.div`
	display: flex;
	width: 256px;
	gap: 8px;
	flex-shrink: 0;
	flex-wrap: wrap;

	@media (max-width: 1250px) {
		justify-content: center;
		margin: 0 auto;
	}
`;

const CountryTagContainer = styled.div`
	display: flex;
	padding: 2px 8px;
	justify-content: center;
	align-items: center;
	border-radius: 20px;
	background-color: ${colors.neutral["100"]};
	
	.country_name {
		color: ${colors.neutral["700"]};
		font-family: Inter, sans-serif;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 120%;
	}
	
	.close_button {
		cursor: pointer;
		display: flex;
		align-items: center;
		color: #666;
		
		&:hover {
			color: #333;
		}
	}
`;

interface SelectCountryTagsProps {
	countries: string[];
	onRemoveCountry?: (country: string) => void;
}

const SelectCountryTags = ({
	countries,
	onRemoveCountry
}: SelectCountryTagsProps) => {
	const [, setSelectedCountryDisplay] = useAtom(celebCountryFilterAtom);

	const removeCountry = (countryToRemove: string) => {
		if (onRemoveCountry) {
			// 국가를 제거한 후 남은 국가 배열 계산
			const updatedCountries = countries.filter(c => c !== countryToRemove);

			// 모든 국가가 제거되었을 때 "Worldwide" 표시 설정
			if (updatedCountries.length === 0) {
				setSelectedCountryDisplay(["Worldwide"]);
			}

			// 부모 컴포넌트의 onRemoveCountry 호출
			onRemoveCountry(countryToRemove);
		}
	};

	return (
		<Wrapper>
			{countries && countries.length > 0 ? (
				countries.map((country, index) => (
					<CountryTagContainer key={index}>
						<span className="country_name">
							{countryList.find(item => item.value === country)?.label}
						</span>
						{onRemoveCountry && (
							<span className="close_button" onClick={() => removeCountry(country)}>
								<CloseIcon style={{ fontSize: 16 }} />
							</span>
						)}
					</CountryTagContainer>
				))
			) : (
				<CountryTagContainer>
					<span className="country_name">WorldWide</span>
					{onRemoveCountry && (
						<span className="close_button" onClick={() => removeCountry("WW")}>
							<CloseIcon style={{ fontSize: 16 }} />
						</span>
					)}
				</CountryTagContainer>
			)}
		</Wrapper>
	);
};

export default SelectCountryTags;
