import {CelebListFilterQuery, FavoriteCelebListFilterQuery} from "../components/interfaces/filter.interface";
import { privateClient } from "./axiosInstance";
import {CelebPermissionStatus} from "../components/dashboard/celebrityAgency/MyPermissionCelebList";

export const getCelebList = async (query: CelebListFilterQuery) => {
  const filteredParams = Object.fromEntries(
    Object.entries(query).filter(
      ([, value]) =>
        value !== "" && value !== null && value !== undefined && value !== 0
    )
  );

  const response = await privateClient.get("/api/v1/celeb", {
    params: {
      ...filteredParams,
      page: query.page || 1,
      take: query.take || 10,
    },
  });

  return response.data;
};

export const getContactCelebList = async () => {
  const response = await privateClient.get("/api/v1/celeb/contact");
  return response.data;
}

export const getFavoriteCelebList = async (query: FavoriteCelebListFilterQuery) => {
  const response = await privateClient.get("/api/v1/celeb", {
    params: {
      isLike: true,
      gender: query.gender,
      order__by: query.order__by,
    },
  });

  return response.data.data;
};

export const getCeleb = async (
  id: string | undefined,
  countries: string[],
) => {
  if (!id) return null;
  const response = await privateClient.get(`/api/v1/celeb/${id}`, {
    params: { countries: countries },
  });
  return response.data;
};

export const getCelebHighlight = async (id: string | undefined) => {
  const response = await privateClient.get(`/api/v1/celeb/${id}/highlights`);

  return response.data;
};

export const getCelebInContract = async () => {
  const response = await privateClient.get("/api/v1/contract/celeb_only");

  return response.data;
};

export const getCelebOnCelevu = async () => {
  const response = await privateClient.get("/api/v1/celeb/registered-celeb");
  return response.data;
};

export const getCelebPermissionCelevu = async (status: CelebPermissionStatus) => {
  return await privateClient.get(`/api/v1/celeb/permission-celeb`, {
    params: {
      permissionStatus: status,
    },
  });
};
