import React from 'react';
import styled from "styled-components";
import {colors} from "../../../styles/color";
import {ECelebType} from "../../celeb/celebCard/CelebCard";
import { CelebRequestContent } from './CelebRequestContent';
import {CelebPermissionStatus} from "./MyPermissionCelebList";
import PermissionButtons from './PermissionButtons';
import MyPermissionRequestDate from "./MyPermissionRequestDate";

const Container = styled.div`
	width: 484px;
	display: flex;
	padding: 40px 32px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 8px;
	border: 1px solid ${colors.neutral["300"]};
	background-color: #FFF;

	@media (max-width: 768px) {
		width: 100%;
		max-width: 400px;
		padding: 20px 16px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 8px;
	}
`;

const ProfileHeader = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	align-self: stretch;
	height: 92px;
`;

const SplitLine = styled.div`
	margin-top: 32px;
	margin-bottom: 32px;
	width: 420px;
	background: #7A7A7A;
	height: 1px;
	
	@media (max-width: 768px) {
		width: 100%;
		max-width: 352px;
		margin-top: 12px;
		margin-bottom: 12px;
	}
`;

const CelebCardBody = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	gap: 16px;
	align-self: stretch;
	
	img {
		display: flex;
		width: 160px;
		height: 160px;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
		gap: 8px;
		object-fit: cover;
	}
	
	.content {
		display: flex;
		width: 244px;
		flex-direction: column;
		align-items: flex-start;
		gap: 16px;
	}
`;

const PermissionTypeContainer = styled.div`
	display: flex;
	width: 244px;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
`;

const PermissionType = styled.div<{ isLimited: boolean }>`
	display: flex;
	align-items: center;
	gap: 4px;
	
	img {
		flex-shrink: 0;	
	}
	
	.celebType {
		color: ${props => props.isLimited 
			? 'var(--Crimson-600-Aligo, #DB0A5B)' 
			: colors.orange["800"]};
		font-size: 14px;
		font-family: Inter;
		font-style: normal;
		font-weight: 400;
		line-height: 120%; /* 16.8px */
	}
`;

const CelebInfo = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;
	align-self: stretch;
	
	.name {
		align-self: stretch;
		color: ${colors.neutral["800"]};
		font-family: Inter;
		font-size: 20px;
		font-style: normal;
		font-weight: 700;
		line-height: 120%; /* 24px */	
	}
	
	.birthday {
		color: ${colors.neutral["600"]};
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 120%; /* 16.8px */
	}
	
	.job {
		color: ${colors.neutral["600"]};
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 120%; /* 16.8px */
	}
`;

const CelebName = styled.div`
	color: ${colors.neutral["800"]};
	font-family: Inter;
	font-size: 28px;
	font-style: normal;
	font-weight: 600;
	line-height: 100%; /* 28px */
`

const CelebPosition = styled.div`
	display: flex;
	align-items: center;
	margin-top: 16px;
	gap: 8px;
	
	img {
		background: var(--Primary-Violet-050, #F3E7FE);
		border-radius: 50%;
		border: 1px solid #C69BFD;
		display: flex;
		width: 20px;
		height: 20px;
		padding: 2px;
		align-items: center;
	}
`;

const CelebAddress = styled.div`
	margin-top: 12px;
	display: flex;
	align-items: flex-start;
	gap: 24px;
	flex: 1 0 0;
`;

const Email = styled.div`
	display: flex;
	align-items: flex-start;
	gap: 8px;
	flex: 1 0 0;
	color: var(--Grey-600, #7A7A7A);
	
	img {
		width: 16px;
		height: 16px;
		aspect-ratio: 1/1;	
	}
`;

const Phone = styled.div`
	display: flex;
	height: 16px;
	align-items: center;
	gap: 8px;
	color: var(--Grey-600, #7A7A7A);
	
	img {
		width: 16px;
		height: 16px;
		aspect-ratio: 1/1;	
	}
`;

export interface ICelebData {
	id: string;
	name: string;
	krName: string;
	birthday: string;
	type: string;
	gender: string;
	celebPermissionId: number;
	celebPermissionCreatedAt: string;
	celebPermissionUpdatedAt: string;
	celebPermissionDeletedAt: string | null;
	userId: string;
	celebId: string;
	celebMainImg: string;
	celebKeyword: string[];
	companyName: string;
	permissionStatus: string;
	email: string;
	position: string;
	phone: string;
}

export interface MyPermissionCelebItemProps {
	celebData: ICelebData;
	selectedTab: CelebPermissionStatus;
	onDataUpdate: () => Promise<void>;
}

const MyPermissionCelebItem = ({ celebData, selectedTab, onDataUpdate }: MyPermissionCelebItemProps) => {
	return (
		<Container>
			<ProfileHeader>
				<CelebName>
					{celebData.companyName || ""}
				</CelebName>
				<CelebPosition>
					{celebData.position && (
						<>
							<img src={"/svg/dashboard/agency/mood.svg"} alt=""/>
							<div>{celebData.position}</div>
						</>
					)}
				</CelebPosition>
				<CelebAddress>
					<Email>
						{celebData.email && (
							<>
								<img src={"/svg/dashboard/agency/mail.svg"} alt=""/>
								<div>{celebData.email}</div>
							</>
						)}
					</Email>
					<Phone>
						{celebData.phone && (
							<>
								<img src={"/svg/dashboard/agency/call.svg"} alt=""/>
								<div>{celebData.phone}</div>
							</>
						)}
					</Phone>
				</CelebAddress>
			</ProfileHeader>
			<SplitLine />
			<CelebCardBody>
				<div>
					<img src={celebData.celebMainImg} alt="celeb_image"/>
				</div>
				<div className="content">
					<PermissionTypeContainer>
						<PermissionType isLimited={celebData.type !== ECelebType.CLOSED}>
							<img
								src={celebData.type === ECelebType.CLOSED
									? "/svg/closed/closed_person.svg"
									: "/svg/limited/lock.svg"}
								alt={celebData.type.toLowerCase()}
								style={{ width: "16px", height: "16px" }}
							/>
							<div className="celebType">
								{celebData.type === ECelebType.CLOSED ? 'Closed Profile' : 'Limited Profile'}
							</div>
						</PermissionType>
						<CelebInfo>
							<div className="name">
								{celebData.name}
							</div>
							<div className="birthday">
								{celebData.birthday}, {celebData.gender}
							</div>
							<div className="job">
								{celebData.celebKeyword?.join(', ')}
							</div>
						</CelebInfo>
					</PermissionTypeContainer>
				</div>
			</CelebCardBody>
			<CelebRequestContent
				selectedTab={selectedTab}
				companyName={celebData.companyName}
				celebName={celebData.name}
			/>
			<PermissionButtons
				celebData={celebData}
				selectedTab={selectedTab}
				onDataUpdate={onDataUpdate}
			/>
			<MyPermissionRequestDate
				selectedTab={selectedTab}
				registerDate={celebData.celebPermissionCreatedAt}
				updateDate={celebData.celebPermissionUpdatedAt}
			/>
		</Container>
	);
};

export default MyPermissionCelebItem;
