import React from "react";
import styled from "styled-components";
import LockIcon from "@mui/icons-material/Lock";
import { colors } from "../../styles/color";


const Overlay = styled.div<{ isVisible: boolean }>`
	display: ${({ isVisible }) => (isVisible ? "flex" : "none")};
	position: fixed;
	z-index: 10;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.4);
	backdrop-filter: blur(2px);
	justify-content: center;
	align-items: center;
`;

const ModalContainer = styled.div`
	width: 496px;
	background-color: ${colors.neutral['0']};
	border-radius: 4px;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
	padding: 40px;
	text-align: center;
	position: relative;
`;

const TitleContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	margin-bottom: 24px;
`;

const IconContainer = styled.div`

`;

const Title = styled.h2`	
	font-size: 16px;
	font-weight: 700;
	color: ${colors.main.black};
	line-height: 19.2px;
	margin: 0;
`;

const Content = styled.p`
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	color: ${colors.main.black};
	margin: 0 0 5px;
	width: 100%;
	text-align: center;
	display: inline-block;
	white-space: nowrap;
	transform: translateX(-10px);
`;

const SubContent = styled.p`
	font-family: 'Inter', sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	color: ${colors.neutral['600']};
	margin: 0 0 24px;
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	gap: 12px;
	margin-bottom: 24px;
`;

const Button = styled.button<{ primary?: boolean }>`
	width: 160px;
	height: 52px;
	border: 1px solid ${({ primary }) => (primary ? "#252525" : "#cccccc")};
	border-radius: 4px;
	background-color: ${({ primary }) => (primary ? "#252525" : "#ffffff")};
	color: ${({ primary }) => (primary ? "#ffffff" : "#252525")};
	font-size: 16px;
	font-weight: 500;
	cursor: pointer;

	&:hover {
		background-color: ${({ primary }) => primary ? "#444444" : "#f5f5f5"};
	}

	&:disabled {
		cursor: not-allowed;
		opacity: 0.7;
	}
`;

const EmailInfo = styled.p`
	font-family: 'Inter', sans-serif;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
	color: ${colors.neutral['600']};
	margin: 0;

	a {
		color: ${colors.email.blue};
		text-decoration: none;
		
		&:hover {
			text-decoration: underline;
		}
	}
`;

interface IPermissionRequestModalsProps {
	isVisible: boolean;
	onClose: (event: React.MouseEvent) => void;
	onSubmit: (event: React.MouseEvent) => void;
	isLoading?: boolean;
	celebName: string;
}

const LimitedPermissionRequestModals: React.FC<IPermissionRequestModalsProps> = ({
	isVisible,
	onClose,
	onSubmit,
	isLoading = false,
	celebName,
}) => {
	return (
		<Overlay isVisible={isVisible} onClick={onClose}>
			<ModalContainer onClick={(e) => e.stopPropagation()}>

				<TitleContainer>
				<IconContainer>
				<img src="/svg/lock.svg" alt="lock" />
				</IconContainer>
				<Title>Limited Celebrity Permission</Title>
				</TitleContainer>
				<Content>
					Limited celebrity have restricted access to detailed pages.
					<br />
					Would you like to request more details about
					<br />
					{celebName}?
				</Content>
				<SubContent>
					Estimated Response Time :  within 3 business days
				</SubContent>
				<ButtonContainer>
					<Button onClick={onClose} disabled={isLoading}>
						Cancel
					</Button>
					<Button primary onClick={onSubmit} disabled={isLoading}>
						{isLoading ? "Submitting..." : "Submit"}
					</Button>
				</ButtonContainer>
				<EmailInfo>
					For a faster response, please reach out via email below.
					<br />
					<a href="mailto:support@aligo.ai">support@aligo.ai</a>
				</EmailInfo>
			</ModalContainer>
		</Overlay>
	);
};

export default LimitedPermissionRequestModals;
