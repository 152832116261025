import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import LogoIcon from "../components/icon/Logo";
import { Link, useSearchParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { SendResetPassword } from '../api/sendEmail';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {updateNewUserPassword} from "../api/auth";
import { AxiosError } from "axios";
import {z} from "zod";
import HelpMessage from "../components/icon/HelpMessage";
import { useNavigate } from 'react-router-dom';
import {colors} from "../styles/color";

import { PasswordInput } from '../components/input/Input';
import { ConfirmPasswordInput } from '../components/input/PasswordInput';

const Wrapper = styled.div`
	margin-top: 60px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 0 20px;
`;

const IconWrapperStyle = styled.div`
	cursor: pointer;
`;

const ContentWrapper = styled.div`
	margin-top: 50px;
	display: flex;
	width: 500px;
	padding: 60px 40px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 20px;

	border: 1px solid ${colors.neutral["300"]};
	background-color: white;
`;

const Title = styled.h2`
	font-size: 24px;
	font-weight: 500;
	margin-top: 24px;
	margin-bottom: 12px;
	color: #252525;
`;

const Description = styled.p`
  font-size: 16px;
  color: #666;
  margin-bottom: 32px;
  max-width: 400px;
`;

const Form = styled.form`
  width: 100%;
  max-width: 400px;
`;

const InputContainer = styled.div`
  height: 60px;
  padding: 20px;  // 상하좌우 여백
  display: flex;
  align-items: center;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  border: none;
  font-size: 16px;
  color: #252525;
  
  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #999;
  }
`;

const ResetYourPasswordEmailInput = styled.input`
	width: 100%;
	height: 60px;
	padding: 12px;
	font-size: 16px;
	border: 1px solid #E0E0E0;
	border-radius: 4px;

	&:focus {
		outline: none;
		border-color: #666;
	}

	&::placeholder {
		color: #999;
	}	
`;

const PasswordToggle = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: #666;
`;

const InputWrapper = styled.div`
  margin-bottom: 24px;
  text-align: left;
`;

const LabelWrapper = styled.div`
	display: flex;
	width: 420px;
	padding: 0 4px;
	align-items: center;
	gap: 8px;
	margin-bottom: 8px;
`;

const ValidationMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  height: 32px; // LabelWrapper와 동일한 높이로 설정
`;

const HelperText = styled.span`
  color: ${colors.neutral[600]};
  ${({ theme }) => theme.font.body3};
  ${({ theme }) => theme.font.regular};
  font-weight: 300;
  margin-left: 0.4rem;
  display: inline-block;
  white-space: nowrap;
  margin-top : 9.1px;
`;

const Label = styled.label`
  display: flex;  // block에서 flex로 변경
  align-items: center;  // 세로 가운데 정렬
  font-size: 16px;
  font-weight: 500;
  color: #7A7A7A;
  height: 32px;  // LabelWrapper와 동일한 높이
`;

interface SubmitButtonProps {
	isValid?: boolean;
	disabled?: boolean;
}

const SubmitButton = styled.button<SubmitButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
	width: 100%;
	height: 60px;
	padding: 12px;
	font-size: 16px;
	color: white;
	background-color: #AE6EFD;
	border: none;
	border-radius: 4px;
	cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
	margin-bottom: 8px;
	transition: background-color 0.3s ease;

	&:hover {
		background-color: ${({ isValid }) => isValid ? '#8A35FF' : '#555'};
	}

	&:disabled {
		background-color: #999;
		cursor: not-allowed;
	}
`;

const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #666;
  text-decoration: none;
  font-size: 16px;

  &:hover {
    color: #333;
  }
`;

const BackIcon = styled.span`
  font-size: 20px;
`;

const EmailSentContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
`;

const EmailText = styled.p`
	color: ${colors.neutral["900"]};
  font-size: 16px;
  margin: 12px 0 24px;
`;

const ResendLink = styled.span`
  color: #AE6EFD;
  cursor: pointer;
  text-decoration: underline;
  
  &:hover {
    color: #8A35FF;
  }
`;

const RequiredMark = styled.span`
  color: #FF4D4F;
  margin-left: 4px;
`;

const PasswordFormtNotice = styled.div`
	display: flex;
	gap: 8px;
`;

const PasswordInputWrapper = styled(InputWrapper)`
	position: relative;
`;

const ErrorMessage = styled.div`
	color: #FF4D4F;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-size: 14px;
`;

const SuccessButton = styled(Link)`
  width: 100%;
  height: 60px;
  padding: 12px;
  font-size: 16px;
  color: white;
  background-color: #AE6EFD;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #8A35FF;
  }
`;

const schema = z.object({
	password: z
		.string()
		.min(8, { message: "Password must be at least 8 characters long." }),
	confirmPassword: z
		.string()
		.min(8, { message: "Password must be at least 8 characters long." }),
});

const ResetPasswordPage = () => {
	const [email, setEmail] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [emailError, setEmailError] = useState('');
	const [isValidEmail, setIsValidEmail] = useState(false);
	const [isSendEmail, setIsSendEmail] = useState(false);
	const [searchParams] = useSearchParams();
	const token = searchParams.get('token');

	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [passwordError, setPasswordError] = useState('');
	// Set new password
	const [IsNewPassword, setIsNewPassword] = useState<boolean>(false);
	const navigate = useNavigate();

	// 이메일 유효성 검사 함수
	const validateEmail = (email: string) => {
		const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		return emailRegex.test(email);
	};
	// 이메일 입력 핸들러
	const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newEmail = e.target.value;
		setEmail(newEmail);

		if (newEmail === '') {
			setEmailError('Email is required');
			setIsValidEmail(false);
		} else if (!validateEmail(newEmail)) {
			setEmailError('Please enter a valid email address');
			setIsValidEmail(false);
		} else {
			setEmailError('');
			setIsValidEmail(true);
		}
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setIsLoading(true);

		if (!validateEmail(email)) {
			setEmailError('Please enter a valid email address');
			return;
		}

		try {
			await SendResetPassword(email);
			setIsSendEmail(true);
		} catch (error) {
			console.error('Reset password error:', error);
			if (error instanceof AxiosError && error.response) {
				const { data } = error.response;
				const { message } = data;
				setEmailError(message);
			} else {
				setEmailError('Failed to send reset password email. Please try again.');
			}
		} finally {
			setIsLoading(false);
		}
	};

	const handleResend = async () => {
		setIsSendEmail(false);
	};

	const handlePasswordSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		const validationResult = schema.safeParse({
			password,
			confirmPassword
		});

		if (!validationResult.success) {
			// Zod 검증 실패시 첫 번째 에러 메시지 표시
			setPasswordError(validationResult.error.errors[0].message);
			return;
		}

		if (password !== confirmPassword) {
			setPasswordError('Passwords do not match');
			return;
		}

		try {
			if (!token) return;
			await updateNewUserPassword(token, password, confirmPassword);
			setIsNewPassword(true);
			setPasswordError(''); // 성공 시에만 에러 메시지 초기화
		} catch (error) {
			if (error instanceof AxiosError && error.response) {
				const { data } = error.response;
				const { message } = data;
				setPasswordError(message); // alert 대신 에러 메시지 표시
			} else {
				setPasswordError('Failed to reset password. Please try again.'); // 기타 에러 처리
			}
		}
	};

	const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newPassword = e.target.value;
		setPassword(newPassword);

		// 비밀번호 유효성 검사
		if (newPassword.length < 8) {
			setPasswordError("Password must be at least 8 characters long.");
		} else if (confirmPassword && newPassword !== confirmPassword) {
			setPasswordError("Passwords do not match");
		} else {
			setPasswordError("");
		}
	};

	const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newConfirmPassword = e.target.value;
		setConfirmPassword(newConfirmPassword);

		// 비밀번호 유효성 검사
		if (password.length < 8) {
			setPasswordError("Password must be at least 8 characters long.");
		} else if (newConfirmPassword && password !== newConfirmPassword) {
			setPasswordError("Passwords do not match");
		} else {
			setPasswordError("");
		}
	};
	
	return (
		<Wrapper>
			<IconWrapperStyle onClick={() => navigate('/')}>
				<LogoIcon />
			</IconWrapperStyle>
			<ContentWrapper>
				{IsNewPassword ? (
					<>
						<Title>Password reset!</Title>
						<Description>
							Your password has been successfully reset!
						</Description>
						<SuccessButton to="/login">
							Log in
						</SuccessButton>
					</>
				) : token ? (
							<>
								<Title>Set new password</Title>
								<Description>
									Your new password must be different to<br/>
									previously used passwords.
								</Description>
								<Form onSubmit={handlePasswordSubmit}>
									{passwordError && (
										<ErrorMessage>{passwordError}</ErrorMessage>
									)}
									<PasswordInputWrapper>
										<PasswordFormtNotice>
											<Label>Password<RequiredMark>*</RequiredMark></Label>
											<HelperText>
											  Password must be at least 8 characters long.
											</HelperText>
										</PasswordFormtNotice>
										<InputContainer>
											<Input
												type={showPassword ? "text" : "password"}
												placeholder="Password must meet the required format"
												value={password}
												onChange={handlePasswordChange}
												required
											/>
											<PasswordToggle onClick={() => setShowPassword(!showPassword)}>
												{showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
											</PasswordToggle>
										</InputContainer>
									</PasswordInputWrapper>
									<PasswordInputWrapper>
										<Label>Confirm password<RequiredMark>*</RequiredMark></Label>
										<InputContainer>
											<Input
												type={showConfirmPassword ? "text" : "password"}
												placeholder="Confirm Password"
												value={confirmPassword}
												onChange={handleConfirmPasswordChange}
												required
											/>
											<PasswordToggle onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
												{showConfirmPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
											</PasswordToggle>
										</InputContainer>
									</PasswordInputWrapper>
									<SubmitButton
										type="submit"
										disabled={isLoading || !password || !confirmPassword || password !== confirmPassword || password.length < 8}
									>
										Reset password
									</SubmitButton>
								</Form>
							</>
						) : !isSendEmail ? (
							<>
								<Title>Reset your password</Title>
								<Description>
									Enter your email address and <br/>
									we'll send you a link to reset your password.
								</Description>
								<Form onSubmit={handleSubmit}>
									<InputWrapper>
										<LabelWrapper>
											<Label>Email</Label>
											{
												emailError && (
													<ValidationMessage>
														<img src={"/svg/resetpassword/error.svg"} alt="error_image"/>
														<ErrorMessage>
															{emailError}
														</ErrorMessage>
													</ValidationMessage>
												)
											}
										</LabelWrapper>
										<ResetYourPasswordEmailInput
											type="email"
											placeholder="Enter your email"
											value={email}
											onChange={handleEmailChange}
											required
										/>
									</InputWrapper>
									<SubmitButton
										type="submit"
										disabled={isLoading || !email || !!emailError}
										isValid={isValidEmail}
									>
										{isLoading ? 'Sending...' : 'Reset password'}
									</SubmitButton>
								</Form>
								<BackLink to="/login">
									<BackIcon>
										<ArrowBackIcon />
									</BackIcon>
									Back to log in
								</BackLink>
							</>
						) : (
							<EmailSentContent>
								<Title>Check your email</Title>
								<EmailText>We sent a Password reset link to<br />{email}</EmailText>
								<Description>
									Don't receive the email?{' '}
									<ResendLink onClick={handleResend}>Click to Resend</ResendLink>
								</Description>
								<BackLink to="/login">
									<BackIcon>
										<ArrowBackIcon />
									</BackIcon>
									Back to log in
								</BackLink>
							</EmailSentContent>
				)}
			</ContentWrapper>
		</Wrapper>
	);
};
export default ResetPasswordPage;