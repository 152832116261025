import React, { useState, useEffect } from "react";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import TimelineIcon from "@mui/icons-material/Timeline";
import InstagramIcon from "@mui/icons-material/Instagram";
import PublicIcon from "@mui/icons-material/Public";

import { useNavigate } from "react-router-dom";
import {
  CelebCardHoverContainer,
  CelebCardTitle,
  CelebCardWrapper,
  CelebInfo,
  CelebScore,
  CelebScoreList,
  CelebSummary, ClosedTooltip,
  FavoriteWrapper, LimitedTooltip,
} from "./CelebCard.styled";
import { CelebListData } from "../../interfaces/celeb.interfaces";
import ImageContainer from "../../common/ImageContainer";
import FavoriteButton from "../../button/FavoriteButton";
import {formatNumber, roundFollowers } from "../../../utils/utils";
import styled from "styled-components";
import LimitedPermissionRequestModals from "../../modals/LimitedPermissionRequestModals";
import {putLimitedCelebPermission, sendLimitedCelebPermission} from "../../../api/celebPermission";
import {AxiosError} from "axios";
import {DataErrorResponse} from "../../interfaces/auth.interfaces";
import PermissionPendingSuccessModals from "../../modals/PermissionPendingSuccessModals";
import { useAtom } from "jotai";
import { celebPermissionAtom } from '../../../atoms/celeb';


const LimitedLockIconImg = styled.div`
  border-radius: 50%;
`;

const ClosedCelebInfoText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  .age {
    white-space: pre-line;
  }

  .type {
    white-space: pre-line;
  }
`;

const ClosedCelebInstagramContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
  color: #AE6EFD;

  .score {
    display: flex;
    align-items: center;
    gap: 4px;

    // Followers 텍스트와 숫자를 한 줄로 표시
    .closed_name {
      margin: 0;
      &::after {
        content: ' ';  // 공백 추가
      }
    }
    
    .closed_score {
			display: flex;
			align-items: center;
      font-weight: 800;
		}
  }
`;

const FollowersTooltip = styled.div`
  // 위치 조정 
  width: 140px;
  bottom: 125%;
  left: 50%;
  padding: 1rem;
  word-wrap: break-word;
  overflow: visible;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  // 스타일링
  transform: translateX(-50%);
  background-color: #fff;
  color: #333;
  border-radius: 0.4rem;
  border: 1px solid #9A47FB;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  font-size: 12px;
  line-height: 1.4;
  transition: opacity 0.2s, transform 0.2s;
  z-index: 10;

  // 외부 삼각형
  &:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border: 12px solid transparent;
    border-top-color: #9A47FB;
    border-bottom: 0;
    margin-top: 0;
    z-index: 1;
  }
  // 흰색 내부 삼각형
  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border: 11px solid transparent;
    border-top-color: white;
    border-bottom: 0;
    margin-top: -1px;
    z-index: 2;
  }
  
  .title {
    font-weight: 400;
    width: 200px;
    gap: 4px;
    height: 17px;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: auto;

    img {
      margin-right: 0.4rem;
      width: 16px;
      height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .content {
    margin-top: 0.4rem;
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    left: auto;
    right: 0;
    transform: translateX(0);
  }
`;

const EgrTooltip = styled(FollowersTooltip)`
  border: 1px solid #388E3C;
  
  &:before {
    border-top-color: #388E3C;
  }
`;

const TargetCountryTooltip = styled(FollowersTooltip)`
  border: 1px solid #E57373;
  
  &:before {
    border-top-color: #E57373;
  }
`;

interface CelebLabelProps {
  color?: string;
}

const CelebLabel = styled.div<CelebLabelProps>`
  margin-top: 8px;
  display: inline-block;
  padding: 0.25rem 0.375rem; // 4px 6px
  align-items: center;
  gap: 4px;
  border-radius: 20px;
  color: ${props => props.color || '#FF6F00'};
  background-color: white;
  position: relative;
  
  .content {
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px; /* 12px */
    display: flex;
    align-items: center;
    gap: 4px;
    
    img {
      width: 16px;
      height: 16px;
      aspect-ratio: 1/1;
    }
  }
  
  &:hover {
    cursor: pointer;
    
    .tooltip {
      visibility: visible;
      opacity: 1;
    }
  }
`;

export enum ECelebType {
  PROFILING = "PROFILING",
  CONTACT = "CONTACT",
  LIMITED = "LIMITED",
  CLOSED = "CLOSED",
}


interface CelebCardProps {
  celebData: CelebListData & {
    onFavoriteStatusChange?: (celebId: string, newStatus: boolean) => void;
  };
  egrCelebsTopTwenty?: { id: string, name: string, egr: number }[];
  followersCelebsTopTwenty?: { id: string, name: string, followersCnt: number }[];
  targetCountryTopTwenty?: { id: string, name: string, targetCountryValue: number }[];
}

const CelebCard = ({
                     celebData,
                     egrCelebsTopTwenty,
                     followersCelebsTopTwenty,
                     targetCountryTopTwenty,
                   }: CelebCardProps) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [permissions, setPermissions] = useAtom(celebPermissionAtom);

  const isEgrTopTwentyCeleb = egrCelebsTopTwenty?.some(egrCeleb => egrCeleb.id === celebData.id);
  const isFollowersTopTwentyCeleb = followersCelebsTopTwenty?.some(followersCeleb => followersCeleb.id === celebData.id);
  const isTargetCountry = targetCountryTopTwenty?.some(targetCountry => targetCountry.id === celebData.id);

  const queryClient = useQueryClient();

  const permissionMutation = useMutation({
    mutationFn: async (celebId: string) => putLimitedCelebPermission(celebId),
    onSuccess: async () => {
      setShowModal(false);
      setShowSuccessModal(true);
      await sendLimitedCelebPermission(celebData.id);
      setPermissions(prev => ({
        ...prev,
        [celebData.id]: 'PENDING'
      }));
    },
    onError: (error: AxiosError<DataErrorResponse>) => {
      if (error.response?.data) {
        console.log(error.response.data.message);
      } else {
        console.log("error", error);
      }
    }
  });
  useEffect(() => {
    if (celebData.limitedCelebPermission) {
      queryClient.invalidateQueries({
        queryKey: ["getCelebList"]
      });
    }
  }, [celebData.limitedCelebPermission]);

  const closeModal = (event: React.MouseEvent) => {
    event.stopPropagation(); // 이벤트 전파 차단
    setShowModal(false);
  };

  const sendSubmitLimitedCelebPermission = async (event: React.MouseEvent) => {
    event.stopPropagation();
    try {
      await permissionMutation.mutateAsync(celebData.id);
    } catch (error) {
      console.error('Permission request failed:', error);
    }
  };

  const handleWrapperClick = (event: React.MouseEvent) => {
    if (celebData.type === ECelebType.LIMITED) {
      setShowModal(true);
      event.stopPropagation(); // 클릭 이벤트 전파 차단
    } else {
      navigate(`/celebs/${celebData.id}`);
    }
  };

  return (
    <CelebCardWrapper
      className="celeb"
      data-celeb-id={celebData.id}
      data-is-limited={celebData.type === ECelebType.LIMITED}
      data-is-trending={false}
      onClick={handleWrapperClick}
    >
        <ImageContainer
          width={26}
          height={26}
          borderRadius={0.4}
          isBlurred={celebData.type === ECelebType.CLOSED}
        >
        <img src={celebData.celebMainImg} alt={celebData.name + "thumbnail"} />
        <FavoriteWrapper>
          <FavoriteButton
            id={celebData.id}
            size={32}
            status={celebData.isLike}
            onChange={(id, newStatus) => celebData.onFavoriteStatusChange?.(id, newStatus)}
            color={{ true: "var(--crimson-600)", false: "var(--gray-050)" }}
          />
        </FavoriteWrapper>
      </ImageContainer>
      <CelebSummary>
        <CelebInfo>
          <CelebCardTitle>
            {celebData.type === "CLOSED" && (
              <CelebCardHoverContainer>
                <img src={"/svg/closed/closed_celeb.svg"} alt={"profiling"} style={{ width: "48px" }} />
                <ClosedTooltip className="tooltip">
                  <div className="title">
                    <img src={"/svg/closed/hover_person.svg"} alt={"profiling"}/>
                    <p>Closed Celebrity</p>
                  </div>
                  <p className="content">
                    Closed celebrity is displayed with a pseudonym and a placeholder image.
                    To access the actual details, please navigate to the detail page and
                    request permission.
                  </p>
                </ClosedTooltip>
              </CelebCardHoverContainer>
            )}
            {(celebData.type === "LIMITED" && celebData.limitedCelebPermission !== "APPROVED") && (
              <CelebCardHoverContainer>
                <LimitedLockIconImg>
                  <img src={"/svg/limited/lock.svg"} alt={"profiling"} style={{ width: "24px" }} />
                </LimitedLockIconImg>
                <LimitedTooltip className="tooltip">
                  <div className="title">
                    <img src={"/svg/limited/lock.svg"} alt={"profiling"}/>
                    <p>Limited Celebrity</p>
                  </div>
                  <p className="content">
                    This celebrity only provides information to approved users. Click on the celebrity card to apply for access.
                  </p>
                </LimitedTooltip>
              </CelebCardHoverContainer>
            )}
            {(celebData.type === "LIMITED" && celebData.limitedCelebPermission === "APPROVED")  && (
              <CelebCardHoverContainer>
                <LimitedLockIconImg>
                  <img src={"/svg/limited/open.svg"} alt={"profiling"} style={{ width: "24px" }} />
                </LimitedLockIconImg>
              </CelebCardHoverContainer>
            )}
            {
              celebData.type === ECelebType.CLOSED ? (
                <ClosedCelebInfoText>
                  <p className="age">
                    {celebData.birthday}, {celebData.gender}
                  </p>
                  <p className="type">{celebData.types.join(", ")}</p>
                </ClosedCelebInfoText>
              ) : (
                <div style={{ marginBottom: "0.5rem" }}>
                  <p className="name">{celebData.name}</p>
                </div>
              )
            }
          </CelebCardTitle>
          {
            celebData.type !== ECelebType.CLOSED && (
              <>
                <p className="age">
                  {celebData.birthday}, {celebData.gender} / {celebData.types.join(", ")}
                </p>

                <p style={{ display: "flex", gap: "4px" }}>
                  {
                    isFollowersTopTwentyCeleb && (
                      <CelebLabel color={"#9A47FB"}>
                          <img src="/svg/celeblist/followers.svg" alt="followers"/>
                        <FollowersTooltip className="tooltip">
                          <p className="content">
                            Top 20% of celebrities with the highest number of followers
                          </p>
                        </FollowersTooltip>
                      </CelebLabel>
                    )
                  }
                  {
                    isEgrTopTwentyCeleb && (
                      <CelebLabel color={"#388E3C"}>
                        <img src="/svg/celeblist/egr.svg" alt="egr"/>
                        <EgrTooltip className="tooltip">
                          <p className="content">
                            Top 20% of celebrities with the highest engagement rate
                          </p>
                        </EgrTooltip>
                      </CelebLabel>
                    )
                  }
                  {
                    isTargetCountry && (
                      <CelebLabel color={"#E57373"}>
                        <img src="/svg/celeblist/target_country.svg" alt="target_country.svg"/>
                        <TargetCountryTooltip className="tooltip">
                          <p className="content">
                            Top 20% of celebrities with the highest proportion of followers from the target countries
                          </p>
                        </TargetCountryTooltip>
                      </CelebLabel>
                    )
                  }
                </p>
              </>
            )
          }
        </CelebInfo>
        <CelebScoreList>
          <CelebScore>
            {celebData.type !== ECelebType.CLOSED ? (
              <>
                <InstagramIcon />
                <p className="name">Followers</p>
                <p className="score">
                  {celebData.instagramFollowersCnt
                    ? formatNumber(celebData.instagramFollowersCnt)
                    : "N/A"}
                </p>
              </>
              ) : (
                <ClosedCelebInstagramContainer>
                  <InstagramIcon style={{ color: '#AE6EFD'}} />
                  <p className="score">
                    <p className="closed_name">Followers</p>
                    <div className="closed_score">
                      {
                        celebData.instagramFollowersCnt
                        ? roundFollowers(formatNumber(celebData.instagramFollowersCnt || 0))
                       : "N/A"
                      }
                    </div>
                  </p>
                </ClosedCelebInstagramContainer>
              )
            }
          </CelebScore>
          <CelebScore>
            <TimelineIcon/>
              <p className="name">Search</p>
                <p className="score">
                  {
                    celebData.searchVolume
                    ? formatNumber(celebData.searchVolume)
                    : "N/A"
                  }
                </p>
          </CelebScore>
          <CelebScore>
            <PublicIcon />
            <p className="name">Popular Countries</p>
          </CelebScore>
          <CelebScore>
            <p className="score" style={{ marginLeft: "2.4rem" }}>
              {celebData.countryName.length
                ? celebData.countryName.map((country, index) => (
                  <React.Fragment key={country}>
                    <span
                      style={{
                        color: celebData.selectedCountry.includes(country) ? 'var(--purple-300)' : 'inherit'
                      }}
                    >
                      {country}
                    </span>
                    {index < celebData.countryName.length - 1 && ", "}
                  </React.Fragment>
                ))
                : "N/A"}
            </p>
          </CelebScore>
        </CelebScoreList>
      </CelebSummary>
      {showModal && (
        (() => {
          const currentPermission = permissions[celebData.id] || celebData.limitedCelebPermission;

          switch (currentPermission) {
            case "PENDING":
              return (
                <PermissionPendingSuccessModals
                  isVisible={showModal}
                  titleMessage={"Your request is being\n processed."}
                  status={"pending"}
                  onClose={closeModal}
                  celebId={celebData.id}
                />
              );
            case "REJECTED":
              return (
                <PermissionPendingSuccessModals
                  isVisible={showModal}
                  titleMessage={"Unfortunately, your request has not been approved at this time."}
                  status={"rejected"}
                  onClose={closeModal}
                  celebId={celebData.id}
                />
              );
            case "APPROVED":
              // 상세 페이지로 이동
              navigate(`/celebs/${celebData.id}`);
              return null; // 모달을 렌더링하지 않음
            default:
              return (
                <LimitedPermissionRequestModals
                  isVisible={showModal}
                  onClose={closeModal}
                  onSubmit={sendSubmitLimitedCelebPermission}
                  isLoading={permissionMutation.isPending}
                  celebName={celebData.name}
                />
              );
          }
        })()
      )}

      {showSuccessModal && (
        <PermissionPendingSuccessModals
          isVisible={showSuccessModal}
          titleMessage={"The request was successful."}
          status={"success"}
          onClose={() => setShowSuccessModal(false)}
          celebId={celebData.id}
        />
      )}
    </CelebCardWrapper>
  );
};

export default CelebCard;
