import React, { useState } from "react";
import { useCelebOnCelevu } from "../../../hooks/useCeleb";
import styled, { css } from "styled-components";
import ImageContainer from "../../common/ImageContainer";
import { formatNumber } from "../../../utils/utils";
import CheckIcon from '@mui/icons-material/Check';
import {colors} from "../../../styles/color";
import { useNavigate, useLocation } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  min-width: 300px;
  
  @media (max-width: 768px) {
    padding: 0 1rem;
  }
`;

const Title = styled.div`
  ${({ theme }) => theme.font.title1};
  ${({ theme }) => theme.font.regular};
  color: ${colors.neutral["800"]};

  @media (max-width: 768px) {
    display: none;
  }
`;

const TotalDataList = styled.div`
  margin-bottom: 40px;
  margin-top: 40px;
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  
  @media (max-width: 1024px) {
    gap: 1rem;
  }
  
  @media (max-width: 768px) {
    margin-top: -40px;
    flex-direction: column;
    gap: 1rem;
  }
`;

const TotalData = styled.div`
  border-radius: 0.8rem;
  width: 32rem;
  height: 12rem;
  border: 0.1rem solid ${colors.neutral["300"]};
  background-color: ${colors.main.white};
  align-content: center;
  text-align: center;
  
  @media (max-width: 1024px) {
    width: calc(33.333% - 0.67rem);
    height: 10rem;
  }
  
  @media (max-width: 768px) {
    width: 100%;
    height: 8rem;
  }

  > .title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    ${({ theme }) => theme.font.body2};
    ${({ theme }) => theme.font.regular};
    color: var(--gray-900);
    
    @media (max-width: 768px) {
      font-size: 1.4rem;
    }
  }
  > .score {
    margin-top: 0.8rem;
    ${({ theme }) => theme.font.h2};
    ${({ theme }) => theme.font.bold};
    color: var(--gray-900);
    
    @media (max-width: 768px) {
      font-size: 2rem;
      margin-top: 0.4rem;
    }
  }
`;

const CelebGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 32rem);
  gap: 2rem;
  
  @media (max-width: 1440px) {
    grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
  }
  
  @media (max-width: 1024px) {
    grid-template-columns: repeat(auto-fill, minmax(28rem, 1fr));
    gap: 1.5rem;
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
`;

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 300px;
  text-align: center;
  grid-column: 1 / -1;  // Add this to span all columns
  
  img {
    margin-bottom: 16px;
  }
  
  p {
    color: ${colors.neutral["600"]};
    ${({ theme }) => theme.font.body1};
  }
`;

const Celeb = styled.div`
  width: 100%;
  height: 36rem;
  background-color: var(--white);
  border-radius: 0.8rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  @media (max-width: 1024px) {
    height: 32rem;
  }
  
  @media (max-width: 768px) {
    height: 28rem;
  }

  &:hover {
    transform: translateY(-4px);
  }

  .celeb-name {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 12rem;
    padding: 2.2rem 2rem;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 0.8rem;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
    
    @media (max-width: 768px) {
      height: 10rem;
      padding: 1.6rem 1.5rem;
      gap: 0.6rem;
    }

    > .name {
      color: var(--white);
      ${({ theme }) => theme.font.title1};
      ${({ theme }) => theme.font.bold};
      
      @media (max-width: 768px) {
        font-size: 1.8rem;
      }
    }
    > .score-list {
      display: flex;
      align-items: center;
      width: 100%;
      color: var(--white);
      gap: 1.2rem;
      
      @media (max-width: 768px) {
        gap: 0.8rem;
      }

      > .score {
        display: flex;
        align-items: center;
        gap: 0.8rem;
        
        @media (max-width: 768px) {
          gap: 0.6rem;
          font-size: 1.2rem;
        }
      }
    }
  }
  
  > .celeb-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.8rem;
    width: 100%;
    
    @media (max-width: 768px) {
      padding: 0.8rem 1.4rem;
    }

    > .score {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.4rem;
      ${({ theme }) => theme.font.body2};
      ${({ theme }) => theme.font.regular};
      color: var(--gray-900);
      
      @media (max-width: 768px) {
        font-size: 1.2rem;
        gap: 0.3rem;
      }
    }
  }
`;

const iconList = {
  favorite: css`
    background-image: url("/svg/dashboard/favorite.svg");
    width: 2rem;
    height: 2rem;
  `,
  assignment: css`
    background-image: url("/svg/dashboard/assignment.svg");
    width: 2rem;
    height: 2rem;
  `,
  article: css`
    background-image: url("/svg/dashboard/article.svg");
    width: 2rem;
    height: 2rem;
  `,
  trendScore: css`
    background-image: url("/svg/dashboard/trend_score.svg");
    width: 2rem;
    height: 2rem;
  `,
  instagramScore: css`
    background-image: url("/svg/dashboard/instagram_score.svg");
    width: 2rem;
    height: 2rem;
  `,
};

const Icon = styled.span<{
  $type:
    | "favorite"
    | "assignment"
    | "article"
    | "trendScore"
    | "instagramScore";
}>`
  ${({ $type }) => iconList[$type]}
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

const ProfileTypeList = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
  justify-content: flex-end;

  @media (max-width: 1200px) {
    justify-content: flex-start;
  }
  
  @media (max-width: 768px) {
    gap: 8px;
    margin-bottom: 16px;
    flex-wrap: wrap;
  }
`;

const ProfileTypeButton = styled.button<{ $isSelected?: boolean; $color: string }>`
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 20px;
  border: 1px solid ${({ $isSelected, $color }) => $isSelected ? $color : colors.neutral["600"]};
  color: ${({$isSelected, $color}) => $isSelected ? $color : colors.neutral["600"]};
  background-color: ${({$isSelected, $color}) => $isSelected ? `${$color}20` : '#FBFBFB'};
  ${({theme}) => theme.font.body2};
  
  @media (max-width: 768px) {
    padding: 3px 6px;
    gap: 6px;
    font-size: 1.2rem;
  }
`;

const MoveDetailCelebButton = styled.div`
  display: flex;
  margin-left: auto;
  
  p {
    color: var(--grey-grey-300, #E5E5E5);
    text-decoration: underline;
    /* Body3/underline */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
    
  } 
  
  img {
    width: 20px;
    height: 20px;
  }
`;

enum ProfileType {
  PROFILING = 'PROFILING',
  LIMITED = 'LIMITED',
  CLOSED = 'CLOSED',
}

const MyCelebList = () => {
  const { data, isPending } = useCelebOnCelevu();
  const [selectedTypes, setSelectedTypes] = useState<ProfileType[]>([
    ProfileType.PROFILING,
    ProfileType.LIMITED,
    ProfileType.CLOSED
  ]);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleProfileType = (type: ProfileType) => {
    setSelectedTypes(prev => {
      if (prev.includes(type)) {
        // 모든 타입이 선택 해제되는 것을 방지
        if (prev.length === 1) return prev;
        return prev.filter(t => t !== type);
      } else {
        return [...prev, type];
      }
    });
  };

  const filteredCelebList = data?.data.celebList.filter(celeb =>
    selectedTypes.length === 0 || selectedTypes.includes(celeb.type as ProfileType)
  ) || [];

  const handleCelebClick = (celebId: string) => {
    navigate(`/celebs/${celebId}`, {
      state: { from: location.pathname } // 현재 경로를 state로 저장
    });
  };

  if (isPending) return <>Loading</>;

  return (
    <Container>
      <Title>Celevu에 등록된 셀럽</Title>
      <TotalDataList>
        <TotalData>
          <div className="title">
            <Icon $type="favorite" />
            Favorite
          </div>
          <div className="score">{data?.data.totalLikes}</div>
        </TotalData>
        <TotalData>
          <div className="title">
            <Icon $type="article" />
            Contract Drafting
          </div>
          <div className="score">{data?.data.contractDraftingCount}</div>
        </TotalData>
        <TotalData>
          <div className="title">
            <Icon $type="assignment" />
            Contract Completion
          </div>
          <div className="score">{data?.data.contractCompletionCount}</div>
        </TotalData>
      </TotalDataList>
      <ProfileTypeList>
        <ProfileTypeButton
          $isSelected={selectedTypes.includes(ProfileType.PROFILING)}
          $color="#1976D2"
          onClick={() => toggleProfileType(ProfileType.PROFILING)}
        >
          <CheckIcon /> Open Profile
        </ProfileTypeButton>
        <ProfileTypeButton
          $isSelected={selectedTypes.includes(ProfileType.LIMITED)}
          $color="#E91E63"
          onClick={() => toggleProfileType(ProfileType.LIMITED)}
        >
          <CheckIcon /> Limited Profile
        </ProfileTypeButton>
        <ProfileTypeButton
          $isSelected={selectedTypes.includes(ProfileType.CLOSED)}
          $color="#FF9800"
          onClick={() => toggleProfileType(ProfileType.CLOSED)}
        >
          <CheckIcon /> Closed Profile
        </ProfileTypeButton>
      </ProfileTypeList>
      <CelebGrid>
        {filteredCelebList.length === 0 ? (
          <EmptyContainer>
            <img src="/svg/dashboard/agency/folder.svg" alt="folder" />
            <p>현재 등록된 셀레브리티가 없습니다. <br/>
              셀럽을 등록해주세요.</p>
          </EmptyContainer>
        ) : (
          filteredCelebList.map((celeb) => {
            return (
              <Celeb
                key={celeb.id}
                onClick={() => handleCelebClick(celeb.id)}
              >
                <ImageContainer width={32} height={32}>
                  {celeb.image ? (
                    <img
                      src={celeb.image}
                      alt={celeb.name + "image"}
                      onError={(e) => {
                        const target = e.currentTarget;
                        if (!target.src.includes('default-profile.png')) {
                          target.src = '/images/default-profile.png';
                        }
                        target.onerror = null;
                      }}
                    />
                  ) : (
                    <img
                      src="/svg/user.svg"
                      alt={celeb.name + "image"}
                      onError={(e) => {
                        e.currentTarget.onerror = null;
                      }}
                    />
                  )}
                  <div className="celeb-name">
                    {
                      celeb.type === ProfileType.CLOSED && (
                       <>
                         <img src={"/svg/closed/closed_person.svg"} alt={"profiling"} style={{ width: "24px" }} />
                       </>
                      )
                    }
                    {
                      celeb.type === ProfileType.LIMITED && (
                        <>
                          <img src={"/svg/limited/lock.svg"} alt={"profiling"} style={{ width: "24px" }} />
                        </>
                      )
                    }
                    <div className="name">{celeb.name}</div>
                    <div className="score-list">
                      <div className="score">
                        <Icon $type="trendScore" />
                        {formatNumber(celeb.searchVolume)}
                      </div>
                      <div className="score">
                        <Icon $type="instagramScore" />
                        {formatNumber(celeb.instagramFollowersCnt)}
                      </div>
                      <MoveDetailCelebButton>
                        <p>
                          Detailed Page
                        </p>
                        <img src="/svg/dashboard/arrow_right_alt.svg" alt=""/>
                      </MoveDetailCelebButton>
                    </div>
                  </div>
                </ImageContainer>
                <div className="celeb-info">
                  <div className="score">
                    <Icon $type="favorite" />
                    {formatNumber(celeb.avgLikes)}
                  </div>
                  <div className="score">
                    <Icon $type="article" />
                    {formatNumber(celeb.contracting)}
                  </div>
                  <div className="score">
                    <Icon $type="assignment" />
                    {formatNumber(celeb.completedContract)}
                  </div>
                </div>
              </Celeb>
            );
          })
        )}
      </CelebGrid>
    </Container>
  );
};

export default MyCelebList;
