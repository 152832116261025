import React from 'react';
import styled from 'styled-components';
import { CelebPermissionStatus } from './MyPermissionCelebList';

const StyledCelebRequestContent = styled.div`
	margin-top: 32px;
	margin-bottom: 32px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;
	font-size: 14px;
	
	.content {
		color: var(--Grey-800, #474747);
		text-align: center;
		font-family: Pretendard;
		margin: 0 auto;
		font-style: normal;
		font-weight: 400;
		line-height: 150%; /* 21px */
	}   
`;

interface CelebRequestContentProps {
	selectedTab: CelebPermissionStatus;
	companyName: string;
	celebName: string;
}

export const CelebRequestContent: React.FC<CelebRequestContentProps> = ({ 
	selectedTab, 
	companyName, 
	celebName 
}) => {
	return (
		<StyledCelebRequestContent>
			{selectedTab === CelebPermissionStatus.GRANTED && (
				<div className="content">
					제한된 프로필 권한을 허용했습니다.<br/>
					[{companyName}]님은 [{celebName}]님의 프로필 확인이 가능합니다.
				</div>
			)}
			{selectedTab === CelebPermissionStatus.REJECTED && (
				<div className="content">
					제한된 프로필의 권한 요청을 거절 했습니다.<br/>
					상태를 수정하고 싶으시다면 [support@aligo.ai]로 문의 주세요.
				</div>
			)}
			{selectedTab === CelebPermissionStatus.PENDING && (
				<div className="content">
					제한된 프로필에 대한 열람 요청이 왔습니다.<br/>
					수락버튼을 누르시면 요청한 {companyName} 님에게 프로필이 공개됩니다.
				</div>
			)}
		</StyledCelebRequestContent>
	);
};
