import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import { CelebListFilterQuery } from "../interfaces/filter.interface";
import { countryList } from "../../utils/utils";
import {useAtom} from "jotai/index";
import {celebCountryFilterAtom, tempSelectedCountryAtom} from "../../atoms/filter";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {colors} from "../../styles/color";

const CountrySelectButton = styled.div`
  display: flex;
  width: 256px;
  height: 40px;
  padding: 10px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 20px;
  border: 1px solid #AF6EFD;
  background: #FFF;
  cursor: pointer;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    justify-content: center;
    margin: 0 auto 24px;
    width: 100%;
    max-width: 256px;
  }
`;

const ButtonContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  color: #851DF0;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 16.8px */
`;

const CountryContainer = styled.div`
  border: 1px solid ${colors.neutral["300"]};
  display: flex;
  width: 256px;
  border-radius: 8px;
  flex-direction: column;
  align-items: flex-start;
  background-color: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(71, 71, 71, 0.10);
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  z-index: 10;
  
  @media (max-width: 768px) {
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 256px;
  }
`;

const CountryWrapper = styled.div`
  position: relative;
  width: 256px;
  margin: 0 auto;
  
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 256px;
  }
`;

const CountryOption = styled.label<{ isWorldwide?: boolean }>`
  display: flex;
  height: 48px;
  width: 100%;
  padding: 12px 20px;
  align-items: center;
  cursor: pointer;
  ${props => props.isWorldwide && `
    border-bottom: 1px solid #E5E5E5;
  `}
  
  &:hover {
    background-color: #F3E7FE;
  }
`;

const Text = styled.div<{ isBold?: boolean }>`
  color: ${colors.neutral["700"]};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: ${props => props.isBold ? '700' : '400'};
  line-height: 120%;
`;

const CheckboxInput = styled.input`
  appearance: none;
  width: 20px;
  height: 20px;
  border: 1.5px solid #E5E5E5;
  border-radius: 4px;
  margin-right: 12px;
  position: relative;
  cursor: pointer;
  
  &:checked {
    border-color: #8C30F5;
    background-color: #8C30F5;
    &::after {
      content: '';
      position: absolute;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
`;

interface CountryProps {
  query: CelebListFilterQuery;
  setQuery: Function;
}

const Country = ({ query, setQuery }: CountryProps) => {
  const [selectedCountry, setSelectedCountry] = useAtom<string[]>(celebCountryFilterAtom);

  const [, setTempSelectedCountry] = useAtom(tempSelectedCountryAtom);
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const FilterCountry = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    // 체크박스가 선택된 경우 배열에 추가, 선택 해제된 경우 배열에서 제거
    let updatedCountries: string[] = [...(query.countries || [])];

    if (e.target.checked) {
      // 'WW'(Worldwide)가 선택된 경우 다른 모든 국가 선택 해제
      if (value === 'WW') {
        updatedCountries = ['WW'];
      } else {
        // 다른 국가가 선택된 경우 'WW' 제거
        updatedCountries = updatedCountries.filter(c => c !== 'WW');
        // 새 국가 추가
        updatedCountries.push(value);
      }
    } else {
      // 선택 해제된 경우 배열에서 제거
      updatedCountries = updatedCountries.filter(c => c !== value);
    }

    setQuery("countries", updatedCountries);

    // 선택된 국가 이름 표시 로직 업데이트
    if (updatedCountries.length === 0 || updatedCountries.includes('WW')) {
      setSelectedCountry(["Worldwide"]);
    } else if (updatedCountries.length === 1) {
      const foundCountry = countryList.find((country) => country.value === updatedCountries[0]);
      setSelectedCountry(foundCountry ? [foundCountry.label] : ["Selected Countries"]);
    } else {
      setSelectedCountry(["Selected Countries"]);
    }

    setTempSelectedCountry(updatedCountries.join(','));
  }

  // 컴포넌트 마운트 시 초기 상태 설정
  useEffect(() => {
    if (!query.countries || query.countries.length === 0 || query.countries.includes('WW')) {
      setSelectedCountry(["Worldwide"]);
    } else if (query.countries.length === 1) {
      const foundCountry = countryList.find((country) => country.value === query.countries[0]);
      setSelectedCountry(foundCountry ? [foundCountry.label] : ["Selected Countries"]);
    } else {
      setSelectedCountry(["Selected Countries"]);
    }
  }, []);

  // 바깥 클릭 감지를 위한 useEffect 추가
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <CountryWrapper ref={containerRef}>
      <CountrySelectButton onClick={() => setIsOpen(!isOpen)}>
        <ButtonContent>
          <span>{Array.isArray(selectedCountry) && selectedCountry.length > 0 ? selectedCountry[0] : "Worldwide"}</span>
          <span>
            {isOpen
              ? <KeyboardArrowUpIcon sx={{ color: '#AE6EFD' }} />
              : <KeyboardArrowDownIcon sx={{ color: '#AE6EFD' }}/>
            }
          </span>
        </ButtonContent>
      </CountrySelectButton>

      {isOpen && (
        <CountryContainer>
          {countryList.map((country) => (
            <CountryOption
              key={`country-${country.id}`}
              isWorldwide={country.value === "WW"}
            >
              <CheckboxInput
                type="checkbox"
                id={`country-${country.id}`}
                name="country"
                value={country.value}
                checked={Array.isArray(query.countries) ? query.countries.includes(country.value) : false}
                onChange={FilterCountry}
              />
              <Text isBold={country.value === "WW"}>
                {country.label}
              </Text>
            </CountryOption>
          ))}
        </CountryContainer>
      )}
    </CountryWrapper>
  );
};

export default Country;
