import React, { useEffect, useState } from "react";
import Home from "../components/main/Home";
import Navbar from "../components/layout/Navbar";
import Footer from "../components/layout/Footer";
import { useNavigate, useLocation } from "react-router-dom";
import { EEnvType } from "../utils/utils";

const HomePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [shouldRender, setShouldRender] = useState(false);
  const envType = process.env.REACT_APP_NODE_ENV as EEnvType;

  useEffect(() => {
    // 개발 환경인 경우
    if (envType === EEnvType.PROD) {
      // 리다이렉트만 수행하고 렌더링하지 않음
      navigate(`/main${location.search}`);
      return;
    }

    // 프로덕션 환경인 경우에만 렌더링을 허용
    setShouldRender(true);
  }, [envType, location.search, navigate]);

  // 렌더링 여부를 결정하기 전까지는 아무것도 표시하지 않음
  if (!shouldRender) {
    return null;
  }

  return (
    <>
      <Navbar />
        <Home />
      <Footer />
    </>
  );
};

export default HomePage;
