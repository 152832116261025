import { atom } from "jotai";
import { CelebListFilterQuery } from "../components/interfaces/filter.interface";

const defaultQuery: CelebListFilterQuery = {
  page: 0,
  take: 0,
  minEstimatedPrice: 0,
  maxEstimatedPrice: 10000,
  countries: ["WW"],
  minInstagramFollowers: 0,
  maxInstagramFollowers: 1000000,
  minSearchVolume: 0,
  maxSearchVolume: 50000,
  advertisingType: "",
  minAdvertisingDuration: 0,
  maxAdvertisingDuration: 12,
  currencyType: "KRW",
  money: 0,
  isLike: null,
  gender: [],
  type: [],
  order__by: "follower",
  envType: process.env.REACT_APP_NODE_ENV as 'prod' | 'stage' | 'dev',
};

export const celebFilterAtom = atom<CelebListFilterQuery>(defaultQuery);
export const celebCountryFilterAtom = atom<string[]>(['']);
export const isApplyButtonAtom = atom<boolean>(true);
export const tempSelectedCountryAtom = atom<string>('WW'); // 임시 선택 국가 저장용
export const displayCountryAtom = atom<string[]>(['WW']);      // 실제 표시할 국가

