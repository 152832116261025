import React, { useEffect } from "react";
import { Outlet, useParams, useNavigate } from "react-router-dom";
import { FormContainer } from "../../common/FormContainer";
import Logo from "../../layout/Logo";
import styled from "styled-components";
import { useAtom } from "jotai";
import { signupAtom } from "../../../atoms/auth";
import { CompanyType } from '../../interfaces/auth.interfaces';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5rem;
  align-items: center;
  justify-content: center;
  margin-top: 6rem;
  margin-bottom: 60px;

`;

const SignupLayout = () => {
  const { type } = useParams<{ type: string }>();
  const [signupFormData, setSignupFormData] = useAtom(signupAtom);
  const navigate = useNavigate();

  useEffect(() => {
    // type 파라미터에 따라 companyType 결정
    const companyType = type === 'celeb' ? CompanyType.CELEBRITY_AGENCY : CompanyType.ADVERTISER;

    if (!signupFormData) {
      // 초기 상태 설정
      setSignupFormData({
        companyType: companyType,
        marketAgree: false,
        termsAgree: false
      });
    } else {
      // 기존 상태 유지하면서 companyType만 업데이트
      setSignupFormData({
        ...signupFormData,
        companyType: companyType,
      });
    }

    // agreement 페이지로 이동
    navigate("agreement");
  }, [type]); // type 파라미터가 변경될 때마다 실행

  return (
    <Container>
      <Logo url="/" />
      <FormContainer>
        <Outlet />
      </FormContainer>
    </Container>
  );
};

export default SignupLayout;
