import React from "react";
import styled from "styled-components";
import { CelebData } from "../../interfaces/celeb.interfaces";
import { formatNumberWithCommas } from "../../../utils/utils";
import { ECelebType } from "../celebCard/CelebCard";
import { colors } from "../../../styles/color";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import LanguageIcon from "@mui/icons-material/Language";
import FavoriteButton from "../../button/FavoriteButton";
import ClosedCelebSideInformation from "./ClosedCelebSideInformation";
import StyledButton from "../../button/StyledButton";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media (max-width: 1350px) {
    width: 100%;
  }
  
  @media (max-width: 850px) {
    margin-top: 40px;
    padding: 0 16px;
  }
`;

const Subtitle = styled.span`
  display: inline-block;
  ${({ theme }) => theme.font.title3};
  ${({ theme }) => theme.font.bold};
`;

const DetailText = styled.span`
  ${({ theme }) => theme.font.body1};
  ${({ theme }) => theme.font.regular};
  line-height: 130%;
  
  .title {
    color: ${colors.neutral["900"]};
    /* Title3 / Bold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 19.2px */
  }
`;

const PhotoDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  
  .items {
    display: flex;
    align-items: flex-start;
    gap: 16px;
  }
`;

const PhotoContributor = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const ActiveFromManagementLink = styled.div`
  border-top: 1px solid ${colors.neutral["800"]};
  border-bottom: 1px solid ${colors.neutral["800"]};
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;

  @media (max-width: 1250px) {
    flex-direction: column;
    height: auto;
    gap: 0.8rem;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${colors.neutral["900"]};
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  gap: 24px;
  width: 100%;

  & > div {
    display: flex;
    gap: 4px;
  }
  
  .bold {
    margin-right: 16px;
    ${({ theme }) => theme.font.bold}
  }
  
  .active-from-management {
    gap: 16px;
    @media (max-width: 1250px) {
      display: flex;
      flex-direction: column;
    }
  }

  @media (max-width: 1250px) {
    gap: 16px;
    align-items: flex-start;
    align-self: flex-start;
    flex-direction: column;
  }
`;

const SocialIconList = styled.div`
  display: flex;
  font-size: 24px;
  gap: 16px;
  
  .material-icons {
    cursor: pointer;
  }
  
  a {
    color: black;
    gap: 16px;
    @media (max-width: 1250px) {
      margin-right: 16px;
    }
  }
`;

interface DescriptionProps {
  marginTop?: number;
}

const Description = styled.div<DescriptionProps>`
  color: ${colors.neutral["600"]};
  font-family: Inter;
  margin-top: ${(props) => props.marginTop}px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  cursor: pointer;

  &:hover {
    -webkit-line-clamp: unset;
    overflow: visible;
    z-index: 1;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 4px;
    border-radius: 4px;
  }
`;

const CelebSideInfoHeader = styled.div`
  margin-bottom: 24px;
  width: 600px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  
  @media (max-width: 1350px) {
    width: 100%;
  }
`;

const NameAndFavorite = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

interface CelebNameFontFamily {
  fontFamily?: string;
  color?: string;
  fontSize?: string;
}

const CelebName = styled.div<CelebNameFontFamily>`
  color: ${colors.neutral["900"]};
  color: ${(props) => props.color};
  font-family: ${(props) => props.fontFamily};
  font-size: ${(props) => props.fontSize};
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  align-self: stretch;
  
  @media (max-width: 850px) {
    font-size: 40px;
  }
`;

const CelebTypes = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  line-height: 120%;
  
  span {
    font-style: normal;
    font-weight: 400;
    font-family: Inter;
    font-size: 24px;
    color: ${colors.neutral["600"]};
  }
`;

const OtherInformation = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  gap: 16px;
  
  @media (max-width: 1350px) {
    width: 100%;
  }
`;

const CelebSideKeywordPhotoInfo = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
  width: 600px;
  display: flex;
  padding-top: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  
  @media (max-width: 1350px) {
    width: 100%;
  }
`;

const KeywordContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  
  .title {
    color: ${colors.neutral["900"]};

    /* Title3 / Bold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 19.2px */
  }
  
  .itmes {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 8px 4px;
    align-self: stretch;
    flex-wrap: wrap;
    
    span {
      display: flex;
      height: 24px;
      padding: 2px 10px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 12px;
      border: 1px solid ${colors.neutral["900"]};
      background-color: ${colors.neutral["0"]};
    }
  }
`;

const ButtonContainer = styled.div`
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  height: auto;
  
  @media (max-width: 1350px) {
    width: 100%;
  }
  
  @media (max-width: 850px) {
    height: auto;
    aspect-ratio: 10/1;
  }
`;

const ContractNotice = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px auto 0;

  .email {
    color: ${colors.main["bora"]};
    margin: 8px auto 0;
  }
`;

export enum EPermissionStatus {
  NOT_REQUESTED = 'NOT_REQUESTED', // 요청 전
  PENDING = 'PENDING', // 처리 대기 중
  GRANTED = 'GRANTED', // 처리 완료
}

interface CelebSideInformationProps {
  celebData: CelebData;
  selectedIndex: number;
}

const CelebSideInformation = ({
                                celebData,
                                selectedIndex,
                              }: CelebSideInformationProps) => {
  const celebPhotoList = celebData.celebStockData;
  const celebId = celebData.celebInfo.id;
  const celebInfo = celebData.celebInfo;

  return (
    <>
      {celebInfo.type === ECelebType.CLOSED ? (
        <ClosedCelebSideInformation celebData={celebData} />
      ) : (
        <Wrapper>
          <CelebSideInfoHeader>
            <NameAndFavorite>
              <CelebName
                fontFamily="Larken"
                color={colors.neutral["black"]}
                fontSize="56px"
              >
                {celebInfo.name}
              </CelebName>
              <FavoriteButton id={celebId} size={48} status={celebInfo.isLike} />
            </NameAndFavorite>
            <CelebTypes>
              {(celebInfo.types ?? []).map((type, index) => (
                <span key={index}>{type}</span>
              ))}
            </CelebTypes>
          </CelebSideInfoHeader>
          <OtherInformation>
            <ActiveFromManagementLink>
              <InfoContainer>
                <div className="active-from-management">
                  <div>
                    <span>Active From :</span>
                    <span className="bold">{celebInfo.activeFrom}</span>
                  </div>
                  <div>
                    <span>Management :</span>
                    <span className="bold">{celebInfo.management}</span>
                  </div>
                </div>
                <SocialIconList>
                  {celebInfo.webUrl && (
                    <a href={celebInfo.webUrl} target="_blank" rel="noreferrer">
                      <LanguageIcon fontSize="inherit" />
                    </a>
                  )}
                  {celebInfo.instaUrl && (
                    <a href={celebInfo.instaUrl} target="_blank" rel="noreferrer">
                      <InstagramIcon fontSize="inherit" />
                    </a>
                  )}
                  {celebInfo.youtubeUrl && (
                    <a href={celebInfo.youtubeUrl} target="_blank" rel="noreferrer">
                      <YouTubeIcon fontSize="inherit" />
                    </a>
                  )}
                </SocialIconList>
              </InfoContainer>
            </ActiveFromManagementLink>
            <Description>
              {celebInfo.description}
            </Description>
          </OtherInformation>
          <CelebSideKeywordPhotoInfo>
            {
              celebInfo.keyword?.length > 0 && (
                <KeywordContainer>
                  <span className="title">Keywords</span>
                  <div className="itmes">
                    {celebInfo.keyword?.map((data, index) => {
                      return <span>{data}</span>;
                    })}
                  </div>
                </KeywordContainer>
              )
            }
            <DetailText>
              <span className="title"> Photo ID : </span>
              <span>{celebPhotoList[selectedIndex].photoId}</span>
            </DetailText>
            <PhotoDetails>
              <Subtitle>Photo Details : </Subtitle>
              <div className="items">
                <DetailText>
                  {celebPhotoList[selectedIndex].extension
                    ? celebPhotoList[selectedIndex].extension?.slice(1).toUpperCase()
                    : ""}
                </DetailText>
                <DetailText>
                  {
                    celebPhotoList[selectedIndex].width && celebPhotoList[selectedIndex].height
                      ? formatNumberWithCommas(celebPhotoList[selectedIndex].width) +
                      " x " +
                      formatNumberWithCommas(celebPhotoList[selectedIndex].height) +
                      " px"
                      : ""
                  }
                </DetailText>
              </div>
            </PhotoDetails>
            <PhotoContributor>
              <Subtitle>Photo Contributor : </Subtitle>
              <DetailText>{celebPhotoList[selectedIndex].photoContributor}</DetailText>
            </PhotoContributor>
          </CelebSideKeywordPhotoInfo>
          <ButtonContainer>
            <StyledButton
              label="Request Contract"
              size="llg"
              color="gray"
              disabled={true}
            />
          </ButtonContainer>
          <ContractNotice style={{ display: "flex", justifyContent: "center"}}>
            Contract services coming soon. Contact us for inquiries.
            <div className="email">support@aligo.ai</div>
          </ContractNotice>
        </Wrapper>
      )}
    </>
  );
};

export default CelebSideInformation;
