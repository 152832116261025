import React from "react";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import { getProjectList } from "../../api/project";

import StyledButton from "../button/StyledButton";
import {
  Link,
  Outlet,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  ProjectData,
  ProjectStatusList,
} from "../interfaces/project.interface";
import {colors} from "../../styles/color";

const ProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 128rem;
  margin: 0 auto;
  padding: 0 2rem;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2.4rem;
  margin-top: 6.8rem;
  flex-wrap: wrap;
  justify-content: flex-start;

  @media (max-width: 768px) {
    gap: 1.2rem;
    margin-top: 3.4rem;
  }
`;

const Status = styled(Link)`
  color: var(--gray-900);
  ${({ theme }) => theme.font.title1};
  white-space: nowrap;
  
  &.active {
    ${({ theme }) => theme.font.bold};
  }

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

const ButtonContainer = styled.div`
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  height: auto;
  
  @media (max-width: 1350px) {
    width: 100%;
  }
  
  @media (max-width: 850px) {
    height: auto;
    aspect-ratio: 10/1;
  }
`;

const Test = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ContractNotice = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1.6rem;

  .email {
    color: ${colors.main["bora"]};
    margin: 8px auto 0;
  }
`;

export interface ProjectContext {
  ongoingProjects: ProjectData[];
  pastProjects: ProjectData[];
}

const ProjectContent = () => {
  const { data, isPending } = useQuery<ProjectStatusList>({
    queryKey: ["DashboardProjectList"],
    queryFn: () => getProjectList(),
  });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isOngoing = searchParams.get("type") === "ongoing";
  const isPast = searchParams.get("type") === "past";
  const showStatus = isOngoing || isPast;

  if (isPending) return <div></div>;
  if (!data) return <></>;

  return (
    <ProjectContainer>
      {showStatus && (
        <StatusContainer>
          <Status to="?type=ongoing" className={isOngoing ? "active" : ""}>
            Ongoing project ({data.data.ongoingProjects.length})
          </Status>
          |
          <Status to="?type=past" className={isPast ? "active" : ""}>
            Past project ({data.data.pastProjects.length})
          </Status>
        </StatusContainer>
      )}
      <Outlet
        context={{
          ongoingProjects: data.data.ongoingProjects,
          pastProjects: data.data.pastProjects,
        }}
      />
      {showStatus && (
        <Test>
          <StyledButton
            label="Create new project"
            margin="3rem auto"
            onClick={() => navigate("create")}
            disabled={true}
          />
          <ContractNotice>
            Project management for contract services coming soon. Contact us for inquires.
            <div className="email">support@aligo.ai</div>
          </ContractNotice>
        </Test>
      )}
    </ProjectContainer>
  );
};

export default ProjectContent;
