import React, { useState } from "react";
import Section from "../layout/Section";
import ImageView from "./ImageView";
import styled from "styled-components";
import CelebSideInformation from "./celebDetail/CelebSideInformation";
import { CelebData } from "../interfaces/celeb.interfaces";

const Wrapper = styled.div`
  max-width: 1440px;
  height: 760px;
  margin: auto;
  justify-content: space-between;
  display: flex;
  width: 100%;

  @media (max-width: 1350px) {
    flex-direction: column;
    max-width: 100%;
    height: auto;
  }

  @media (max-width: 768px) {
    gap: 2rem;
  }
`;

const ImageViewWrapper = styled.div`
  max-width: 768px;
  
  @media (max-width: 1350px) {
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
`;

const SideInfoWrapper = styled.div`
  @media (max-width: 1350px) {
    width: 100%;
  }
`;

interface CelebInformationSectionProps {
  celebData: CelebData;
}

const CelebInformationSection = ({
  celebData,
}: CelebInformationSectionProps) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  return (
    <Section mt={4}>
      <Wrapper>
        <ImageViewWrapper>
          <ImageView
            celebData={celebData}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
          />
        </ImageViewWrapper>
        <SideInfoWrapper>
          <CelebSideInformation
            celebData={celebData}
            selectedIndex={selectedIndex}
          />
        </SideInfoWrapper>
      </Wrapper>
    </Section>
  );
};

export default CelebInformationSection;
