import React, { Fragment } from "react";
import { CelebData } from "../interfaces/celeb.interfaces";
import styled from "styled-components";
import FavoriteIcon from "@mui/icons-material/Favorite";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import {
  CardData,
  CardSubtitle,
  CardTitle,
  CountryColorSet,
  GridCard,
  ReportTitle,
} from "./ReportSection";
import {countryList, formatNumber, roundFollowers} from "../../utils/utils";
import AudienceGeoChart from "./AudienceGeoChart";
import AudienceGenderChart from "./AudienceGender";
import AudienceAgeChart from "./AudienceAgeChart";
import FollowersGrowthChart from "./FollowersGrowthChart";
import ImageContainer from "../common/ImageContainer";
import HelpMessage from "../icon/HelpMessage";
import {ECelebType} from "./celebCard/CelebCard";
import {colors} from "../../styles/color";

const InstagramReportsContainer = styled.div`
  margin: 0 auto;
  padding: 60px;
  width: 100%;

  @media (max-width: 1440px) {
    padding: 60px 30px;
  }

  @media (max-width: 768px) {
    padding: 20px 15px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  white-space: nowrap;
  
  @media (max-width: 650px) {
    margin: 0 auto;
	}
`;

const InstagramGridContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(3, 24.4rem);
  grid-template-columns: repeat(5, 24.4rem);
  gap: 2rem;
  padding: 1rem;
  min-width: 1325px;
  margin: auto;
  white-space: nowrap;

  .instagram-thumbnail {
    grid-column: 1 / 3;
  }
  .audience-geo {
    grid-column: 2 / 4;
    .chart-list {
      display: flex;
    }
  }
  .instagram-summary {
    width: 100%;
    height: 100%;
    border: none;
    display: flex;
    gap: 0.4rem;
    flex-direction: column;
    justify-content: space-between;
    background-color: transparent;
  }
  .follower-growth {
    grid-column: 2 / 6;
  }
`;

const InstagramThumbnail = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 4.2rem 2.8rem;
  border: 0.1rem solid ${colors.neutral["0"]};
  border-radius: 8px;
  gap: 2rem;

  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15), 
              0px 2px 5px rgba(0, 0, 0, 0.1);

  transition: box-shadow 0.2s ease-in-out;
  &:hover {
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2),
                0px 3px 8px rgba(0, 0, 0, 0.15);
  }
`;

const ClosedBlur = styled.div`
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  filter: blur(8px);
`;

const InstagramThumbnailData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  gap: 1.6rem;

  .instagram-id {
    ${({ theme }) => theme.font.title1};
    ${({ theme }) => theme.font.bold};
  }

  .closed {
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none
  }
  
  .celeb-name {
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.regular};
    color: ${colors.neutral["600"]};
  }
  .border {
    border-top: 0.1rem solid black;
  }
  .instagram-data-list {
    display: flex;
    align-items: center;
    width: 100%;
    .instagram-data {
      text-align: center;
      width: 33.3%;
      .title {
        ${({ theme }) => theme.font.body2};
        ${({ theme }) => theme.font.regular};
      }
      .data {
        margin-top: 1.2rem;
        ${({ theme }) => theme.font.body1};
        ${({ theme }) => theme.font.bold};
      }
    }
    
    .closed {
      -webkit-user-select:none;
      -moz-user-select:none;
      -ms-user-select:none;
      user-select:none
		}
  }
`;

const InstagramSummaryCard = styled.div`
  width: 100%;
  height: 100%;
  padding: 1.2rem;
  position: relative;
  border: 0.1rem solid ${colors.neutral["0"]};
  border-radius: 8px;
  
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15),
  0px 2px 5px rgba(0, 0, 0, 0.1);

  transition: box-shadow 0.2s ease-in-out;
  &:hover {
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2),
    0px 3px 8px rgba(0, 0, 0, 0.15);
  }
`;


interface SimpleBarProps {
  $width: number;
  $bgColor: string;
}

const emvScoreColors: { [key: string]: string } = {
  Excellent: "#860CF9",
  Very_Good: "#1D5AD2",
  Good: "#2D9EFF",
  Average: "#F59E0B",
  Fair: "#EF3A74",
  Poor: "#B10052",
};

const SimpleBarChart = styled.div`
  display: flex;
  margin: 0 auto;
  width: 46.4rem;
  height: 2rem;
  border-radius: 0.4rem;
  background-color: ${colors.neutral["300"]};
  overflow: hidden;
`;

const SimpleBar = styled.div<SimpleBarProps>`
  width: ${(props) => props.$width}%;
  background-color: ${(props) => props.$bgColor};
  position: relative;
`;

const DonughtChartList = styled.div`
  display: flex;
  width: 464px;
  justify-content: space-between;
  align-items: center;
`;

const BarInfo = styled.div`
  display: none;
  background-color: ${colors.neutral["50"]};
  border: 1px solid ${colors.neutral["500"]};
  justify-content: center;
  &.bar-info {
  }
  &:hover {
    display: flex;
  }
`;

interface InstagramReportProps {
  celebData: CelebData;
  countries: string[];
}
const InstagramReport = ({
                           celebData,
                           countries,
}: InstagramReportProps) => {
  const instagramData = celebData.instagram;
  const celebType = celebData.celebInfo.type;

  const selectedCountry = countries.map(code =>
    countryList.find(country => country.value === code)?.label || code
  );

  return (
    <InstagramReportsContainer>
      <Container>
        <ReportTitle>Instagram</ReportTitle>
        <InstagramGridContainer>
          <InstagramThumbnail className="instagram-thumbnail">
            <ImageContainer width={16} height={16} borderRadius={0.4} celebType={celebType}>
              <img src={instagramData.celebMainImg} alt="hel" />
            </ImageContainer>
            <InstagramThumbnailData>
              {
                celebType === ECelebType.CLOSED ? (
                  <ClosedBlur className="instagram-id" >{instagramData.instagramName}</ClosedBlur>
                ): (
                  <div className="instagram-id">{instagramData.instagramName}</div>
                )
              }
              {
                celebType === ECelebType.CLOSED ? (
                  <div className="celeb-name">{celebData.celebInfo.fakeEnName}</div>
                ): (
                  <div className="celeb-name">{celebData.celebInfo.name}</div>
                )
              }
              <div className="border"></div>
              <div className="instagram-data-list">
                <div className="instagram-data">
                  <div className="title">Posts</div>
                  {
                    celebType === ECelebType.CLOSED ? (
                      <ClosedBlur className="data">{formatNumber(instagramData.postsCount)}</ClosedBlur>
                    )  : (
                      <div className="data">{formatNumber(instagramData.postsCount)}</div>
                    )
                  }
                </div>
                <div className="instagram-data">
                  <div className="title">Followers</div>
                  <div className="data">
                    {
                      celebType === ECelebType.CLOSED ? (
                        roundFollowers(formatNumber((instagramData.followers)))
                      ) : (
                        formatNumber(instagramData.followers)
                      )
                    }
                  </div>
                </div>
                <div className="instagram-data">
                  <div className="title">Followings</div>
                  {
                    celebType === ECelebType.CLOSED ? (
                      <ClosedBlur className="data">{formatNumber(instagramData.followings)}</ClosedBlur>
                    ) : (
                      <div className="data">{formatNumber(instagramData.followings)}</div>
                    )
                  }
                </div>
              </div>
            </InstagramThumbnailData>
          </InstagramThumbnail>
          <GridCard>
            <div>
              <CardTitle>Followers</CardTitle>
            </div>
            <CardData>
              {celebType === ECelebType.CLOSED ? roundFollowers(formatNumber(instagramData.followers)) : formatNumber(instagramData.followers)}
            </CardData>
          </GridCard>
          <GridCard>
            <div>
              <CardTitle>
                Real Followers
                <HelpMessage>
                  Real followers consists of real people and influencers,
                  excluding mass followers and suspicious accounts
                </HelpMessage>
              </CardTitle>
              <CardSubtitle>Estimated Data</CardSubtitle>
            </div>
            <CardData>
              {instagramData?.realFollowers ?? 0}%
              <span className="sub-text">
                {(() => {
                  if (!instagramData?.followers || !instagramData?.realFollowers) {
                    return '0';
                  }
                  const rawFollowers = formatNumber(instagramData.followers);
                  const numericFollowers = parseFloat(rawFollowers.replace(/[KMB,]/g, '')) *
                    (rawFollowers.includes('M') ? 1000000 :
                     rawFollowers.includes('K') ? 1000 : 1);

                  const realFollowersPercentage = Number(instagramData.realFollowers) / 100;
                  const result = Math.round(numericFollowers * realFollowersPercentage);

                  return formatNumber(result);
                })()}
              </span>
            </CardData>
          </GridCard>
          <GridCard>
            <div>
              <CardTitle>
                EGR
                <HelpMessage>
                  Engagement rate, which shows the percent of the audience who
                  engages with the content posted by this influencer, including
                  likes and comments.
                </HelpMessage>
              </CardTitle>
            </div>
            <CardData>
              {instagramData.egr.toFixed(1)}%
            </CardData>
          </GridCard>
          <GridCard>
            <div>
              <CardTitle>
                Authentic Engagement
                <HelpMessage>
                Authentic Engagement shows the average number of organic likes and comments per post, i.e.
                likes and comments that come from real followers.
                </HelpMessage>
              </CardTitle>
              <CardSubtitle>Estimated Data</CardSubtitle>
            </div>
            <CardData>
              {formatNumber(instagramData.authenticEngagement)}
              <span className="sub-text"> per post</span>
            </CardData>
          </GridCard>
          <GridCard className="audience-geo">
            <div>
              <CardTitle>
                Audience Geo
                <HelpMessage>
                  Audience Geography, which shows where the majority of the
                  audience lives
                </HelpMessage>
              </CardTitle>
            </div>
            <SimpleBarChart>
              {instagramData.audienceGeo.map((geo, index) => {
                return (
                  <Fragment key={"barchart" + geo.code + index}>
                    <SimpleBar
                      $width={geo.value}
                      $bgColor={CountryColorSet[index]}
                    ></SimpleBar>
                    <BarInfo />
                  </Fragment>
                );
              })}
            </SimpleBarChart>

            <DonughtChartList data-items={instagramData.audienceGeo.length}>
              {instagramData.audienceGeo.map((geo, index) => {
                return (
                  <AudienceGeoChart
                    key={"doughnutchart" + geo.code + index}
                    name={geo.name}
                    value={geo.value}
                    color={CountryColorSet[index]}
                    selectedCountry={selectedCountry}
                    items={instagramData.audienceGeo.length}  // items prop 전달
                  />
                );
              })}
            </DonughtChartList>
          </GridCard>
          <GridCard>
            <div>
              <CardTitle>Audience Gender</CardTitle>
            </div>
            {instagramData?.audienceGender ? (
              <AudienceGenderChart data={instagramData?.audienceGender} />
            ) : (
              <div>No data available</div> // 데이터가 없을 경우 표시할 내용
            )}
          </GridCard>
          <GridCard>
            <div>
              <CardTitle>Audience Age</CardTitle>
              <AudienceAgeChart data={instagramData.audienceAge} />
            </div>
          </GridCard>
          <div className="instagram-summary">
            <InstagramSummaryCard>
              <CardTitle>
                <FavoriteIcon style={{ color: "#EC0E5D" }} />
                Avg. Likes
              </CardTitle>
              <CardData>{formatNumber(instagramData.avgLikes)}</CardData>
            </InstagramSummaryCard>
            <InstagramSummaryCard>
              <CardTitle>
                <ChatBubbleIcon style={{ color: "#F59E0B" }} />
                Avg. Comments
              </CardTitle>
              <CardData>{formatNumber(instagramData.avgComments)}</CardData>
            </InstagramSummaryCard>
            <InstagramSummaryCard>
              <CardTitle>
                <VisibilityIcon style={{ color: "#008DFF" }} />
                Avg. Impressions
              </CardTitle>
              <CardData>{formatNumber(instagramData.impressions)}</CardData>
            </InstagramSummaryCard>
          </div>
          <GridCard className="follower-growth">
            <div>
              <CardTitle>Followers Growth</CardTitle>
              <FollowersGrowthChart data={instagramData.followersChart} />
            </div>
          </GridCard>
        </InstagramGridContainer>
      </Container>
    </InstagramReportsContainer>
  );
};

export default InstagramReport;
