export interface InputValidationErrorResponse {
  ok: boolean;
  statusCode: number;
  data: ValidationErrorField;
}

export interface BaseResponse<T> {
  ok: boolean;
  statusCode: number;
  messages: string;
  data: T;
}

export interface ValidationErrorField {
  field: string;
  messages: string[];
}

export interface DataErrorResponse {
  ok: boolean;
  statusCode: number;
  message: string;
}

export interface LoginSuccessResponse {
  ok: boolean;
  statusCode: number;
  message: string;
  data: {
    user: UserInformation;
    accessToken: string;
    refreshToken: string;
  };
}

export enum CompanyType {
  CELEBRITY_AGENCY = 'celebrity_agency',
  ADVERTISER = 'advertiser'
}

export type userType = CompanyType | "";

export interface UserInformation {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: null;
  email: string;
  companyName: string;
  isVerification: boolean;
  companyType: userType;
  position: string;
  phone: string;
  permissionId: null;
}

export interface LoginFormData {
  email: string;
  password: string;
}

export interface SignupSuccessResponse {
  ok: boolean;
  statusCode: number;
  message: string;
  data: {
    user: {
      isVerification: boolean;
      id: string;
      createdAt: string;
      updatedAt: string;
      deletedAt: null;
      email: string;
      companyName: string;
      companyType: userType;
      position: string;
      phone: string;
      permissionId: null;
    };
    accessToken: string;
    refreshToken: string;
  };
}

export interface UserProfile {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: null;
  email: string;
  companyName: string;
  companyType: userType;
  isVerification: boolean;
  position: string;
  phone: string;
  permissionId: null;
  marketAgree: string;
}

export interface UserProfileResponse extends BaseResponse<UserProfile> {}

export interface SignupFormData {
  companyName: string;
  companyType: userType;
  position: string;
  marketAgree: boolean;
  email: string;
  password: string;
  passwordConfirm: string;
  phone: string;
}

export interface SignupErrorResponse {
  ok: boolean;
  statusCode: number;
  message: Array<{
    field: string;
    messages: string[];
  }>;
}

export interface VerifyStatusSuccessResponse {
  ok: boolean;
  statusCode: number;
  message: string;
  data: {
    id: number;
    email: string;
    token: string;
    verification: boolean;
    createdAt: string;
    deletedAt: null;
  };
}

export interface VerifyStatusErrorResponse {
  ok: boolean;
  statusCode: number;
  message: string;
}

export interface VerifySendSuccessResponse {
  ok: boolean;
  statusCode: number;
  message: string;
  data: {
    data: {
      $metadata: {
        httpStatusCode: number;
        requestId: string;
        attempts: number;
        totalRetryDelay: number;
      };
      MessageId: string;
    };
    token: string;
  };
}


