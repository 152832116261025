import React from "react";
import styled from "styled-components";
import { colors } from "../../styles/color";
import Section from "../layout/Section";
import Banner from "./Banner";
import ImageContainer from "../common/ImageContainer";
import Discover from "./Discover";
import NewsCardList from "./NewsCardList";

const Container = styled.div`
  min-width: 100%;
  min-height: 100vh;
  background-color: ${colors.main.main};
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10rem;
`;


const PaddingContainer = styled.div`
  padding: 0 8rem;
  > .border {
    border-top: 0.1rem solid ${colors.neutral["900"]};
    padding-top: 10rem;
  }
`;

const SectionTitle = styled.h2`
  font-size: 10rem;
  line-height: 120%;
  ${({ theme }) => theme.font.larken};
  ${({ theme }) => theme.font.w500};
`;

const SectionMediumTitle = styled.h3`
  color: ${colors.neutral["800"]};
  text-align: center;
  ${({ theme }) => theme.font.larken};
  ${({ theme }) => theme.font.h1};
  ${({ theme }) => theme.font.w500};
  margin: 12rem auto 6rem;
`;


const Home = () => {
  return (
    <Container>
      <Section bgColor="var(--gray-900)">
        <Banner />
      </Section>
      <Section pt={4}>
        <SectionMediumTitle>Celebs in celevu</SectionMediumTitle>
        <NewsCardList />
      </Section>
      <Section pt={6}>
        <FlexContainer>
          <PaddingContainer>
            <div className="border" />
            <SectionTitle>
              Perfect Celebrity Matches for Your Brand, Made Simple!
            </SectionTitle>
          </PaddingContainer>
          <ImageContainer width={100} unit="%">
            <img src="/image/main/main_01.png" alt="main_image_01" />
          </ImageContainer>
          <PaddingContainer>
            <ImageContainer width={100} unit="%">
              <img src="/image/main/main_02.png" alt="main_image_02" />
            </ImageContainer>
          </PaddingContainer>
          <PaddingContainer>
            <ImageContainer width={100} unit="%">
              <img src="/image/main/main_03.png" alt="main_image_03" />
            </ImageContainer>
          </PaddingContainer>
        </FlexContainer>
      </Section>
      <Section pt={10}>
        <FlexContainer>
          <PaddingContainer>
            <div className="border" />
            <SectionTitle>
              Seamless Contracts: Connect Advertisers and Celebrities Fast!
            </SectionTitle>
          </PaddingContainer>

          <ImageContainer width={100} unit="%">
            <img src="/image/main/main_04.png" alt="main_image_04" />
          </ImageContainer>
          <PaddingContainer>
            <ImageContainer width={100} unit="%">
              <img src="/image/main/main_05.png" alt="main_image_05" />
            </ImageContainer>
          </PaddingContainer>
        </FlexContainer>
      </Section>
      <Section pt={10} pb={20}>
        <ImageContainer width={100} unit="%">
          <PaddingContainer>
            <img src="/image/main/main_06.png" alt="main_image_06" />
          </PaddingContainer>
        </ImageContainer>
        <SectionMediumTitle>Our partners</SectionMediumTitle>
        <ImageContainer width={100} unit="%">
          <PaddingContainer>
            <ImageContainer width={100} unit="%">
              <img src="/image/main/main_07.png" alt="main_image_07" />
            </ImageContainer>
          </PaddingContainer>
        </ImageContainer>
      </Section>
      <Section padding="12rem 11.6rem" bgColor="var(--gray-050)">
        <Discover />
      </Section>
    </Container>
  );
};

export default Home;
