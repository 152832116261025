import styled from "styled-components";
import {CelebPermissionStatus} from "./MyPermissionCelebList";
import {ICelebData} from "./MyPermissionCelebItem";
import {privateClient} from "../../../api/axiosInstance";
import {ECelebType} from "../../celeb/celebCard/CelebCard";
import {colors} from "../../../styles/color";
import {useState} from "react";

const BUTTON_DIMENSIONS = {
    desktop: {
        width: '420px',
        height: '60px'
    },
    mobile: {
        width: '320px',
        height: '60px'
    }
};

const BUTTON_TEXT = {
    rejected: '거절완료',
    granted: '수락완료',
    reject: '거절',
    accept: '수락'
} as const;

const ButtonContainer = styled.div`
    display: flex;
    width: ${BUTTON_DIMENSIONS.desktop.width};
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 32px;
    
    @media (max-width: 768px) {
        margin-bottom: 16px;
        width: ${BUTTON_DIMENSIONS.mobile.width};
        justify-content: space-between;
    }
`;

const BaseButton = styled.div`
    width: ${BUTTON_DIMENSIONS.desktop.width};
    height: ${BUTTON_DIMENSIONS.desktop.height};
    flex-shrink: 0;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    
    @media (max-width: 768px) {
        width: ${BUTTON_DIMENSIONS.mobile.width};
    }
`;

const ActionButton = styled.button<{ isPrimary?: boolean }>`
    width: 200px;
    height: ${BUTTON_DIMENSIONS.desktop.height};
    flex-shrink: 0;
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    
    @media (max-width: 768px) {
        width: 148px;
    }
    
    ${({ isPrimary }) => isPrimary ? `
        background: var(--Primary-Violet-300-Primay, #AE6EFD);
        color: white;
        
        &:hover {
            background: var(--Primary-Violet-400, #9B4FFD);
            transform: translateY(-2px);
            box-shadow: 0 2px 8px rgba(174, 110, 253, 0.25);
        }
    ` : `
        background: var(--Grey-300, #E5E5E5);
        color: #374151;
        
        &:hover {
            background: var(--Grey-400, #D1D1D1);
            transform: translateY(-2px);
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        }
    `}
`;

const StatusButton = styled(BaseButton)`
    background-color: ${colors.neutral["300"]};
    color: ${colors.neutral["500"]};
`;

interface PermissionButtonsProps {
    selectedTab: CelebPermissionStatus;
    celebData: ICelebData;
    onDataUpdate: () => Promise<void>;
}

const PermissionButtons = ({
                               selectedTab,
                               celebData,
                               onDataUpdate,
                           }: PermissionButtonsProps) => {
    const [isRejecting, setIsRejecting] = useState(false);
    const [isApproving, setIsApproving] = useState(false);

    const RejectButton = async () => {
        try {
            setIsRejecting(true);
            if (celebData.type === ECelebType.CLOSED) {
                await privateClient.put(`/api/v1/celeb/${celebData.id}/access_closed_permission`, {
                    permissionStatus: "REJECTED",
                    userId: celebData.userId,
                });
            } else if (celebData.type === ECelebType.LIMITED) {
                await privateClient.put(`/api/v1/celeb/${celebData.id}/access_limited_permission`, {
                    permissionStatus: "REJECTED",
                    userId: celebData.userId,
                });
                await privateClient.post(`/api/v1/email/send-limited-permission-rejected-advertiser`, {
                    celebId: celebData.id,
                })
            }
            await onDataUpdate();
        } finally {
            setIsRejecting(false);
        }
    }

    const ApproveButton = async () => {
        try {
            setIsApproving(true);
            if (celebData.type === ECelebType.CLOSED) {
                await privateClient.put(`/api/v1/celeb/${celebData.id}/access_closed_permission`, {
                    permissionStatus: "GRANTED",
                    userId: celebData.userId,
                });
                await privateClient.post(`/api/v1/email/send-closed-permission-granted-advertiser`, {
                    celebId: celebData.id,
                })
            } else if (celebData.type === ECelebType.LIMITED) {
                await privateClient.put(`/api/v1/celeb/${celebData.id}/access_limited_permission`, {
                    permissionStatus: "APPROVED",
                    userId: celebData.userId,
                });
                await privateClient.post(`/api/v1/email/send-limited-permission-approved-advertiser`, {
                    celebId: celebData.id,
                })
            }
            await onDataUpdate();
        } finally {
            setIsApproving(false);
        }
    }

    const renderButtons = () => {
        switch (selectedTab) {
            case CelebPermissionStatus.REJECTED:
                return <StatusButton>{BUTTON_TEXT.rejected}</StatusButton>;
            case CelebPermissionStatus.GRANTED:
                return <StatusButton>{BUTTON_TEXT.granted}</StatusButton>;
            case CelebPermissionStatus.PENDING:
                return (
                    <>
                        <ActionButton
                            onClick={RejectButton}
                            disabled={isRejecting || isApproving}
                        >
                            {isRejecting ? '처리중...' : BUTTON_TEXT.reject}
                        </ActionButton>
                        <ActionButton
                            isPrimary
                            onClick={ApproveButton}
                            disabled={isRejecting || isApproving}
                        >
                            {isApproving ? '처리중...' : BUTTON_TEXT.accept}
                        </ActionButton>
                    </>
                );
            default:
                return null;
        }
    };

    return <ButtonContainer>{renderButtons()}</ButtonContainer>;
};

export default PermissionButtons;
