import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Logo from "./Logo";
import { useAtom } from "jotai";
import {clearSession, userDataAtom} from "../../atoms/auth";
import { UserInformation } from "../interfaces/auth.interfaces";
import MenuIcon from '@mui/icons-material/Menu';
import {colors} from "../../styles/color";

const NavBarContainer = styled.nav`
  display: flex;
  align-items: center;
  background-color: white;
  border-bottom: 0.1rem solid ${colors.neutral["300"]};
  padding: 1.2rem 32px;
  color: ${colors.neutral["900"]};
  max-width: 160rem;
  margin: 0 auto;

  @media (max-width: 1250px) {
    padding: 1.2rem 4rem;
  }

  @media (max-width: 768px) {
    padding: 1.2rem 2rem;
    position: relative;
  }
`;

const MenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  
  @media (max-width: 768px) {
    display: block;
    margin-left: auto;
  }
`;

const NavContent = styled.div<{ $isOpen: boolean }>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    display: ${({ $isOpen }) => ($isOpen ? 'flex' : 'none')};
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    flex-direction: column;
    background-color: white;
    padding: 1rem;
    border-bottom: 0.1rem solid ${colors.neutral["300"]};
    z-index: 1000;
  }
`;

const NavLinkList = styled.div`
  display: flex;
  gap: 2.4rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 1.6rem;
    margin: 1rem 0;
  }
`;

const NavLink = styled(Link)<{ $currentPage: boolean }>`
  color: ${colors.neutral["900"]};
  text-align: center;
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.regular};
  ${({ $currentPage, theme }) => $currentPage && theme.font.bold};

  @media (max-width: 768px) {
    font-size: 1.4rem;
  }
`;

const UserStatusContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 2rem;
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.regular};

  @media (max-width: 768px) {
    gap: 1rem;
  }
`;

const IconWrapper = styled.div<{ $alert: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  cursor: pointer;

  > .text {
    color: ${colors.neutral["900"]};
    ${({ theme }) => theme.font.body2};
    ${({ theme }) => theme.font.regular};

    @media (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  @media (max-width: 576px) {
    > .text {
      display: none;
    }
  }

  > .logout {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    background-image: url("/svg/logout.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  > .user-icon {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    background-image: url("/svg/user.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    &::after {
      display: ${({ $alert }) => ($alert ? "block" : "none")};
      position: absolute;
      top: 0;
      right: 0;
      content: "";
      width: 0.8rem;
      height: 0.8rem;
      background-color: var(--crimson-600);
      border-radius: 50%;
    }
  }
`;

const SignIn = styled.button`
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.regular};
  padding: 0.8rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  background-color: ${colors.purple["300"]};
  color: white;

  @media (max-width: 768px) {
    padding: 0.6rem 1rem;
    font-size: 1.4rem;
  }
`;

const ContactUsWrapper = styled.div`
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 1.4rem;
  }

  @media (max-width: 576px) {
    display: none;
  }
`;

const UserStatus = ({ userData }: { userData: UserInformation | null }) => {
  const [, setUserData] = useAtom(userDataAtom);
  const navigate = useNavigate();
  const logout = () => {
    setUserData(null);
    clearSession();
    navigate("/", { replace: true });
  };

  const ClickMoveContactUs = () => {
    navigate("/contact-us");
  };

  if (userData && userData.id && userData.isVerification)
    return (
      <UserStatusContainer>
        <IconWrapper $alert={false}>
          <div className="user-icon" />
          <span className="text">{userData.companyName}</span>
        </IconWrapper>
        <IconWrapper $alert={false} onClick={logout}>
          <div className="logout" />
          <span className="text">Log out</span>
        </IconWrapper>
      </UserStatusContainer>
    );
  else {
    return (
      <UserStatusContainer>
        <ContactUsWrapper onClick={ClickMoveContactUs}>Contact us</ContactUsWrapper>
        <Link to="/login">
          <SignIn>Sign in</SignIn>
        </Link>
      </UserStatusContainer>
    );
  }
};

const Navbar = () => {
  const [userData, setUserData] = useAtom(userDataAtom);
  const location = useLocation();
  const currentPath = location.pathname;
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <NavBarContainer>
      <Logo url="/" />
      <MenuButton onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <MenuIcon />
      </MenuButton>
      <NavContent $isOpen={isMenuOpen}>
        <NavLinkList>
          {(userData?.id && userData.isVerification) && (
            <NavLink
              to="/dashboard"
              $currentPage={currentPath.startsWith("/dashboard")}
              onClick={() => setIsMenuOpen(false)}
            >
              Dashboard
            </NavLink>
          )}
          {userData?.companyType === "advertiser" && (
            <NavLink
              to="/celebs"
              $currentPage={currentPath.startsWith("/celebs")}
              onClick={() => setIsMenuOpen(false)}
            >
              Celebs
            </NavLink>
          )}
        </NavLinkList>
        <UserStatus userData={userData} />
      </NavContent>
    </NavBarContainer>
  );
};

export default Navbar;
