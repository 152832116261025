import React, { useState } from "react";
import styled from "styled-components";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Apply from "../button/Apply";
import RangeSlider from "../common/RangeSlider";
import Country from "../select/Country";
import Checkbox from "../common/Checkbox";
import { CelebListFilterQuery } from "../interfaces/filter.interface";
import { colors } from "../../styles/color";
import {useAtom} from "jotai/index";
import {celebFilterAtom, displayCountryAtom, celebCountryFilterAtom} from "../../atoms/filter";
import SelectCountryTags from "../select/SelectCountryTags";

const CelebListSidebarContainer = styled.div`
  border-right: 0.1rem solid ${colors.neutral["50"]};
  width: 20%;
  height: calc(100vh - 8rem);
  flex-shrink: 0;
  position: sticky;
  top: 1px;
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
  
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  
  @media (max-width: 1250px) {
    width: 100%;
    height: auto;
    position: static;
    border-right: none;
    border-bottom: 0.1rem solid ${colors.neutral["100"]};
  }
`;

const CelebListSidebarContainerHeader = styled.div`
  display: flex;
  width: 320px;
  // please change padding px to rem
  padding: 1.25rem 2rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  
  .filters {
    color: ${colors.neutral["900"]};
    /* Title1 / Bold */
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 28.8px */   
  }
  
  .clear_all {
    color: ${colors.neutral["400"]};
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px; /* 100% */ 
    
    &:hover {
      cursor: pointer;
      color: ${colors.neutral["600"]};
  }
`;

const FilterMenuContainer = styled.ul`
  @media (max-width: 768px) {
    padding: 0 1.6rem;
  }
`;

const FilterMenu = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 1.25rem 2rem; // 20px 32px;
  border-bottom: 0.1rem solid ${colors.neutral["100"]};
  color: ${props => props.className === "selected" ? "var(--Primary-Violet-500, #851DF0)" : colors.neutral["900"]};
  cursor: pointer;
  white-space: nowrap;

  svg {
    color: ${props => props.className === "selected" ? "var(--Primary-Violet-500, #851DF0)" : "inherit"};
  }

  @media (max-width: 768px) {
    padding: 1.6rem;
  }
`;

const FilterDetail = styled.li<FilterDetailProps>`
  padding-top: ${props => props.topPadding || '1.25rem'};
  padding-right: ${props => props.rightPadding || '2rem'};
  padding-bottom: ${props => props.bottomPadding || '1.25rem'};
  padding-left: ${props => props.leftPadding || '2rem'};
  border-bottom: 0.1rem solid ${colors.neutral["100"]};
  color: ${colors.neutral["900"]};
  opacity: ${props => props.disabled ? 0.5 : 1};
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};

  @media (max-width: 768px) {
    padding: 1.6rem;
  }
`;


const BudgetContainer = styled.div`
  display: flex;
  width: 256px;
  height: 40px;
  align-items: center;
  gap: 8px;
`;

const TypeOfAdvertising = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  flex-shrink: 0;
  
  .title {
    color: ${colors.neutral["700"]};
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */ 
  }
  
  .item-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
    height: 57px;
    flex-wrap: wrap;
  }
  
  .item {
    color: ${colors.neutral["400"]};
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    border-radius: 12px;
    border: 1px solid ${colors.neutral["400"]};
    background-color: white;
    height: 24px;
    padding: 2px 10px;
    gap: 8px;
    cursor: pointer;

    &:hover {
      color: ${colors.neutral["700"]};
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      border-radius: 12px;
      border: 1px solid ${colors.neutral["700"]};
      background-color: white;
    }
    
    &.selected {
      display: flex;
      height: 24px;
      padding: 2px 10px;
      gap: 8px;
      border-radius: 12px;
      background: #AF6EFD;
      color: white;
      border: none;
    }
  }
`;

const CheckboxContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.4rem;
`;

const BudgetDropDownContainer = styled.div`
  background-color: #FBFBFB;
  width: 80px;
  height: 40px;
  position: relative;
  cursor: pointer;
  border: 1px solid ${colors.neutral["200"]};
  border-radius: 4px;
`;

const StyledSelect = styled.div`
  display: flex;
  width: 60px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  padding: 0 10px;
  height: 100%;
  justify-content: space-between;
  
  .currency-text {
    color: ${colors.neutral["900"]};
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }
`;

const DropdownOptions = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: white;
  border: 1px solid ${colors.neutral["200"]};
  border-radius: 4px;
  z-index: 10;
  display: ${props => props.isOpen ? 'block' : 'none'};
  
  .option {
    padding: 8px 10px;
    color: ${colors.neutral["900"]};
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    cursor: pointer;
    
    &:hover {
      background-color: ${colors.neutral["50"]};
    }
  }
`;

const BudgetInputContainer = styled.div`
  display: flex;
  border: 1px solid ${colors.neutral["300"]};
  padding: 0px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
`;

const BudgetInput = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  
  input {
    color: ${colors.neutral["900"]};
    border: none;
    text-align: right;
    width: 112px;
    /* Body2/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */ 
  }
  
  span {
    color: ${colors.neutral["900"]};
    text-align: center;

    /* Body2/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
  }
`

const celebType = [
  { label: "Musician", value: "musician" },
  { label: "Actor", value: "actor" },
  { label: "Others", value: "others" },
  { label: "Athlete", value: "athlete" },
];

interface CelebListSidebarProps {
  query: CelebListFilterQuery;
  setQuery: (name: keyof CelebListFilterQuery, value: string | string[] | number, checked?: boolean) => void;
  refetch: () => void;
}

interface FilterDetailProps {
  topPadding?: string;
  rightPadding?: string;
  bottomPadding?: string;
  leftPadding?: string;
  disabled?: boolean;
}

// Add this type definition if not already present
type AdvertisingType = "digitalAds" | "instagramPostingAd" | "";

// Change this from an array to a mapping object for easier reference
const advertisingTypeMap = {
  "Digital Ads": "digitalAds" as AdvertisingType,
  "Instagram Posting Ad": "instagramPostingAd" as AdvertisingType
};

const CelebListSidebar = ({
                            query,
                            setQuery,
                            refetch,
                          }: CelebListSidebarProps) => {
  const [celebTypeStatus, setCelebTypeStatus] = useState<boolean>(false);
  const [advertisingInfo, setAdvertisingInfo] = useState<boolean>(false);
  const [countryStatus, setCountryStatus] = useState<boolean>(false);
  const [_, setDisplayCountry] = useAtom(displayCountryAtom);
  const [selectedAdvertisingType, setSelectedAdvertisingType] = useState<string>("");
  const [currency, setCurrency] = useState<string>(query.currencyType || "USD");
  const [budgetAmount, setBudgetAmount] = useState<string>(query.money?.toString() || "0");
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [defaultFilterValues] = useAtom(celebFilterAtom);
  const [__, setSelectedCountryDisplay] = useAtom(celebCountryFilterAtom);

  const [localQuery, setLocalQuery] = useState<CelebListFilterQuery>(query);

  // 기존 queryHandler와 동일한 로직을 로컬 상태 업데이트용으로 사용
  const updateArray = (target: string[], value: string, checked: boolean) => {
    return checked ? [...target, value] : target.filter((item) => item !== value);
  };

  const localQueryHandler = (
    name: keyof CelebListFilterQuery,
    value: string | string[] |number,
    checked?: boolean
  ) => {
    setLocalQuery((prevQuery) => {
      const targetValue = prevQuery[name];
      if (
        checked !== undefined &&
        typeof value === "string" &&
        Array.isArray(targetValue)
      ) {
        return {
          ...prevQuery,
          [name]: updateArray(targetValue as string[], value, checked),
        };
      }
      return {
        ...prevQuery,
        [name]: value,
      };
    });
  };

  const handleApply = () => {
    // 제외할 필드 목록
    const excludeFields = ['gender', 'order__by'];

    // 제외 필드를 제외한 모든 필드 업데이트
    Object.entries(localQuery).forEach(([key, value]) => {
      if (!excludeFields.includes(key)) {
        setQuery(key as keyof CelebListFilterQuery, value);
      }
    });

    setDisplayCountry(localQuery.countries);
    refetch();
  };

  const onHandleClearAll = () => {
    // Reset local query to default values from the atom
    setLocalQuery({...defaultFilterValues, countries: ['WW']});

    // Reset UI state
    setSelectedAdvertisingType("");
    setCurrency("USD");
    setBudgetAmount("0");
    setIsDropdownOpen(false);

    // Remove the immediate application and refetch
    // Instead, just update the local state and wait for Apply button
    setSelectedCountryDisplay(["Worldwide"]);
  };

  const handleAdvertisingTypeClick = (displayText: string) => {
    // Toggle the selected display text for UI
    const newDisplayText = selectedAdvertisingType === displayText ? "" : displayText;
    setSelectedAdvertisingType(newDisplayText);

    // Get the correct value type from our mapping
    const valueToSet: AdvertisingType = newDisplayText ?
      advertisingTypeMap[newDisplayText as keyof typeof advertisingTypeMap] :
      "";

    // Update the local query with the correct value type
    localQueryHandler("advertisingType", valueToSet);
  };

  const handleCurrencyChange = (selectedCurrency: string) => {
    setCurrency(selectedCurrency);
    setIsDropdownOpen(false);
    // Update the local query with the currency type
    localQueryHandler("currencyType", selectedCurrency);
  };

  const handleBudgetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    // 모든 콤마 제거
    const numberValue = inputValue.replace(/,/g, '');
    // 숫자만 남기기
    const numericValue = numberValue.replace(/[^\d]/g, '');
    // 정수로 변환
    const integerValue = numericValue === '' ? 0 : parseInt(numericValue, 10);
    // 세 자리마다 콤마 추가하여 표시용 문자열 생성
    const formattedValue = integerValue.toLocaleString();

    // 표시용 값 설정 (콤마 포함)
    setBudgetAmount(formattedValue);

    // 실제 계산에 사용되는 값 전달 (콤마 없음)
    localQueryHandler("money", integerValue);
  };

  // Add this function to check if advertising duration should be disabled
  const isAdvertisingDurationDisabled = () => {
    return !selectedAdvertisingType || selectedAdvertisingType === 'Instagram Posting Ad';
  };

  return (
    <CelebListSidebarContainer>
      <CelebListSidebarContainerHeader>
        <div className="filters">Filters</div>
        <div className="clear_all" onClick={onHandleClearAll}>Clear all</div>
      </CelebListSidebarContainerHeader>
      <FilterMenuContainer>
        <FilterMenu
          className={countryStatus ? "selected" : ""}
          onClick={() => setCountryStatus(!countryStatus)}
        >
          Target Countries
          {countryStatus ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </FilterMenu>
        {countryStatus && (
          <>
            <FilterDetail>
              <Country query={localQuery} setQuery={localQueryHandler} />
              <SelectCountryTags
                countries={localQuery.countries}
                onRemoveCountry={(countryToRemove) => {
                  localQueryHandler(
                    "countries",
                    localQuery.countries.filter(country => country !== countryToRemove)
                  );
                }}
              />
            </FilterDetail>
            <FilterDetail>
              <RangeSlider
                title="Instagram Followers"
                width={256}
                max={1000000}
                minValue={localQuery.minInstagramFollowers}
                maxValue={localQuery.maxInstagramFollowers}
                name="InstagramFollowers"
                setQuery={localQueryHandler}
              />
            </FilterDetail>
            <FilterDetail>
              <RangeSlider
                title="Search Volume"
                width={256}
                max={50000}
                minValue={localQuery.minSearchVolume}
                maxValue={localQuery.maxSearchVolume}
                name="SearchVolume"
                setQuery={localQueryHandler}
              />
            </FilterDetail>
          </>
        )}
        <FilterMenu
          className={advertisingInfo ? "selected" : ""}
          onClick={() => setAdvertisingInfo(!advertisingInfo)}
        >
          Advertising Budget
          {advertisingInfo ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </FilterMenu>
        {advertisingInfo && (
          <>
            <FilterDetail>
              <TypeOfAdvertising>
                <div className="title">Advertising Formats</div>
                <div className="item-container">
                  <span
                    className={`item ${selectedAdvertisingType === 'Digital Ads' ? 'selected' : ''}`}
                    onClick={() => handleAdvertisingTypeClick('Digital Ads')}
                  >
                    Digital Ads
                  </span>
                  <span
                    className={`item ${selectedAdvertisingType === 'Instagram Posting Ad' ? 'selected' : ''}`}
                    onClick={() => handleAdvertisingTypeClick('Instagram Posting Ad')}
                  >
                    Instagram Posting Ad
                  </span>
                </div>
              </TypeOfAdvertising>
            </FilterDetail>
            <FilterDetail disabled={isAdvertisingDurationDisabled()}>
              <div style={{ display: "flex", flexDirection: "column", gap: "14px"}}>
                <div style={{ fontSize: "14px", color: "#666"}}>Advertising Duration</div>
                <RangeSlider
                  title={
                    `For ${localQuery.maxAdvertisingDuration === 12
                    ? "1 year"
                    : `${localQuery.maxAdvertisingDuration} months`}`
                  }
                  titleColor="#7A7A7A"
                  width={256}
                  min={1}
                  max={12}
                  minValue={localQuery.minAdvertisingDuration}
                  maxValue={localQuery.maxAdvertisingDuration === 0 ? 1 : localQuery.maxAdvertisingDuration}
                  name="AdvertisingDuration"
                  hideRangeViewer={false}
                  double={false}
                  setQuery={localQueryHandler}
                />
              </div>
            </FilterDetail>
            <FilterDetail
              bottomPadding="2.5rem"
              disabled={!selectedAdvertisingType}
            >
              <div style={{ display: "flex", flexDirection: "column", gap: "14px"}}>
                <div style={{ color: "#666"}}>Budget</div>
                <BudgetContainer>
                  <BudgetDropDownContainer onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                    <StyledSelect>
                      <span className="currency-text">{currency}</span>
                      <img src="/svg/celeblist/dropdown.svg" alt="dropdown"/>
                    </StyledSelect>
                    <DropdownOptions isOpen={isDropdownOpen}>
                      <div className="option" onClick={() => handleCurrencyChange("USD")}>USD</div>
                      <div className="option" onClick={() => handleCurrencyChange("KRW")}>KRW</div>
                    </DropdownOptions>
                  </BudgetDropDownContainer>
                  <BudgetInputContainer>
                    <BudgetInput>
                      <input
                        type="text"
                        value={budgetAmount}
                        onChange={handleBudgetChange}
                        placeholder="0"
                      />
                      <span> {currency === "USD" ? "$" : "₩"} </span>
                    </BudgetInput>
                  </BudgetInputContainer>
                </BudgetContainer>
              </div>
            </FilterDetail>
          </>
        )}
        <FilterMenu
          className={celebTypeStatus ? "selected" : ""}
          onClick={() => setCelebTypeStatus(!celebTypeStatus)}
        >
          Celeb type
          {celebTypeStatus ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </FilterMenu>
        {celebTypeStatus && (
          <FilterDetail>
            <CheckboxContainer>
              {celebType.map((type) => (
                <Checkbox
                  key={"celeb_type_checkbox" + type.value}
                  label={type.label}
                  value={type.value}
                  checked={localQuery.type.includes(type.value)}
                  onChange={(value, checked) =>
                    localQueryHandler("type", value, checked)
                  }
                />
              ))}
            </CheckboxContainer>
          </FilterDetail>
        )}
        <FilterDetail>
          <Apply
            onApply={handleApply}
          />
        </FilterDetail>
      </FilterMenuContainer>
    </CelebListSidebarContainer>
  );
};

export default CelebListSidebar;
