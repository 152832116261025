import styled from "styled-components";
import { colors } from "../styles/color";
import {useAtom} from "jotai/index";
import {userDataAtom} from "../atoms/auth";
import {publicClient} from "../api/axiosInstance";
import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import Logo from "../components/layout/Logo";
import { FormContainer } from "../components/common/FormContainer";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5rem;
	align-items: center;
	justify-content: center;
	margin-top: 6rem;
`;

const Wrapper = styled.div`
	width: 372px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 2.5rem;
`;

const Header = styled.div`
	gap: 1rem; // 16px
	display: flex;
	flex-direction: column;

	img {
		margin: 0 auto;
		width: 77px;
		height: 77px;
		aspect-ratio: 1/1;
	}

	p {
		color: ${colors.neutral[900]};
		text-align: center;
		font-family: Inter;
		font-size: 24px;
		font-style: normal;
		font-weight: 700;
		line-height: 120%; /* 28.8px */
	}
`;

const Content = styled.div`
	color: ${colors.neutral[900]};
	text-align: center;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%; /* 19.2px */
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;

const Bottom = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
	align-self: stretch;

	button {
		width: 372px;
		height: 60px;
		flex-shrink: 0;
		border-radius: 2px;
		color: white;
		background-color: ${colors.neutral["900"]};
		text-align: center;
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 120%; /* 19.2px */
	}

	div {
		display: flex;
		color: ${colors.neutral[600]};
		font-family: Inter;
		text-align: center;
		margin: auto;
		justify-content: center;
		align-items: center;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;

		.email {
			color: ${colors.email.blue};
			margin-left: 5px;
			font-family: Inter;
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 150%;
		}
	}
`;

const VerificationRequiredPage = () => {
	const navigate = useNavigate();
	const [userData, _] = useAtom(userDataAtom);

	const sendVerificationEmail = async () => {
		const userEmail = userData?.email;
		try {
			await publicClient.post("/api/v1/email/send_verification", { email: userEmail });
			navigate("/verify-via-email");
		} catch (error: any) {
			console.error("Unexpected error:", error);
			if (error?.response?.data?.message === "The request limit has been exceeded") {
				navigate("/verify-via-email", { state: { isLimitExceeded: true } });
			}
		}
	}

	return (
		<Container>
			<Logo url="/" />
			<FormContainer>
				<Wrapper>
					<Header>
						<img src="/svg/auth/signup_email_icon.svg" alt="signup_email_icon.svg"/>
						<p>Verification Required</p>
					</Header>
					<Content>
						<div>To keep your account secure, we need to verify</div>
						<div>your identity.</div>
						<div>Please verify your account to continue using</div>
						<div>Celevu.</div>
					</Content>
					<Bottom>
						<button onClick={sendVerificationEmail}>Send Verification Email</button>
						<div>Didn't get the email? Contact us
							<a className="email" href="mailto:dev@aligo.ai">dev@aligo.ai</a>
						</div>
					</Bottom>
				</Wrapper>
			</FormContainer>
		</Container>
	)
}

export default VerificationRequiredPage;
