import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import styled from 'styled-components';
import { UseFormRegister } from 'react-hook-form';
import { useFormContext, useWatch } from 'react-hook-form';
import { colors } from "../../styles/color";

interface PhoneInputProps {
  register: UseFormRegister<any>;
  fieldName: string;
  label?: string;
  required?: boolean;
  errorMessage?: string;
}

const PhoneInputWrapper = styled.div<{ $hasError?: boolean }>`
  .label-group {
    display: flex;
    padding: 0 0.2rem;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .label {
    color: ${colors.neutral[600]};
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.bold};
    white-space: nowrap;
    display: inline-block;
    
  }

  .error-message {
    color: ${colors.alert.error};
    text-align: right;
    ${({ theme }) => theme.font.body2};
    ${({ theme }) => theme.font.regular};
    text-overflow: ellipsis;
  }

  .react-tel-input {
    .form-control {
      width: 420px;
      height: 60px;
      padding: 20px 20px 20px 70px;
      border: 1px solid ${props => props.$hasError ? colors.alert.error : colors.neutral[300]};
      border-radius: 4px;
      font-size: 16px;
      color: ${colors.neutral[900]};
      gap: 8px;
      
      &:focus {
        border-color: ${props => props.$hasError ? colors.alert.error : colors.purple[500]};
        box-shadow: none;
      }

      &:hover {
        border-color: ${props => props.$hasError ? colors.alert.error : colors.neutral[400]};
      }

      &:disabled {
        border: 0.1rem solid ${colors.neutral[300]};
        border-radius: 0.4rem;
        background-color: ${colors.neutral[0]};
        color: ${colors.neutral[900]};
      }

      &.error {
        border: 0.1rem solid ${colors.alert.error};
        border-radius: 0.4rem;
        background-color: ${colors.neutral[0]};
        color: ${colors.neutral[900]};
      }
    }

    .selected-flag {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: transparent !important;
      width: 60px;
      height: 20px;
      top: 50%;
      transform: translateY(-50%);
      left: 12px;
      padding: 0 4px;
      
      .flag {
        transform: scale(1.2);
      }

      .arrow {
        position: relative;
        width: 8.85px;
        height: 8.85px;
        border: none;
        border-right: 1.5px solid ${colors.neutral[600]};
        border-bottom: 1.5px solid ${colors.neutral[600]};
        transform: rotate(45deg) translateY(-8px);
        transition: transform 0.3s;
      }
    }

    .flag-dropdown {
      border: none;
      background: transparent;
    }

    .country-list {
      margin-top: 4px;
      border: 0.1rem solid ${colors.neutral[300]};
      border-radius: 0.4rem;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      width: 200px;
      scrollbar-width: thin;
      scrollbar-color: ${colors.neutral[400]} transparent;
      
      &::-webkit-scrollbar {
        width: 6px;
      }
      
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      
      &::-webkit-scrollbar-thumb {
        background-color: ${colors.neutral[400]};
        border-radius: 3px;
      }

      .search {
        z-index: 1;
        padding-right: 10px;
      }

      .country {
        padding-right: 10px;
      }
      
      .country {
        padding: 8px 12px;
        color: ${colors.neutral[900]};
        font-size: 16px;
        
        .flag {
          transform: scale(1.2);
          margin-right: 8px;
        }
        
        .dial-code {
          color: ${colors.neutral[900]};
        }
        
        &:hover {
          background: ${colors.neutral[50]};
        }
        
        &.highlight {
          background: ${colors.neutral[100]};
        }
      }
    }
  }
`;

const PhoneInputComponent = ({ register, fieldName, errorMessage, ...props }: PhoneInputProps) => {
  const { setValue } = useFormContext();
  const value = useWatch({ name: fieldName });

  const handleChange = (phone: string, country: any) => {
    // 번호가 비어있는 경우
    if (!phone) {
      setValue(fieldName, '');
      return;
    }

    // 번호가 국가 코드만 있는 경우
    if (phone === country.dialCode) {
      const codeWithoutPlus = country.dialCode.startsWith('+') ? country.dialCode.slice(1) : country.dialCode;
      setValue(fieldName, codeWithoutPlus);
      return;
    }

    // 실제 번호가 입력된 경우
    if (phone.length > country.dialCode.length) {
      if (country.countryCode === 'kr') {
        // 한국 번호인 경우
        const numberWithoutCode = phone.replace(country.dialCode, '');
        setValue(fieldName, `82${numberWithoutCode}`);
      } else {
        // 다른 나라 번호인 경우
        const numberWithoutPlus = phone.startsWith('+') ? phone.slice(1) : phone;
        setValue(fieldName, numberWithoutPlus);
      }
    }
  };

  return (
    <PhoneInputWrapper $hasError={!!errorMessage}>
      <div className="label-group">
        <span className="label">{props.label}</span>
        {errorMessage && <span className="error-message">{errorMessage}</span>}
      </div>
      <PhoneInput
        country={'kr'}
        preferredCountries={['kr', 'us', 'jp', 'id', 'br', 'mx', 'ir', 'ph', 'th', 'fr']}
        enableSearch={true}
        searchPlaceholder="Search"
        searchNotFound="No entries to show"
        disableSearchIcon={true}
        value={value}
        onChange={handleChange}
        placeholder=""
        inputProps={{
          placeholder: ""
        }}
        searchStyle={{
          width: '100%',
          height: '30px',
          padding: '2px',
          marginBottom: '8px',
          border: `1px solid ${colors.neutral[300]}`,
          borderRadius: '1px'
        }}
      />
    </PhoneInputWrapper>
  );
};

export default PhoneInputComponent;
