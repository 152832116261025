import { css } from "styled-components";

export const sizeStyle = {
  xs: css`
    width: 7.6rem;
    height: 3.4rem;
    ${({ theme }) => theme.font.body2};
    ${({ theme }) => theme.font.regular};
  `,
  sm: css`
    width: 16rem;
    height: 6rem;
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.regular};
  `,
  md: css`
    width: 21rem;
    height: 6rem;
    padding: 2rem;
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.regular};
  `,
  lg: css`
    width: 42rem;
    height: 6rem;
    padding: 2rem;
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.regular};
  `,
  llg: css`
    width: 64rem;
    height: 6rem;
    padding: 2rem;
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.regular};
  `,
  default: css`
    width: 42rem;
    height: 6rem;
    padding: 2rem;
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.regular};
  `,
};
