import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

const MainPage = () => {
	const location = useLocation();

	useEffect(() => {
		// 쿼리 파라미터가 있으면 그것을 유지하면서 리다이렉트
		if (window.location.hostname !== 'main.celevu.ai') {
			window.location.href = `https://main.celevu.ai${location.search}`;
		}
	}, [location]);

	return (
		<div style={{ background: "#fff0", height: "100vh" }}>
		</div>
	);
};

export default MainPage;
