import React from "react";
import styled from "styled-components";
import Sidebar from "./Sidebar";
import { Outlet } from "react-router-dom";

const DashboardContainer = styled.div`
  display: flex;
  background-color: var(--gray-050);
  max-width: 1600px;
  
  @media (max-width: 768px) {
    flex-direction: column;
    overflow-x: hidden;
  }
`;

const ContentArea = styled.div`
  background-color: var(--gray-050);
  padding-left: 60px;
  padding-top: 44px;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  
  @media (max-width: 768px) {
    padding-left: 0;
    padding-top: 20px;
    width: 100%;
    margin: 0 auto;
    overflow-x: hidden;
  }
`;

const Content = styled.div`
  display: flex;
  width: 1176px;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 16px;
  flex-wrap: nowrap;
  overflow-x: auto;
  
  @media (max-width: 768px) {
    padding: 0 16px;
    box-sizing: border-box;
    width: 100%;
  }
`;

const Dashboard = () => {
  return (
    <DashboardContainer>
      <Sidebar />
      <ContentArea>
        <Content>
          <Outlet />
        </Content>
      </ContentArea>
    </DashboardContainer>
  );
};

export default Dashboard;
