import {useQuery} from "@tanstack/react-query";
import {getCelebPermissionCelevu} from "../api/celebList";
import {CelebPermissionStatus} from "../components/dashboard/celebrityAgency/MyPermissionCelebList";

export const usePermissionCeleb = (status: CelebPermissionStatus) => {
	const query = useQuery({
		queryKey: ['permissionCeleb', status],
		queryFn: () => getCelebPermissionCelevu(status),
	});

	return {
		data: query.data,
		isPending: query.isPending,
		refetch: query.refetch,
	};
};
