import React from "react";
import styled from "styled-components";
import Checkbox from "../../common/Checkbox";
import {CelebListFilterQuery, FavoriteCelebListFilterQuery} from "../../interfaces/filter.interface";
import AdvertiserFavoriteOrder from "./AdvertiserFavoriteOrder";

const FilterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
	max-width: 100%;
  gap: 1.6rem;
  align-items: center;
  margin-left: auto;
  margin-bottom: 20px;
  
  @media (max-width: 1250px) {
    margin-right: 0;
    margin-left: 0;
    justify-content: center; 
  }

  @media (max-width: 768px) {
		max-width: 30%;
		display: flex;
    width: 100%;
    justify-content: flex-start;
  }
`;

const CheckBoxContainer = styled.div`
  display: flex;
  gap: 20px;

	@media (max-width: 768px) {
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

interface FilterProps {
	query: FavoriteCelebListFilterQuery;
	setQuery: Function;
}

const AdvertiserFavoriteFilter = ({ query, setQuery }: FilterProps) => {
	return (
		<FilterContainer>
			<CheckBoxContainer>
				<Checkbox
					label="Male"
					value="MALE"
					checked={query.gender.includes("MALE")}
					onChange={(value, checked) => setQuery("gender", value, checked)}
				/>
				<Checkbox
					label="Female"
					value="FEMALE"
					checked={query.gender.includes("FEMALE")}
					onChange={(value, checked) => setQuery("gender", value, checked)}
				/>
			</CheckBoxContainer>
			<AdvertiserFavoriteOrder query={query} setQuery={setQuery} />
		</FilterContainer>
	);
};

export default AdvertiserFavoriteFilter;
