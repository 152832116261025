import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import { colors } from '../../../styles/color';
import MyPermissionCelebItem, {ICelebData} from "./MyPermissionCelebItem";
import {usePermissionCeleb} from "../../../hooks/useCelebAgency";
import {getCelebPermissionCelevu} from "../../../api/celebList";

const Container = styled.div`
	max-width: 1000px;
	display: flex;
	flex-direction: column;
	min-width: 300px;
	width: 100%;

	@media (max-width: 768px) {
		padding: 0 1rem;
		max-width: 100%;
	}
`;

const Title = styled.div`
	color: ${colors.neutral["800"]};
	font-family: Pretendard;
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%; /* 28.8px */
	margin-bottom: 24px;
	${({ theme }) => theme.font.title1};
	${({ theme }) => theme.font.regular};
	color: ${colors.neutral["800"]};

	@media (max-width: 768px) {
		display: none;
	}
`;

const SplitLine = styled.div`
	height: 2px;
	background: #474747;
	width: 1000px;
	max-width: 100%;

	@media (max-width: 768px) {
		display: none;
	}
`

const TotalContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 32px;
`;

const ContentContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	align-content: flex-start;
	gap: 32px;
	align-self: stretch;

	@media (max-width: 768px) {
		width: 400px;
		margin: 0 auto;
		flex-direction: column;
		flex-wrap: nowrap;  // 추가된 부분
		justify-content: center;
		align-items: center;
	}
`;

const FilterContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 20px;
	padding-bottom: 16px;
	max-width: 1000px;
	max-height: 1000px;

	@media (max-width: 768px) {
		display: flex;
		align-items: center;
		margin: 0 auto;
		gap: 16px;
	}
`;

const TabItem = styled.div<{ isSelected: boolean }>`
	cursor: pointer;
	color: ${props => props.isSelected ? 'var(--Grey-800, #474747)' : 'var(--Grey-500, #A4A4A4)'};
	transition: color 0.2s ease;
	font-family: Pretendard;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 100%; /* 20px */

	&:hover {
		color: ${colors.neutral["800"]};
		cursor: pointer;
	}

	@media (max-width: 768px) {
		color: ${props => props.isSelected ? 'var(--Grey-800, #474747)' : 'var(--Grey-500, #A4A4A4)'};
		font-family: Pretendard;
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: 100%; /* 16px */
	}
`;

const EmptyContainer = styled.div`
	display: flex;
	gap: 20px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-height: 300px;
`;

const LoadingBar = styled.div`
	width: 100%;
	height: 2px;
	background-color: ${colors.neutral["200"]};
	position: relative;
	overflow: hidden;
	margin-bottom: 16px;

	&::after {
		content: '';
		position: absolute;
		width: 40%;
		height: 100%;
		background-color: ${colors.neutral["800"]};
		animation: loading 1s infinite ease-in-out;
	}

	@keyframes loading {
		0% {
			transform: translateX(-100%);
		}
		100% {
			transform: translateX(250%);
		}
	}
`;

export enum CelebPermissionStatus {
	PENDING = 'PENDING',
	GRANTED = 'GRANTED',
	REJECTED = 'REJECTED',
}

const MyPermissionCelebList = () =>{
	const [selectedTab, setSelectedTab] = useState(CelebPermissionStatus.PENDING);

	const {
		data,
		isPending,
		refetch
	} = usePermissionCeleb(selectedTab);

	const {
		data: pendingData,
		refetch: refetchPending,
	} = usePermissionCeleb(CelebPermissionStatus.PENDING);

	// 데이터 구조 수정: 중첩된 data 객체에서 올바른 데이터 추출
	const celebs = data?.data?.data || [];
	const pendingCount = pendingData?.data?.data?.length || 0;

	// 데이터 새로고침을 위한 함수
	const handleDataRefresh = async () => {
		await Promise.all([refetch(), refetchPending()]);
	};

	useEffect(() => {
		// Tab 변경 시 데이터 새로고침
		refetch();
	}, [selectedTab, refetch]);

	if (isPending) {
		return <div>Loading...</div>;
	}

	return (
		<Container>
			<Title>제한된 프로필 관리</Title>
			<TotalContentContainer>
				<FilterContainer>
					<TabItem
						isSelected={selectedTab === CelebPermissionStatus.PENDING}
						onClick={() => setSelectedTab(CelebPermissionStatus.PENDING)}
					>
						새로 들어온 요청 ({pendingCount})
					</TabItem>
					<TabItem
						isSelected={selectedTab === CelebPermissionStatus.GRANTED}
						onClick={() => setSelectedTab(CelebPermissionStatus.GRANTED)}
					>
						수락한 요청
					</TabItem>
					<TabItem
						isSelected={selectedTab === CelebPermissionStatus.REJECTED}
						onClick={() => setSelectedTab(CelebPermissionStatus.REJECTED)}
					>
						거절한 요청
					</TabItem>
				</FilterContainer>
				<SplitLine />
				<ContentContainer>
					{celebs?.length > 0 ? (
						celebs.map((celeb: ICelebData) => (
							<MyPermissionCelebItem
								celebData={celeb}
								selectedTab={selectedTab}
								onDataUpdate={handleDataRefresh}
							/>
						))
					) : (
						<EmptyContainer>
							<img src={"/svg/dashboard/agency/files.svg"} alt={"files"} />
							<p>현재 새로 들어온 요청이 없습니다.</p>
						</EmptyContainer>
					)}
				</ContentContainer>
			</TotalContentContainer>
		</Container>
	);
};

export default MyPermissionCelebList;
