import React, { useEffect } from "react";
import { LayoutWithNavBack } from "../../layout/Layout";
import { Outlet, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { useCelebData } from "../../../hooks/useCeleb";
import { useAtom } from "jotai";
import { contractFormAtom } from "../../../atoms/contract";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  > .title {
    margin-top: 6rem;
    ${({ theme }) => theme.font.title1};
    ${({ theme }) => theme.font.regular};
    color: var(--gray-900);
    text-align: center;
  }
`;

const ContractLayout = () => {
  const { celebId } = useParams();
  const { data } = useCelebData(celebId);
  const [contractForm, setContractForm] = useAtom(contractFormAtom);
  const location = useLocation();
  const isCreateProject = location.pathname.includes(
    "/contract/project/create"
  );

  useEffect(() => {
    setContractForm({
      ...contractForm,
      celebInfo: data?.data.celebInfo,
    });
  }, [celebId, data]);

  return (
    <LayoutWithNavBack>
      {data && (
        <Container>
          {!isCreateProject ? (
            <p className="title">Request Contract</p>
          ) : (
            <p className="title">Create Project</p>
          )}
          <Outlet />
        </Container>
      )}
    </LayoutWithNavBack>
  );
};

export default ContractLayout;
