import React, { useState } from 'react';
import styled from "styled-components";
import {colors} from "../../../styles/color";
import {CelebData} from "../../interfaces/celeb.interfaces";
import FavoriteButton from "../../button/FavoriteButton";
import LanguageIcon from "@mui/icons-material/Language";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import Close from "@mui/icons-material/Close";
import ClosedPermissionRequestModals from "../../modals/ClosedPermissionRequestModals";
import PermissionGrantModals from "../../modals/PermissionGrantModals";
import PermissionPendingSuccessModals from "../../modals/PermissionPendingSuccessModals";
import ClosedPermissionPendingModals from "../../modals/ClosedPermissionPendingModals";
import {EPermissionStatus} from "./CelebSideInformation";
import StyledButton from "../../button/StyledButton";
import {useAtomValue} from "jotai/index";
import {userDataAtom} from "../../../atoms/auth";

const Overlay = styled.div`
	position: fixed;
	z-index: 2;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	gap: 0.8rem;
	
	@media (max-width: 850px) {
		margin-top: 40px;
		padding: 0 16px;
	}
`;

const CelebSideInfoHeader = styled.div`
  margin-bottom: 24px;
  width: 600px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  
  @media (max-width: 1350px) {
    width: 100%;
  }
`;

const ClosedHeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  
  .closed-celeb {
    display: flex;
    gap: 20px;
	}
`;

const OtherInformation = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  gap: 16px;
  
  @media (max-width: 1350px) {
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  height: auto;
  
  @media (max-width: 1350px) {
    width: 100%;
  }
  
  @media (max-width: 850px) {
    height: auto;
    aspect-ratio: 10/1;
  }
`;

interface ButtonProps {
	backgroundColor?: string;
	color?: string;
}

const Button = styled.button<ButtonProps>`
	background-color: ${props => props.backgroundColor};
	color: ${props => props.color};
  text-align: center;
  display: flex;
  width: 100%;
  height: 60px;
  justify-content: center;
  align-items: center;
  
  @media (max-width: 850px) {
    height: 100%;
    font-size: 14px;
  }
`;

const CloseIcon = styled.p`
  margin-left: auto;
  display: flex;
  color: white;
  cursor: pointer;
  font-size: 1.6rem;
`;

const ClosedCelebrityKeyword = styled.div`
  display: flex;
  width: 600px;
  padding: 12px;
  flex-direction: column;
	color: #FFF8E1;
	align-items: flex-start;
  gap: 8px;
  border-radius: 4px;
  background-color: ${colors.permissionCeleb["closed"]};
  
  @media (max-width: 1350px) {
    width: 100%;
  }
  
  @media (max-width: 850px) {
    padding: 8px;
    
    p {
			font-size: 14px;
      line-height: 1.3;
    }
  }
  
  img {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    
    @media (max-width: 850px) {
      width: 14px;
      height: 14px;
    }
  }

  .content {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    align-self: stretch;
    
    @media (max-width: 850px) {
      gap: 6px;
    }
  }
  
  .icon {
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
    color: #FF8F00;
    
    @media (max-width: 850px) {
      width: 16px;
      height: 16px;
    }
  }
`;

interface CelebNameProps {
	fontFamily?: string;
	color?: string;
	fontSize?: string;
}

const CelebName = styled.div<CelebNameProps>`
	font-family: ${props => props.fontFamily};
	color: ${props => props.color};
	font-size: ${props => props.fontSize};
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
	align-self: stretch;
	
	@media (max-width: 850px) {
		font-size: 40px;
	}
`;

const CelebTypes = styled.div`
  display: flex;
  gap: 0.4rem;
`;

const ClosedDescription = styled.div`
  width: 100%;
  height: 120px;
  color: ${colors.neutral["900"]};
  line-height: 150%;
  
  font-size: 18px;
  font-weight: 400;
  word-wrap: break-word; /* 긴 단어를 줄바꿈 */
  overflow-wrap: break-word; /* 브라우저 호환성을 위한 추가 설정 */
`;

export const ClosedTooltip = styled.div`
    min-width: 240px;
    max-width: 300px;
    word-wrap: break-word;
    overflow: visible;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    color: #333;
    padding: 1rem;
    border-radius: 0.4rem;
    border: 1px solid #FF6F00;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    font-size: 1.2rem;
    line-height: 1.4;
    transition: opacity 0.2s, transform 0.2s;
    z-index: 10;

    // 오렌지색 외부 삼각형
    &:before {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border: 12px solid transparent;
        border-top-color: #FF6F00;
        border-bottom: 0;
        margin-top: 0;
        z-index: 1;
    }
    // 흰색 내부 삼각형
    &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border: 11px solid transparent;
        border-top-color: white;
        border-bottom: 0;
        margin-top: -1px;
        z-index: 2;
    }
    
    .title {
        font-weight: bold;
        width: 200px;
        gap: 4px;
        height: 17px;
        font-size: 14px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: auto;

        img {
            margin-right: 0.4rem;
            width: 16px;
            height: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .content {
        margin-top: 0.4rem;
        text-align: center;
        z-index: 1000;
    }

    @media screen and (max-width: 768px) {
        left: auto;
        right: 0;
        transform: translateX(0);
    }
`;

const ActiveFromManagementLink = styled.div`
  border-top: 1px solid ${colors.neutral["800"]};
  border-bottom: 1px solid ${colors.neutral["800"]};
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;

  @media (max-width: 1250px) {
    flex-direction: column;
    height: auto;
    gap: 0.8rem;
  }
`;

const SocialIconList = styled.div`
  display: flex;
  font-size: 24px;
  gap: 16px;
  
  .material-icons {
    cursor: pointer;
  }
  
  a {
    color: black;
  }
`;

interface DescriptionProps {
	marginTop?: number;
}

const Description = styled.div<DescriptionProps>`
  color: ${colors.neutral["600"]};
  font-family: Inter;
  margin-top: ${(props) => props.marginTop}px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  cursor: pointer;

  &:hover {
    -webkit-line-clamp: unset;
    overflow: visible;
    z-index: 1;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 4px;
    border-radius: 4px;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  color: ${colors.neutral["900"]};
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  gap: 4px;
  
  .bold {
    margin-right: 16px;
    ${({ theme }) => theme.font.bold}
  }
`;


interface CelebSideInformationProps {
	celebData: CelebData;
}

const ClosedCelebSideInformation = ({
	                                    celebData,
                                    }: CelebSideInformationProps) => {
	const [showModal, setShowModal] = useState(false);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [showPendingModal, setShowPendingModal] = useState(true);

	const [isClickIcon, setIsClickIcon] = useState(false);
	const [isSuccessModal, setIsSuccessModal] = useState(false);
	const [isClosedRequestPermissionBtn, setIsClosedRequestPermissionBtn] = useState(false);
	const [isGrantModal, setIsGrantModal] = useState(false);

	const user = useAtomValue(userDataAtom);
	const celebId = celebData.celebInfo.id;
	const celebInfo = celebData.celebInfo;
	const celebPermissionStatus = celebData.celebInfo.permission;

	const onClickRequestPermission = () => {
		setShowModal(true);
	};

	const closeModal = () => {
		setShowModal(false);
	};

	const closePendingModal = () => {
		setShowPendingModal(false);
	};

	const closeGrantModal = () => {
		setIsSuccessModal(false);
		window.location.reload();
	};

	const changeClosedRequestPermissionBtn = () => {
		setIsClosedRequestPermissionBtn(true);
		setIsGrantModal(true);
		setShowSuccessModal(true);
	}

	const onCloseIconClick = () => {
		setIsClickIcon(true)
	}

	return (
		<Wrapper>
			<CelebSideInfoHeader>
				<ClosedHeaderContainer>
					<div className="closed-celeb">
						<img src={"/svg/closed/closed_celeb.svg"} alt={"profiling"} style={{ width: "48px" }} />
						<CelebName
							fontFamily="Inter"
							color={colors.permissionCeleb["closed"]}
							fontSize="40px"
						>
							Closed Celebrity
						</CelebName>
					</div>
					<FavoriteButton id={celebId} size={48} status={celebInfo.isLike} />
				</ClosedHeaderContainer>
				<CelebTypes>
					{(celebInfo.types ?? []).map((type, index) => (
						<span key={index}>{type}</span>
					))}
				</CelebTypes>
			</CelebSideInfoHeader>
			<OtherInformation>
				<ActiveFromManagementLink>
					<InfoContainer>
						<span>Active From :</span>
						<span className="bold">{celebInfo.activeFrom}</span>
						<span>Management :</span>
						<span className="bold">{celebInfo.management}</span>
					</InfoContainer>
					<SocialIconList>
						{celebInfo.webUrl && (
							<a href={celebInfo.webUrl} target="_blank" rel="noreferrer">
								<LanguageIcon fontSize="inherit" />
							</a>
						)}
						{celebInfo.instaUrl && (
							<a href={celebInfo.instaUrl} target="_blank" rel="noreferrer">
								<InstagramIcon fontSize="inherit" />
							</a>
						)}
						{celebInfo.youtubeUrl && (
							<a href={celebInfo.youtubeUrl} target="_blank" rel="noreferrer">
								<YouTubeIcon fontSize="inherit" />
							</a>
						)}
					</SocialIconList>
				</ActiveFromManagementLink>
				<ClosedCelebrityKeyword>
					<div className="content">
						<img src={"/image/error.png"} alt="errorImg"/>
						<p>Closed celebrity is displayed with a pseudonym and a placeholder image. To access the actual details,
							please request permission.
						</p>
						<CloseIcon onClick={onCloseIconClick}>
							<Close sx={
								{
									fontSize: "20px",
									color: "white",
									cursor: "pointer",
								}
							} />
						</CloseIcon>
					</div>
				</ClosedCelebrityKeyword>
			</OtherInformation>
			<Description marginTop={16}>
				{celebInfo.description}
			</Description>
			<ButtonContainer>
				{
					(
						celebInfo.permission === EPermissionStatus.PENDING ||
						celebInfo.permission === EPermissionStatus.GRANTED ||
						celebInfo.celebAgencyId === user?.id ||
						isClosedRequestPermissionBtn
					) ? (
							<StyledButton
								label="Request Permission"
								size="llg"
								color="gray"
								disabled={true}
							/>
					) : (
						<StyledButton
							label="Request Permission"
							size="llg"
							color="black"
							onClick={onClickRequestPermission}
						/>
					)
				}
			</ButtonContainer>
			{showModal && (
				<>
					<ClosedPermissionRequestModals
						onClose={closeModal}
						celebId={celebId}
						setIsSuccessModal={setIsSuccessModal}
						changeClosedRequestPermissionBtn={changeClosedRequestPermissionBtn}
					/>
				</>
			)}
			{isSuccessModal && (
				<PermissionGrantModals
					onClose={closeGrantModal}
				/>
			)}

			{celebPermissionStatus === EPermissionStatus.PENDING && showPendingModal && (
				<Overlay>
					<ClosedPermissionPendingModals onClose={closePendingModal} />
				</Overlay>
			)}
		</Wrapper>
	);
};


export default ClosedCelebSideInformation;
