import React from "react";
import { Area, AreaChart, XAxis, Tooltip, ResponsiveContainer } from "recharts";
import { FollowersChart } from "../interfaces/celeb.interfaces";
import formatDate, { formatNumberWithCommas } from "../../utils/utils";
import styled from "styled-components";
import {colors} from "../../styles/color";

interface FollowersGrowthChartProps {
  data: FollowersChart[];
}

const TooltipContainer = styled.div`
  padding: 0.2rem 0.6rem;
  border-radius: 1.2rem;
  border: 0.1rem solid ${colors.neutral["100"]};
  color: ${colors.neutral["600"]};
  background-color: white;
  white-space: nowrap;
  color: ${colors.neutral["600"]}
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.regular};
`;

const FollowersGrowthChart = ({ data }: FollowersGrowthChartProps) => {
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <TooltipContainer>
          <p className="label">{`${formatDate(
            label
          )} : ${formatNumberWithCommas(payload[0].value)}`}</p>
        </TooltipContainer>
      );
    }

    return null;
  };
  return (
    <ResponsiveContainer width="100%" height={210}>
      <AreaChart data={data}>
        <XAxis dataKey="date" tick={false} axisLine={false} tickLine={false} />
        <Tooltip content={CustomTooltip} />
        <Area
          type="monotone"
          dataKey="count"
          stroke="var(--purple-300)"
          fill="var(--purple-050)"
          strokeWidth={3}
          dot={{ stroke: "var(--purple-300)", strokeWidth: 3, r: 2 }}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default FollowersGrowthChart;
